import React, { useRef, useState, useContext, useEffect } from 'react';
import { AppContext } from "@/app/providers";

import { Link, CircularProgress, Box, TextField, Tooltip, IconButton, Slider, Typography, Popover, Menu, MenuItem, ListItemIcon, Alert, AlertColor, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FileOpenTwoToneIcon from '@mui/icons-material/FileOpenTwoTone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';

import { toast } from 'sonner';
import logger from '@/lib/logger';
import { logEvent } from 'firebase/analytics';
import { getFirebaseAnalytics } from '@/lib/hooks/firebase';

import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import Confetti from 'react-confetti';
import { useTheme } from "next-themes";
import { getConfettiColors } from '@/lib/utils';
import { DragItemTypes } from '@/lib/interfaces';

interface DragItem {
    id: string;
    type?: string;
    index?: number;
    sourceOkrId?: string;
    // other properties...
}

const DraggableKeyResult = (
    {
        kr,
        index,
        okrId,
        objective,
        handleKeyResultChange,
        handleKeyDown,
        handleDeleteKeyResult,
        handleProgressChange,
        onOpenEditor,
        moveKeyResult
    }) => {

    const { isMobile } = useContext(AppContext);

    const { theme, systemTheme } = useTheme();
    const effectiveTheme = theme === 'system' ? (systemTheme) : theme;

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(menuAnchorEl);

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
        logger.debug('Opening menu for key result...', index);
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);

    const handleDeletePrompt = () => {
        setDeleteConfirmDialog(true);
    }

    const handleDelete = () => {
        handleDeleteKeyResult(index);

        setDeleteConfirmDialog(false);
        handleMenuClose();
    };

    const handleMarkAsCompleted = () => {
        logger.debug('Marking key result as completed...', index);

        setProgress(100);
        handleProgressChange(index, 100);

        handleMenuClose();
    };

    const handleScratch = () => {
        logger.debug('Toggling scratch on key result...', kr.id);

        kr.scratched = !kr.scratched;

        handleKeyResultChange(index, kr); 

        toast.message(`Key Result ${index + 1} ${kr.scratched ? 'scratched' : 'enabled'}.`);

        handleMenuClose();
    };

    const handleDescriptionChange = (newDescription: string) => {
        logger.debug('Handling description change...', kr.id);

        kr.description = newDescription;
        handleKeyResultChange(index, kr);
    }

    const handleVerifyIsOutcome = async () => {
        await handleVerifyKeyResultIsOutcome(index);

        handleMenuClose();
    };

    const [verifyOutcomeResponse, setVerifyOutcomeResponse] = useState(null);
    const [popperAnchorEl, setPopperAnchorEl] = useState(null);
    const [isVerifyingOutcome, setIsVerifyingOutcome] = useState(false);

    // Function to call API and handle response for verifying key result as an outcome
    const handleVerifyKeyResultIsOutcome = async (index: number) => {
        logger.debug('Verifying key result as outcome...', index);
        toast.message('Checking if key result is outcome-based...');

        setIsVerifyingOutcome(true);

        const keyResultDescription = kr?.description;

        try {
            let prompt = `
                Objective: ${objective}
                Key Result: ${keyResultDescription}

                Please don't suggest key results that are not outcome-based.
            `;

            const response = await fetch('/api/outcome', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ prompt })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            logger.debug('Outcome response: ', data);

            setVerifyOutcomeResponse(data);
            setPopperAnchorEl(menuAnchorEl);

            // toast.success('Done checking if key result is outcome based...');
        } catch (error) {
            logger.error('Error fetching data: ', error);
            toast.error('Error tallking to GenAI. Please try again.');
        } finally {
            setIsVerifyingOutcome(false);
        }

        logEvent(getFirebaseAnalytics(), 'verify_key_result_outcome', {
            key_result_index: index
        });
    };

    const renderAlert = () => {
        if (!verifyOutcomeResponse) return null;

        let severity = 'info' as AlertColor;

        if (verifyOutcomeResponse.isOutcomeBased) {
            severity = 'success' as AlertColor;
        } else {
            severity = 'warning' as AlertColor;
        }

        return <Alert severity={severity}>{verifyOutcomeResponse.explanation}</Alert>;
    };

    const renderSuggestions = () => {

        if (!verifyOutcomeResponse?.suggestions) return null;

        return (
            <ul style={{ listStyleType: 'disc', paddingLeft: '20px', fontSize: '0.9em' }}>
                {verifyOutcomeResponse.suggestions.map((outcome, index) => (
                    <li key={index} style={{ fontSize: 'smaller' }}>{outcome}</li>
                ))}
            </ul>
        );
    };

    const handleClosePopover = () => {
        setPopperAnchorEl(null);
    }

    const dragRef = useRef(null);
    const dropRef = useRef(null);

    const [{ isDragging }, drag, preview] = useDrag({
        type: DragItemTypes.KEY_RESULT,
        item: () => ({ id: kr.id, index, sourceOkrId: okrId }),
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item: DragItem | null, monitor) => {
            const dropResult = monitor.getDropResult();

            if (item && dropResult) {
                const { sourceOkrId, index: dragIndex } = item;

                const { targetOkrId, hoverIndex } = dropResult as { targetOkrId: string; hoverIndex: number };

                if (sourceOkrId !== targetOkrId || dragIndex !== hoverIndex) {
                    moveKeyResult(sourceOkrId, targetOkrId, dragIndex, hoverIndex);
                }
            }
        },
    });

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);

    const useCombinedRefs = (...refs): React.RefObject<any> => {
        const targetRef = useRef(null);
    
        useEffect(() => {
            refs.forEach(ref => {
                if (!ref) return;
    
                if (typeof ref === 'function') {
                    ref(targetRef.current);
                } else {
                    ref.current = targetRef.current;
                }
            });
        }, [refs]);
    
        return targetRef;
    };
    
    const combinedRef = useCombinedRefs(dragRef, dropRef);

    const [{ isOver }, drop] = useDrop({
        accept: DragItemTypes.KEY_RESULT,
        hover(item: DragItem) {
            if (!combinedRef.current) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;
            const targetOkrId = okrId;

            logger.debug('Drag Index:', dragIndex, 'Hover Index:', hoverIndex, 'Target OKR ID:', targetOkrId, 'Source OKR ID:', item.sourceOkrId);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
        drop: () => ({
            targetOkrId: okrId,
            hoverIndex: index,
        }),
    });

    // Apply 'drop' to the entire component but 'drag' only to the drag handle.
    drag(drop(combinedRef));

    const dragHandleRef = (el: HTMLDivElement | null) => {
        drag(el);
    };

    const [showKRConfetti, setShowKRConfetti] = useState(false);
    const progressBarRef = useRef(null);

    const [progress, setProgress] = useState<number>(typeof kr.progress === 'number' ? kr.progress : 0);

    const doHandleProgressChange = (index, value) => {
        handleProgressChange(index, value);

        if (value === 100 && !showKRConfetti) {
            setShowKRConfetti(true);
            setTimeout(() => setShowKRConfetti(false), 2000);
        }
    };

    return (
        <div 
            className='w-full' 
        >
            <div
                ref={combinedRef}
                style={{
                    border: isOver ? '2px dashed #1976d2' : '1px solid transparent',
                    opacity: isDragging ? 0.5 : 1,
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    flexDirection: isMobile ? 'column' : 'row'
                }}
                className='w-full'
            >
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px', width: isMobile ? '100%' : '95%' }}>
                    <div
                        ref={dragHandleRef}
                        className='screenshot-hide'
                        style={{
                            cursor: 'grab',
                            color: 'gray',
                            transition: 'color 0.3s',
                        }}
                        onMouseOver={(e) => e.currentTarget.style.color = '#1976d2'}
                        onMouseOut={(e) => e.currentTarget.style.color = 'gray'}
                    >
                        <Tooltip title="Drag to reorder">
                            <DragIndicatorIcon fontSize="small" />
                        </Tooltip>
                    </div>

                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small" label={
                            <Box display="flex" alignItems="center">
                                Key Result {index + 1}
                                {kr.description && kr.description.trim() !== '' && (
                                    <Tooltip
                                        title={
                                            <React.Fragment>
                                                Set specific, measurable results. E.g., &apos;Launch 3 new marketing campaigns&apos;.
                                                For more info, see
                                                <Link
                                                    href="https://scaledagileframework.com/okrs/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{ marginLeft: '4px', color: 'blue' }} // Inline CSS for blue color
                                                >
                                                    this guide
                                                </Link>.
                                            </React.Fragment>
                                        }
                                    >
                                        <InfoOutlinedIcon fontSize="small" style={{ marginLeft: 4, marginBottom: 1 }} />
                                    </Tooltip>
                                )}
                            </Box>
                        }
                        disabled={kr.scratched}
                        value={kr.description}
                        onChange={(e) => handleDescriptionChange(e.target.value)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        InputLabelProps={{
                            className: 'dark:text-gray-100',
                        }}
                        InputProps={{
                            className: `bg-gray-50 ${kr.scratched ? 'dark:bg-gray-500' : 'dark:bg-gray-700'} ${kr.scratched ? 'dark:text-gray-700' : 'dark:text-gray-300'}`,
                            style: {
                                textDecoration: kr.scratched ? 'line-through' : ''
                            },
                            readOnly: kr.scratched,
                            endAdornment: (
                                <Tooltip title="Notes" placement="top">
                                    <IconButton
                                        onClick={() => onOpenEditor('kr', okrId, index)}
                                        className="cursor-pointer"
                                    >
                                        <FileOpenTwoToneIcon color={kr.scratched ? 'disabled' : 'primary'} />
                                    </IconButton>
                                </Tooltip>
                            ),
                        }}
                        margin="normal"
                        multiline
                    />
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 2, gap: 1, width: isMobile ? '100%' : '25%' }}>
                    <Box ref={progressBarRef} sx={{ position: 'relative', display: 'flex', flexDirection: isMobile ? 'row' : 'column', alignItems: 'center', gap: 0, width: '100%' }}>

                        <Box sx={{ textAlign: 'center', width: isMobile ? '50%' : '100%', p: 0, m: 0 }}>
                            <Typography variant="caption" color="GrayText">{progress}% Progress</Typography>
                        </Box>

                        <Slider
                            value={progress}
                            onChange={(e, value) => setProgress(value as number)}
                            onChangeCommitted={(e, value) => doHandleProgressChange(index, value as number)}
                            aria-labelledby={`key-result-slider-${index}`}
                            valueLabelDisplay="auto"
                            valueLabelFormat={(value) => `${value}%`}
                            size="small"
                            step={1}
                            min={0}
                            max={100}
                            disabled={kr.scratched}
                        />

                        {showKRConfetti && progressBarRef.current && (
                            <Confetti
                                width={progressBarRef.current.offsetWidth}
                                height={progressBarRef.current.offsetHeight * 4}
                                recycle={false}
                                numberOfPieces={200}
                                wind={0}
                                gravity={0.6}
                                run={showKRConfetti}
                                tweenDuration={5000}
                                colors={getConfettiColors(effectiveTheme)}
                                style={{ position: 'absolute', top: 0, left: 0 }}
                            />
                        )}

                    </Box>

                    <IconButton
                        aria-label="more"
                        id="long-button"
                        key={`kr-action-${index}`}
                        aria-controls={isMenuOpen ? 'long-menu' : undefined}
                        aria-expanded={isMenuOpen ? 'true' : undefined}
                        aria-haspopup="true"
                        className="text-stone-500"
                        onClick={(e) => handleMenuClick(e, index)}
                    >
                        {isVerifyingOutcome ? (
                            <CircularProgress size={24} />
                        ) : (
                            <MoreVertIcon />
                        )}
                    </IconButton>
                </Box>
            </div>

            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={menuAnchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
            >
                <Tooltip title="Verify if key result is outcome-based" placement="right">
                    <MenuItem
                        onClick={handleVerifyIsOutcome}
                        aria-label="check if the key result is  an outcome"
                        className="hover:text-blue-700 dark:hover:text-blue-500"
                    >
                        <ListItemIcon>
                            <AutoAwesomeOutlinedIcon className="hover:text-blue-700 dark:hover:text-blue-500" fontSize="small" />
                        </ListItemIcon>
                        Verify Outcome
                    </MenuItem>
                </Tooltip>

                <Tooltip title="Take notes relating to this key result" placement="right">
                    <MenuItem
                        onClick={() => {
                            onOpenEditor('kr', okrId, index);
                            handleMenuClose(); // Ensure the menu is closed after the action
                        }}
                        aria-label="open key results details"
                        className="hover:text-blue-700 dark:hover:text-blue-500"
                    >
                        <ListItemIcon>
                            <FileOpenTwoToneIcon className="hover:text-blue-700 dark:hover:text-blue-500" fontSize="small" />
                        </ListItemIcon>
                        Notes
                    </MenuItem>
                </Tooltip>

                <Tooltip title="Complete this key result" placement="right">
                    <MenuItem
                        onClick={handleMarkAsCompleted}
                        aria-label="Mark Key Result as completed"
                        className="hover:text-green-700 dark:hover:text-green-500"
                    >
                        <ListItemIcon>
                            <CheckOutlinedIcon className="hover:text-green-700 dark:hover:text-green-500" fontSize="small" />
                        </ListItemIcon>
                        Mark as Completed
                    </MenuItem>
                </Tooltip>

                <Tooltip title="Ignore this key result from progress tracking." placement="right">
                    <MenuItem
                        onClick={handleScratch}
                        aria-label={kr.scratched ? "Enable Key Result" : "Scratch Key Result"}
                        className="hover:text-yellow-700 dark:hover:text-yellow-500"
                    >
                        <ListItemIcon>
                            <StrikethroughSIcon className="hover:text-yellow-700 dark:hover:text-yellow-500" fontSize="small" />
                        </ListItemIcon>
                        {kr.scratched ? "Enable Key Result" : "Scratch Key Result"}
                    </MenuItem>
                </Tooltip>

                <Tooltip title="Permanently delete this key result" placement="right">
                    <MenuItem
                        onClick={handleDeletePrompt}
                        aria-label="Delete Key Result"
                        className="hover:text-red-700 dark:hover:text-red-500"
                    >
                        <ListItemIcon>
                            <DeleteIcon className="hover:text-red-700 dark:hover:text-red-500" fontSize="small" />
                        </ListItemIcon>
                        Delete Key Result
                    </MenuItem>
                </Tooltip>

            </Menu>

            <Popover
                id={`${index}-outcome-popover`}
                open={Boolean(popperAnchorEl)}
                anchorEl={popperAnchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box p={2} bgcolor="background.paper" boxShadow={1} maxWidth="400px" style={{ overflow: 'auto' }}>
                    {renderAlert()}

                    <Typography style={{ paddingTop: '20px', paddingBottom: '10px', fontSize: '0.875rem' }}>
                        {verifyOutcomeResponse?.isOutcomeBased ? 'Here are some additional suggestions just in case:' : 'Here are some suggestions for you:'}
                    </Typography>


                    {renderSuggestions()}
                </Box>
            </Popover>

            {/* Delete Confirmation Dialog */}
            <Dialog open={deleteConfirmDialog} onClose={() => setDeleteConfirmDialog(false)}>
                <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this key result? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteConfirmDialog(false)}>Cancel</Button>
                    <Button onClick={handleDelete} autoFocus>Delete</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DraggableKeyResult;
