import React, { useState, useEffect, useContext, useCallback } from 'react';

import { AppContext } from "@/app/providers";
import { Divider, Drawer, List, ListItemButton, ListItemIcon, ListItemText, Tooltip, IconButton, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography, Button } from '@mui/material';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import HomeIcon from '@mui/icons-material/Home';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';

import { logEvent, getAnalytics } from 'firebase/analytics';
import { toast } from 'sonner';

import { usePlans } from '@/context/PlansContext';
import { FolderInfo, PlanInfo, PlanMenuInfo, allPlansOption, timelineOption } from '@/lib/interfaces';
import logger from "@/lib/logger";
import { useFirebaseAuth } from '@/lib/hooks/firebase';
import useSubscription from '@/lib/hooks/useSubscription';
import { addItemToPlans } from '@/lib/utils';

import PlanSelector from './PlanSelector';
import CreateFolderDialog from './CreateFolderDialog';

import ClickAwayListener from '@mui/material/ClickAwayListener';

interface PlanListProps {
    onSelectPlan: (selectedPlan: PlanMenuInfo) => void;
    onOpenNewPlanDialog: (folder: FolderInfo) => void;
}

const PlanList: React.FC<PlanListProps> = ({ onSelectPlan, onOpenNewPlanDialog }) => {
    const { user } = useFirebaseAuth();
    const { hasProPlan } = useSubscription(user?.uid);
    const { isTablet, isMobile } = useContext(AppContext);

    const { plans, setPlans, selectedPlan, deletePlan, savePlans } = usePlans();

    const [sidebarWidth, setSidebarWidth] = useState(() => parseInt(localStorage.getItem('planListSidebarWidth') || '300'));

    const startResizing = (e) => {
        e.preventDefault();
        document.addEventListener('mousemove', resize);
        document.addEventListener('mouseup', stopResizing);

        logger.debug('Starting to resize sidebar');
    };

    const resize = (e) => {

        const newWidth = Math.max(248, Math.min(480, e.clientX));

        logger.debug('Resizing sidebar to:', newWidth);

        setSidebarWidth(newWidth);
        localStorage.setItem('planListSidebarWidth', newWidth.toString());
    };

    const stopResizing = () => {
        document.removeEventListener('mousemove', resize);
        document.removeEventListener('mouseup', stopResizing);

        logger.debug('Stopped resizing sidebar');
    };

    const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
    const [isDeletePlanDialogOpen, setIsDeletePlanDialogOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState<PlanMenuInfo | null>(null);

    useEffect(() => {
        setIsSidebarExpanded(!isTablet);
    }, [isTablet]);

    const handleSelectPlan = (planOrFolder: PlanMenuInfo) => {
        logger.debug('Selecting plan in PlanList:', planOrFolder);

        onSelectPlan(planOrFolder);

        checkIfShouldCloseSideBar();
    }

    const handleAddPlan = (folder: FolderInfo = null) => {
        if (plans.length >= 6 && !hasProPlan) {
            toast.error('Upgrade to pro to create more than 6 plans.');
            return;
        }

        setSelectedFolder(folder);
        onOpenNewPlanDialog(folder);

        checkIfShouldCloseSideBar();
    };

    const [isCreateFolderDialogOpen, setIsCreateFolderDialogOpen] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState<FolderInfo | null>(null);

    const handleOpenCreateFolderDialog = (folder: FolderInfo = null) => {
        setSelectedFolder(folder);

        setIsCreateFolderDialogOpen(true);

        checkIfShouldCloseSideBar();
    };

    const handleCreateNewFolder = (folderInfo: FolderInfo) => {
        logger.debug('Adding a new folder!', folderInfo);

        let targetFolderId = selectedFolder?.id ?? null;

        setPlans(prevPlans => {
            const newPlans = addItemToPlans(prevPlans, folderInfo, targetFolderId);
            savePlans(newPlans);
            return newPlans;
        });
    }

    const handleCloseCreateFolderDialog = () => {
        setIsCreateFolderDialogOpen(false);
    };

    const toggleSidebar = () => {
        setIsSidebarExpanded(!isSidebarExpanded);

        logEvent(getAnalytics(), 'toggle_plan_sideline');
    };

    const handleOpenDeleteDialog = (plan: PlanMenuInfo) => {
        setCurrentItem(plan);

        logger.debug('Opening delete dialog for item:', plan);

        setIsDeletePlanDialogOpen(true);

        checkIfShouldCloseSideBar();
    };

    const handleCloseDeleteDialog = () => {
        setIsDeletePlanDialogOpen(false);
    };

    const handleConfirmDelete = () => {
        if (currentItem) {
            deletePlan(currentItem);
            setCurrentItem(null);

            logEvent(getAnalytics(), 'delete_plan');
        }

        setIsDeletePlanDialogOpen(false);
    };

    const [isRenameDialogOpen, setIsRenameDialogOpen] = React.useState(false);
    const [newTitle, setNewTitle] = useState('');

    const handleOpenRenameDialog = (plan: PlanMenuInfo) => {
        setCurrentItem(plan);

        logger.debug('Opening rename dialog for item:', plan);

        setNewTitle(plan?.title || '');

        setIsRenameDialogOpen(true);

        checkIfShouldCloseSideBar();
    };

    const inputTitleRef = useCallback(node => {
        logger.debug('After rename dialog open, input title ref:', node);

        if (node !== null && isRenameDialogOpen) {
            setTimeout(() => {
                logger.debug('Focusing and selecting input title ref:', node);

                node.focus();
                node.select();
            }, 100);


        }
    }, [isRenameDialogOpen]);

    const handleItemRename = () => {
        logger.debug('Renaming item:', currentItem);

        if (!currentItem || currentItem.title === newTitle) {
            setIsRenameDialogOpen(false);
            return;
        }

        const recursiveUpdate = (items, level = 0) => {
            return items.map(item => {
                logger.debug(`\t`.repeat(level) + 'Name: ' + item.title, ', ID: ' + item.id);

                if (item.id === currentItem.id) {
                    // Found the item to rename
                    return { ...item, title: newTitle };
                } else if (item.items && item.items.length) {
                    // Recursively update if the item has nested items
                    return { ...item, items: recursiveUpdate(item.items, level + 1) };
                }
                return item;
            });
        };

        const updatedPlans = recursiveUpdate(plans);

        setPlans(updatedPlans);
        savePlans(updatedPlans);

        setIsRenameDialogOpen(false);
    };


    const handleClickAway = () => {
        checkIfShouldCloseSideBar();
    };

    const checkIfShouldCloseSideBar = () => {
        if (isMobile && isSidebarExpanded) {
            setIsSidebarExpanded(false);
        }
    }

    const handleSelectAllPlans = () => {
        handleSelectPlan(allPlansOption);
    };

    const handleSelectTimeline = () => {
        handleSelectPlan(timelineOption);
    };

    return (
        <div>
            <ClickAwayListener onClickAway={handleClickAway} disabled={!isSidebarExpanded}>
                <div>
                    <Drawer
                        id="plan-list-drawer"
                        variant="persistent"
                        open={isSidebarExpanded}
                        sx={{
                            width: isSidebarExpanded ? sidebarWidth : 0,
                            flexShrink: 0,
                            '& .MuiDrawer-paper': { width: sidebarWidth, boxSizing: 'border-box' }
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center', padding: 1, justifyContent: 'space-between', width: '100%' }}>
                            <Typography variant="h6" sx={{ padding: 1 }}>
                                Plans
                            </Typography>

                            {isSidebarExpanded && (
                                <Tooltip title="Collapse sidebar" placement='right'>
                                    <IconButton
                                        onClick={toggleSidebar}
                                    >
                                        <KeyboardArrowLeftIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </div>

                        <List component="nav" disablePadding>
                            <Tooltip title="Create a new plan" placement='right'>
                                <ListItemButton
                                    onClick={() => handleAddPlan()}
                                >
                                    <ListItemIcon>
                                        <AddCircleOutlineIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="New Plan" />
                                </ListItemButton>
                            </Tooltip>

                            <Tooltip title="Create a new folder" placement='right'>
                                <ListItemButton
                                    onClick={() => handleOpenCreateFolderDialog()}
                                >
                                    <ListItemIcon>
                                        <CreateNewFolderOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="New Folder" />
                                </ListItemButton>
                            </Tooltip>

                            <Tooltip title="View All Plans" placement='right'>
                                <ListItemButton onClick={handleSelectAllPlans}>
                                    <ListItemIcon>
                                        <HomeIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="All Plans" />
                                </ListItemButton>
                            </Tooltip>

                            <Tooltip title="View priorities across all plans" placement='right'>
                                <ListItemButton onClick={handleSelectTimeline}>
                                    <ListItemIcon>
                                        <ViewTimelineIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Priorities" />
                                </ListItemButton>
                            </Tooltip>

                            <Divider />
                        </List>

                        <PlanSelector
                            key={`plan-selector-nav`}
                            selectedPlan={selectedPlan as PlanInfo}
                            onSelectPlan={handleSelectPlan}
                            onDeletePlan={handleOpenDeleteDialog}
                            onAddPlan={handleAddPlan}
                            onAddFolder={handleOpenCreateFolderDialog}
                            onRenameItem={handleOpenRenameDialog}
                            isDraggingEnabled={true}
                            isInteractive={true}
                        />
                    </Drawer>

                    <div
                        style={{ position: 'absolute', top: 0, left: `${sidebarWidth}px`, width: '10px', height: '100%', cursor: 'ew-resize', zIndex: 1200 }}
                        onMouseDown={startResizing}
                    >
                    </div>

                    {!isSidebarExpanded && (
                        <Tooltip title="Expand sidebar" placement='right'>
                            <IconButton
                                onClick={toggleSidebar}
                                className="text-stone-500"
                                sx={{ position: 'absolute', top: 8, left: 10, transition: 'left 0.3s' }}
                            >
                                <KeyboardArrowRightIcon />
                            </IconButton>
                        </Tooltip>
                    )}

                </div>
            </ClickAwayListener>

            <Dialog open={isDeletePlanDialogOpen} onClose={handleCloseDeleteDialog}>
                <DialogTitle>{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this {currentItem?.type === 'folder' ? "folder and everything inside of it" : "plan"}? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={isRenameDialogOpen} onClose={() => setIsRenameDialogOpen(false)}>
                <DialogTitle>Rename {currentItem?.type === 'folder' ? "Folder" : "Plan"}</DialogTitle>
                <DialogContent>
                    <TextField
                        inputRef={inputTitleRef}
                        margin="dense"
                        id="name"
                        label="Name"
                        type="text"
                        value={newTitle}
                        onChange={(e) => setNewTitle(e.target.value)}
                        fullWidth
                        inputProps={{
                            style: { fontSize: '1.25rem', minWidth: '300px' },
                            onFocus: event => event.target.select()
                        }}
                        InputLabelProps={{
                            style: { fontSize: '1.25rem' }
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleItemRename();
                                e.preventDefault();
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsRenameDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleItemRename} color="primary">Save</Button>
                </DialogActions>
            </Dialog>

            <CreateFolderDialog
                isOpen={isCreateFolderDialogOpen}
                onClose={handleCloseCreateFolderDialog}
                onCreate={handleCreateNewFolder}
            />
        </div>
    );
};

export default PlanList;
