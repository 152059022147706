import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { IconButton, Divider, Tooltip, Menu, ListItemIcon, MenuItem, Popover } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';

import { DragItemTypes, FolderInfo, IconSelectionInfo, PlanInfo, PlanMenuInfo } from '@/lib/interfaces';
import { findPlanItemAndParentById, getMaxDepth } from '@/lib/utils';

import logger from "@/lib/logger";
import { logEvent, getAnalytics } from 'firebase/analytics';
import { toast } from 'sonner';
import IconComponent from './IconComponent';
import { IconsGrid } from '@/lib/IconUtils';
import { usePlans } from '@/context/PlansContext';

interface PlanSelectorProps {
    selectedPlan?: PlanInfo | null;
    onSelectPlan: (plan: PlanMenuInfo) => void;
    onDeletePlan?: (plan: PlanMenuInfo) => void;
    onRenameItem?: (plan: PlanMenuInfo) => void;
    onAddPlan?: (folder: FolderInfo) => void;
    onAddFolder?: (folder: FolderInfo) => void;
    isDraggingEnabled?: boolean;
    isInteractive?: boolean;
}

interface DragItem {
    id: string;
    index: number;
    type: string;
}

interface DropResult {
    targetId: string;
    targetIndex: number;
}

const PlanSelector: React.FC<PlanSelectorProps> = ({
    selectedPlan,
    onSelectPlan,
    onDeletePlan,
    onRenameItem,
    onAddPlan,
    onAddFolder,
    isDraggingEnabled = false,
    isInteractive = false
}) => {

    const { plans, setPlans, savePlans } = usePlans();

    const handleMove = (dragId, targetId) => {
        setPlans(prev => {
            const updatedPlans = reorderPlans(prev, dragId, targetId);

            savePlans(updatedPlans);

            logEvent(getAnalytics(), 'reorder_plans');

            return updatedPlans;
        });
    };

    function reorderPlans(plans, dragId, targetId) {
        let newPlans = JSON.parse(JSON.stringify(plans)); // Deep copy for immutability

        const { item: dragItem, parent: dragParent, index: dragIndex } = findPlanItemAndParentById(newPlans, dragId);
        const { item: targetItem, parent: targetParent, index: targetIndex, depth: targetDepth } = findPlanItemAndParentById(newPlans, targetId);

        if (!dragItem || !targetItem) return plans; // Safety check if items are not found

        const dragItemMaxDepth = getMaxDepth(dragItem);
        const potentialNewTotalDepth = targetDepth + dragItemMaxDepth;
        const maxDepth = dragItem.type === 'folder' ? 5 : 6;

        if (potentialNewTotalDepth > maxDepth) {
            toast.error(`Cannot move this ${dragItem.type} into a folder, as it would exceed the maximum depth of ${maxDepth}.`);
            return plans;
        }

        // Remove item from old position
        if (dragParent) {
            dragParent.items.splice(dragIndex, 1);
        } else {
            newPlans.splice(dragIndex, 1);
        }

        // Insert item into new position
        if (targetItem.type === 'folder') {
            targetItem.items.push(dragItem); // Or use unshift() to add to the start
        } else {
            if (targetParent) {
                targetParent.items.splice(targetIndex + 1, 0, dragItem);
            } else {
                newPlans.splice(targetIndex + 1, 0, dragItem);
            }
        }

        return newPlans;
    }

    const handleToggleArchive = (item: PlanMenuInfo) => {
        const newPlans = toggleArchiveItem(plans, item.id);
        setPlans(newPlans);

        savePlans(newPlans);

        toast.success(`${item.type === 'folder' ? 'Folder' : 'Plan'} ${item.archived ? 'unarchived' : 'archived'} successfully.`);

        handlePlanOptionsClose();
    };

    function toggleArchiveItem(plans: PlanMenuInfo[], itemId: string): PlanMenuInfo[] {
        return plans.map(item => {
            if (item.id === itemId) {
                const newArchivedState = !item.archived;

                return {
                    ...item,
                    archived: newArchivedState,
                    ...(item.type === 'folder' && { items: toggleArchiveChildren(item.items, newArchivedState) })
                };
            }
            if (item.type === 'folder' && item.items) {
                return { ...item, items: toggleArchiveItem(item.items, itemId) };
            }
            return item;
        });
    }

    function toggleArchiveChildren(items: PlanMenuInfo[], newArchivedState: boolean): PlanMenuInfo[] {
        return items.map(item => ({
            ...item,
            archived: newArchivedState,
            ...(item.type === 'folder' && { items: toggleArchiveChildren(item.items, newArchivedState) })
        }));
    }

    const SortableItem = ({ itemInfo, index, children, onSelect, onMove, disabled }) => {
        const ref = useRef<HTMLDivElement>(null);

        const [{ isDragging }, drag] = useDrag({
            type: itemInfo.type === 'folder' ? DragItemTypes.FOLDER : DragItemTypes.PLAN,
            item: { id: itemInfo.id, index, type: itemInfo.type } as DragItem,
            collect: monitor => ({
                isDragging: monitor.isDragging(),
            }),
            end: (item: DragItem | null, monitor) => {
                const dropResult = monitor.getDropResult() as DropResult;

                if (item && dropResult) {
                    const { id: dragId, index: dragIndex } = item;
                    const { targetId, targetIndex } = dropResult;

                    if (dragId !== targetId || dragIndex !== targetIndex) {
                        onMove && onMove(dragId, targetId, dragIndex, targetIndex);
                    }
                }
            },
        });

        const [{ isOver }, drop] = useDrop({
            accept: [DragItemTypes.PLAN, DragItemTypes.FOLDER],
            hover(item: DragItem) {
                if (!ref.current) {
                    return;
                }

                const dragIndex = item.index;
                const targetIndex = index;
                const targetId = itemInfo.id;

                logger.debug('Drag Index:', dragIndex, 'Target Index:', targetIndex, 'Target ID:', targetId, 'Source ID:', item.id);
            },
            collect: monitor => ({
                isOver: monitor.isOver(),
            }),
            drop: () => ({
                targetId: itemInfo.id,
                targetIndex: index,
            } as DropResult),
        });

        if (!disabled) {
            drag(drop(ref));
        }

        const itemStyle = {
            border: isOver ? '2px dashed #1976d2' : '1px solid transparent',
            opacity: isDragging ? 0.5 : 1,
            flexGrow: 1,
        };

        return (
            <div ref={!disabled ? ref : null} onClick={() => onSelect(itemInfo.id)} style={itemStyle}>
                {children}
            </div>
        );
    };

    const [currentItem, setCurrentItem] = useState<PlanMenuInfo | null>(null);
    const [currentItemDepth, setCurrentItemDepth] = useState<number>(0);

    const handleSelect = (item: PlanMenuInfo) => {
        logger.debug('Selecting item in PlanSelector:', item);

        if (item.type === 'folder') {
            const result = findPlanItemAndParentById(plans, item.id);

            if (result) {
                const { item, parent, index } = result;
                const foundItem = item as FolderInfo;

                if (foundItem) {
                    foundItem.isExpanded = !foundItem.isExpanded;

                    const newPlans = [...plans];

                    if (parent) {
                        parent.items[index] = foundItem;
                    } else {
                        newPlans[index] = foundItem;
                    }

                    setPlans(newPlans);

                    savePlans(newPlans);

                    if (foundItem.items.length === 0) {
                        toast.message('This folder is empty. Add some plans to it by dragging them in.')
                    }
                }
            }
        } else {
            // Not a folder, so select the plan (not specifically looking for type == 'plan' to handle migration)
            onSelectPlan(item as PlanInfo);
        }
    };

    const [planOptionsAnchorEl, setPlanOptionsAnchorEl] = useState(null);

    const handlePlanOptionsClick = (event, item, level) => {
        setCurrentItem(item);
        setCurrentItemDepth(level);
        setPlanOptionsAnchorEl(event.currentTarget);
    };

    const handlePlanOptionsClose = () => {
        setPlanOptionsAnchorEl(null);
    };

    const [anchorIconEl, setIconAnchorEl] = React.useState(null);
    const [isIconMenuOpen, setIsIconMenuOpen] = useState(false);

    const handleIconClick = (event) => {
        setIconAnchorEl(event.target);
        setIsIconMenuOpen(true);

        logEvent(getAnalytics(), 'plan_icon_click');
    };

    const handleIconMenuClose = () => {
        setIconAnchorEl(null);
        setIsIconMenuOpen(false);
    };

    const selectIcon = async (icon: IconSelectionInfo) => {
        logger.debug("Selected plan icon:", icon.name);

        setIsIconMenuOpen(false);

        if (!currentItem) return;

        // Find the plan and its parent
        const result = findPlanItemAndParentById(plans, currentItem.id);
        if (result) {
            const { item, parent, index } = result;

            // Create a deep copy of the item to mutate
            const updatedItem = { ...item, icon: icon };

            // Create a new array from the plans to mutate
            const newPlans = [...plans];

            // Replace the item in its parent, or at the top level if no parent
            if (parent) {
                const updatedItems = [...parent.items];
                updatedItems[index] = updatedItem;
                parent.items = updatedItems; // Mutate the parent's items immutably
            } else {
                newPlans[index] = updatedItem; // Update the item at the top level
            }

            // Update the plans state
            setPlans(newPlans);
            await savePlans(newPlans); // Optionally save to backend or other storage
        }
    };

    const renderMenuItems = () => {
        if (!currentItem) return null;

        return (
            <Menu
                anchorEl={planOptionsAnchorEl}
                open={Boolean(planOptionsAnchorEl)}
                onClose={handlePlanOptionsClose}
            >
                {currentItem.type === 'folder' && currentItemDepth < 5 && (
                    [
                        <Tooltip title="Create a new plan" placement='right'>
                            <MenuItem key="new-plan" onClick={() => { handlePlanOptionsClose(); onAddPlan(currentItem); }}>
                                <ListItemIcon>
                                    <AddCircleOutlineIcon fontSize="small" />
                                </ListItemIcon>
                                New Plan
                            </MenuItem>
                        </Tooltip>,
                        <Tooltip title="Create a new folder" placement='right'>
                            <MenuItem key="new-folder" onClick={() => { handlePlanOptionsClose(); onAddFolder(currentItem); }}>
                                <ListItemIcon>
                                    <CreateNewFolderOutlinedIcon fontSize="small" />
                                </ListItemIcon>
                                New Folder
                            </MenuItem>
                        </Tooltip>,
                        <Divider key="divider-1" />
                    ]
                )}

                <Tooltip title="Rename this item." placement='right'>
                    <MenuItem key="rename-item" onClick={() => { handlePlanOptionsClose(); onRenameItem(currentItem) }}>
                        <ListItemIcon>
                            <EditOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        Rename
                    </MenuItem>
                </Tooltip>

                <Tooltip title={currentItem.type === 'folder' ? "Change this folder's icon." : "Change this plan's icon."} placement='right'>
                    <MenuItem key="change-icon" onClick={(e) => { handleIconClick(e); handlePlanOptionsClose(); }}>
                        <ListItemIcon>
                            <InsertPhotoOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        Change Icon
                    </MenuItem>
                </Tooltip>

                <Tooltip title={currentItem.type === 'folder' ? 'Delete folder and all its contents.' : 'Delete this plan'} placement='right'>
                    <MenuItem key="delete-item" onClick={() => { handlePlanOptionsClose(); onDeletePlan(currentItem) }}>
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        Delete {currentItem.type === 'folder' ? 'Folder' : 'Plan'}
                    </MenuItem>
                </Tooltip>

                <Tooltip title={currentItem.archived ? 'Unarchive this item' : 'Archive this item'} placement='right'>
                    <MenuItem key="archive-item" onClick={() => handleToggleArchive(currentItem)}>
                        <ListItemIcon>
                            {currentItem.archived ? <UnarchiveIcon fontSize="small" /> : <ArchiveIcon fontSize="small" />}
                        </ListItemIcon>
                        {currentItem.archived ? 'Unarchive' : 'Archive'} {currentItem.type === 'folder' ? 'Folder' : 'Plan'}
                    </MenuItem>
                </Tooltip>
            </Menu>
        );
    };

    const renderItems = (items, level = 0) => {
        if (!items) return null;

        return items.map((item, index) => {
            if (!isInteractive && item.archived) return null; // Skip archived items if not in interactive mode

            return (
                <div >
                    <div
                        key={`item-${item.id}`}
                        className={`flex items-center justify-between p-2 cursor-pointer rounded ${selectedPlan && item.id === selectedPlan.id ? 'bg-primary-50 dark:bg-primary-50' : 'hover:bg-gray-200 dark:hover:bg-gray-200'}`}
                        style={{ opacity: item.archived ? 0.5 : 1 }}
                    >
                        <SortableItem
                            key={`sortable-${item.id}`}
                            itemInfo={item}
                            index={index}
                            onSelect={() => handleSelect(item)}
                            onMove={isDraggingEnabled ? handleMove : undefined}
                            disabled={!isDraggingEnabled}
                        >
                            <div className="flex-grow flex items-center">
                                <IconComponent
                                    size="small"
                                    type={item.type}
                                    iconSelection={item.icon}
                                />
                                <span className="ml-2">{item.title}</span>
                            </div>
                        </SortableItem>
                        {isInteractive && (
                            <Tooltip title="Remove, rename, and more..." placement='right'>
                                <IconButton
                                    size="small"
                                    onClick={(e) => handlePlanOptionsClick(e, item, level)}
                                >
                                    <MoreHorizOutlinedIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>

                    {item.type === 'folder' && item.isExpanded && (
                        <div key={`folder-${item.id}`} className="pl-4">
                            {renderItems(item.items, level + 1)}
                        </div>
                    )}
                </div>
            );
        });
    };

    return (
        <div>
            {renderItems(plans)}

            {renderMenuItems()}

            <Popover
                open={isIconMenuOpen}
                anchorEl={anchorIconEl}
                onClose={handleIconMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <IconsGrid
                    onSelect={selectIcon}
                    initialIcon={currentItem?.icon}
                />
            </Popover>
        </div>
    );
};

export default PlanSelector;
