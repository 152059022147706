import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';

import logger from "@/lib/logger";

const StripePricingTable = ({ user }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  logger.debug("StripePricingTable user.uid: ", user.uid);

  return React.createElement("stripe-pricing-table", {
    "pricing-table-id": process.env.NEXT_PUBLIC_STRIPE_PRICING_TABLE_ID,
    "publishable-key": process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
    "client-reference-id": user.uid,
    "customer-email": user.email,
  });
};

const StripePlansDialog = ({ open, onClose, user }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>View Payment Plans</DialogTitle>
      <DialogContent>
        <StripePricingTable user={user} />
      </DialogContent>
    </Dialog>
  );
};

export default StripePlansDialog;