import React, { useState, useEffect, useMemo } from 'react';
import { Button, CircularProgress, Typography, Tooltip, IconButton } from '@mui/material';
import TimelineBar from './TimelineBar';
import TimeRangeSelector from './TimeRangeSelector';
import { PlanInfo, ProgressInfo, TimelineMilestoneData, TimelineLayerItem, TimeRange, PredefinedTimeRange, CustomTimeRange, isCustomTimeRange, PrioritiesSnapshotReference, PrioritiesSnapshotData, UserPrioritiesDocument } from '@/lib/interfaces';
import { adjustMilestoneDates, calculateTimelineProgressInfo, debounce, getTimeRange, isWithinTimeRange } from '@/lib/utils';
import { StorageManager } from '@/lib/StorageManager';
import logger from "@/lib/logger";
import { useFirebaseAuthContext } from '@/context/FirebaseAuthContext';
import { toast } from 'sonner';
import { toPng } from 'html-to-image';
import { v4 as uuidv4 } from 'uuid';
import SnapshotGallery from './SnapshotGallery';

import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor';

interface TimelineViewProps {
    initialMilestonesByPlan: { [planId: string]: TimelineMilestoneData[] };
    onSelectPlan: (selectedPlan: PlanInfo) => void;
    onSelectMilestone: (selectedPlan: PlanInfo, milestone: TimelineMilestoneData) => void;
    isLoading: boolean;
}

const TimelineView: React.FC<TimelineViewProps> = ({ initialMilestonesByPlan, onSelectPlan, onSelectMilestone, isLoading }) => {
    const { user } = useFirebaseAuthContext();

    const storageManager = useMemo(() => new StorageManager(), []);
    const [milestones, setMilestones] = useState<TimelineMilestoneData[]>([]);
    const [planProgressInfo, setPlanProgressInfo] = useState<ProgressInfo>({ startDate: '', endDate: '', progress: 0 });
    const [milestonesByPlan, setMilestonesByPlan] = useState<{ [planId: string]: TimelineMilestoneData[] }>(initialMilestonesByPlan);

    const storedRange = localStorage.getItem('selectedTimeRange');
    let initialRange: TimeRange;

    if (storedRange && Object.values(PredefinedTimeRange).includes(storedRange as PredefinedTimeRange)) {
        initialRange = storedRange as PredefinedTimeRange;
    } else {
        try {
            initialRange = storedRange ? JSON.parse(storedRange) : PredefinedTimeRange.ThisMonth;
            if (typeof initialRange !== 'string' && (!initialRange.type || !initialRange.startDate || !initialRange.endDate)) {
                initialRange = PredefinedTimeRange.ThisMonth;
            }
        } catch {
            initialRange = PredefinedTimeRange.ThisMonth;
        }
    }

    const [timeRange, setTimeRange] = useState<TimeRange>(initialRange);

    const filterMilestonesByTimeRange = (mbp: { [planId: string]: TimelineMilestoneData[] }, timeRange: TimeRange) => {
        let start: Date, end: Date;

        if (typeof timeRange === 'string') {
            ({ start, end } = getTimeRange(timeRange));
        } else if (timeRange.type === 'Custom') {
            start = new Date(timeRange.startDate);
            end = new Date(timeRange.endDate);
            if (isNaN(start.getTime()) || isNaN(end.getTime())) {
                return;
            }
        } else {
            throw new Error('Invalid time range');
        }

        logger.debug('Filtering milestones by time range:', start, end);
        logger.debug('Initial milestones by plan:', mbp);

        const flattenedMilestones: TimelineMilestoneData[] = Object.values(mbp).flat();
        logger.debug('Flattened milestones:', flattenedMilestones);

        const filteredMilestones = flattenedMilestones.filter(milestone =>
            isWithinTimeRange(milestone, timeRange)
        );

        logger.debug('Filtered milestones:', filteredMilestones);

        const adjustedMilestones = adjustMilestoneDates(filteredMilestones, start, end);

        logger.debug('Adjusted milestones:', adjustedMilestones);

        setMilestones(adjustedMilestones);

        if (adjustedMilestones.length > 0) {
            const progressInfo = calculateTimelineProgressInfo(adjustedMilestones);
            setPlanProgressInfo(progressInfo);
        } else {
            setPlanProgressInfo({ startDate: start.toISOString(), endDate: end.toISOString(), progress: 0 });
        }
    };

    useEffect(() => {
        setMilestonesByPlan(initialMilestonesByPlan);

        filterMilestonesByTimeRange(initialMilestonesByPlan, timeRange);
    }, [timeRange, initialMilestonesByPlan]);

    const [snapshotRefs, setSnapshotRefs] = useState<PrioritiesSnapshotReference[]>([]);
    const [isTakingSnapshot, setIsTakingSnapshot] = useState(false);
    const [isGalleryOpen, setIsGalleryOpen] = useState(false);

    useEffect(() => {
        if (user) {
            fetchSnapshotRefs();
        }
    }, [user]);

    const fetchSnapshotRefs = async () => {
        if (!user) return;

        try {
            const userPriorities = await storageManager.get('priorities', user.uid);

            if (userPriorities && Array.isArray(userPriorities.snapshotRefs)) {
                setSnapshotRefs(userPriorities.snapshotRefs);
            } else {
                setSnapshotRefs([]);
            }
        } catch (error) {
            logger.error("Error fetching snapshot refs:", error);
            toast.error('Failed to fetch snapshots');
            setSnapshotRefs([]);
        }
    };

    const handleSnapshot = async () => {
        if (!user) {
            toast.error('You must be logged in to take a snapshot');
            return;
        }

        setIsTakingSnapshot(true);
        toast.message('Taking snapshot...');

        try {
            const timelineElement = document.getElementById('priorities-timeline');
            if (!timelineElement) {
                throw new Error("Timeline element not found.");
            }

            // Ensure the element is fully visible and not scrolled or clipped
            timelineElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

            const dataUrl = await toPng(timelineElement, {
                filter: (node) => {
                    return (node as HTMLElement).classList?.contains('screenshot-hide') !== true;
                }
            });

            const snapshotId = uuidv4();

            await storageManager.handleSnapshotUpload(dataUrl, null, snapshotId);

            const newSnapshotRef: PrioritiesSnapshotReference = {
                id: snapshotId,
                createdAt: new Date().toISOString(),
                timeRange
            };

            const newSnapshotData: PrioritiesSnapshotData = {
                ...newSnapshotRef,
                userId: user.uid,
                milestones
            };

            // Store snapshot data in pri-snapshots collection
            await storageManager.set('pri-snapshots', snapshotId, newSnapshotData);

            // Update user's priorities document with new snapshot reference
            await storageManager.set('priorities', user.uid, [...snapshotRefs, newSnapshotRef], 'snapshotRefs');

            setSnapshotRefs(prev => [...prev, newSnapshotRef]);
            setIsGalleryOpen(true);

            toast.success('Snapshot taken successfully!');
        } catch (error) {
            logger.error("Error taking snapshot:", error);
            toast.error('Failed to take snapshot.');
        } finally {
            setIsTakingSnapshot(false);
        }
    };

    const handleDeleteSnapshot = async (snapshotId: string) => {
        if (!user) return;

        try {
            const updatedRefs = snapshotRefs.filter(ref => ref.id !== snapshotId);

            await storageManager.set('priorities', user.uid, updatedRefs, 'snapshotRefs');

            await storageManager.delete('pri-snapshots', snapshotId);

            setSnapshotRefs(updatedRefs);
            toast.success('Snapshot deleted successfully');
        } catch (error) {
            logger.error("Error deleting snapshot:", error);
            toast.error('Failed to delete snapshot');
        }
    };

    const handleViewSnapshot = async (snapshot: PrioritiesSnapshotData) => {
        try {
            const snapshotData = await storageManager.get('pri-snapshots', snapshot.id) as PrioritiesSnapshotData;
            if (snapshotData) {
                logger.debug("Viewing snapshot:", snapshotData);
                // TODO: Implement snapshot viewing functionality
            } else {
                toast.error('Snapshot data not found');
            }
        } catch (error) {
            logger.error("Error fetching snapshot data:", error);
            toast.error('Failed to load snapshot data');
        }
    };

    const handlePlanClick = (plan: PlanInfo) => {
        onSelectPlan(plan);
    };

    const handleMilestoneClick = (planId, milestoneId) => {
        logger.debug('Milestone clicked:', planId, milestoneId);

        const milestone = milestones.find(m => m.id === milestoneId);

        if (milestone && milestone.plan) {
            onSelectMilestone(milestone.plan, milestone);
        }
    };

    const handleTimeRangeChange = (range: TimeRange) => {
        if (isCustomTimeRange(range)) {
            logger.debug("Custom time range selected:", range);

            const currentTimeRange = timeRange as CustomTimeRange;
            handleCustomRangeSet(currentTimeRange.startDate, currentTimeRange.endDate);
        } else {
            setTimeRange(range);
            localStorage.setItem('selectedTimeRange', JSON.stringify(range));
        }
    };

    const handleCustomRangeSet = (start: string, end: string) => {
        const tr = { type: 'Custom', startDate: start, endDate: end } as CustomTimeRange;
        setTimeRange(tr);
        localStorage.setItem('selectedTimeRange', JSON.stringify(tr));
    };

    const updateMilestones = async (updatedLayerItems: TimelineLayerItem[]) => {
        logger.debug("Updating milestones:", updatedLayerItems);

        const updatedMilestonesByPlan = { ...milestonesByPlan };

        updatedLayerItems.forEach(updatedLayerItem => {
            const updatedMilestone = updatedLayerItem.milestone;

            if (!updatedMilestone || !updatedMilestone.plan) {
                logger.error("Invalid milestone or plan:", updatedMilestone);
                return;
            }

            const planId = updatedMilestone.plan.id;

            if (!updatedMilestonesByPlan[planId]) {
                logger.debug("updated milestones by plan:", updatedMilestonesByPlan);

                logger.debug("No milestones found for plan ID:", planId);
                return;
            }

            updatedMilestonesByPlan[planId] = updatedMilestonesByPlan[planId].map(milestone =>
                milestone.id === updatedMilestone.id ? updatedMilestone : milestone
            );
        });

        setMilestonesByPlan(updatedMilestonesByPlan);

        try {
            toast('Saving...');

            const debouncedSaveMilestonesAcrossPlans = debounce(
                async (userId: string, updatedMilestonesByPlan: { [planId: string]: TimelineMilestoneData[] }) => {
                    storageManager.saveMilestonesAcrossPlans(userId, updatedMilestonesByPlan, () => {
                        toast.dismiss();
                        toast('Saved');
                    }, (error) => {
                        toast.dismiss();
                        toast.error('Failed to save');
                        logger.error("Failed to update milestones:", error);
                    });
                },
                2000
            );

            await debouncedSaveMilestonesAcrossPlans(user.uid, updatedMilestonesByPlan);

            logger.debug("Triggered saveMilestonesAcrossPlans");
        } catch (error) {
            logger.error("Failed to update milestones:", error);
            toast.dismiss();
            toast.error('Failed to save');
        }
    };

    return (
        <div id="priorities-timeline" style={{ width: '100%', minHeight: '100vh', overflowX: 'hidden', overflowY: 'auto', padding: '16px' }}>

            <div className='screenshot-hide' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px', gap: '8px' }}>
                <Typography variant="h4">Priorities</Typography>

                <div className="flex items-start mr-5">

                    <Tooltip title={'Take a snapshot'}>
                        <IconButton
                            onClick={handleSnapshot}
                            disabled={isTakingSnapshot}
                            color="primary"
                            sx={{
                                margin: '2px',
                            }}
                        >
                            {!isTakingSnapshot ? <ScreenshotMonitorIcon /> : <CircularProgress size={24} />}
                        </IconButton>
                    </Tooltip>

                    <Tooltip
                        title={snapshotRefs.length > 0 ?
                            "Open snapshots gallery to look back at the history of your priorities" :
                            "Taking snapshots on your priorities helps you tell a story over time, showing how you're evolving juggling multiple priorities by time frame."
                        }
                    >
                        <span>
                            <button
                                onClick={() => setIsGalleryOpen(true)}
                                disabled={snapshotRefs.length === 0}
                                className={
                                    `inline-flex items-center justify-center bg-opacity-70
                                        ${snapshotRefs.length === 0 ? 'bg-gray-400' : 'bg-primary-700 hover:bg-primary-500'} 
                                        text-white border-none rounded px-3 py-2 
                                        cursor-${snapshotRefs.length === 0 ? 'default' : 'pointer'} 
                                        outline-none m-1`
                                }
                            >
                                <PhotoLibraryIcon style={{ marginRight: '4px' }} />
                                Snapshots
                            </button>
                        </span>
                    </Tooltip>
                </div>
            </div>

            <TimeRangeSelector 
                key={`timeline-range-${timeRange}`} 
                onSelect={handleTimeRangeChange} 
                selectedRange={timeRange} 
            />

            <div style={{ position: 'relative', padding: '1rem' }}>
                {isLoading ? (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '300px', width: '100%' }}>
                        <CircularProgress size={48} />
                        <Typography variant="h6" component="div" style={{ marginTop: '20px' }}>
                            Loading priorities...
                        </Typography>
                    </div>
                ) : (
                    <TimelineBar
                        key={`timeline-view-${milestones?.length}`}
                        milestones={milestones}
                        planProgressInfo={planProgressInfo}
                        isIntimeline={true}
                        onTimeRangeChange={handleCustomRangeSet}
                        onSelectPlan={handlePlanClick}
                        onMilestoneClick={handleMilestoneClick}
                        updateMilestones={updateMilestones}
                    />
                )}
            </div>

            {isGalleryOpen && (
                <SnapshotGallery
                    snapshots={snapshotRefs}
                    open={isGalleryOpen}
                    onClose={() => { setIsGalleryOpen(false) }}
                    onDeleteSnapshot={handleDeleteSnapshot}
                    onViewSnapshot={handleViewSnapshot}
                />
            )}
        </div>
    );
};

export default TimelineView;
