import React, { useState, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Tooltip, Popover } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';

import ReactMarkdown from 'react-markdown';
import breaks from 'remark-breaks';

import CloseIcon from '@mui/icons-material/Close';

import { SketchPicker } from 'react-color';

import { RetroData } from '@/lib/interfaces';

import { getFirebaseAnalytics } from '@/lib/hooks/firebase';
import { logEvent } from 'firebase/analytics';

const bgPresetColors = [
    '#f6f6f8',
    '#fdf8b9',
    '#ecd14f',
    '#eaa257',
    '#def39e',
    '#cfdc6b',
    '#a7ce80',
    '#89c3c0',
    '#f3d1df',
    '#d89bb9',
    '#bea5cf',
    '#dc999e',
    '#b2ccf2',
    '#94d5f7',
    '#a2abf9',
    '#000000',
];

/*
const txPresetColors = [
    '#D9E3F0',
    '#F47373',
    '#697689',
    '#37D67A',
    '#2CCCE4',
    '#555555',
    '#dce775',
    '#ff8a65', 
    '#ba68c8'
];
*/

interface RetrospectiveNotesProps {
    retroData: RetroData;
    onSaveRetro: (data: RetroData) => void;
}

const RetrospectiveNotes: React.FC<RetrospectiveNotesProps> = ({ retroData, onSaveRetro }) => {
    const [open, setOpen] = useState(false);

    const defaultNoteContent: string = `
Reflect on achievements, challenges faced, and *lessons* learned...

**Start:** ...

**Stop:** ...

**Continue:** ...
`;

    const getRandomBGColor = () => {
        const randomIndex = Math.floor(Math.random() * (bgPresetColors.length - 1));
        return bgPresetColors[randomIndex];
    };

    const [noteTitle, setNoteTitle] = useState(retroData.title || '');
    const [noteContent, setNoteContent] = useState(retroData.note || defaultNoteContent);
    const [noteColor, setNoteColor] = useState({ bg: retroData.bgColor || getRandomBGColor(), text: retroData.textColor || '#000000' });

    const [showBgColorPicker, setShowBgColorPicker] = useState(false);
    const [showTextColorPicker, setShowTextColorPicker] = useState(false);

    const bgPickerAnchorRef = useRef(null);
    const textPickerAnchorRef = useRef(null);

    const handleBgColorChange = (color) => {
        setNoteColor({ ...noteColor, bg: color.hex });

        onSaveRetro({
            ...retroData,
            bgColor: color.hex,
        });

        logEvent(getFirebaseAnalytics(), 'retro_notes_bg_color_change');
    };

    const handleTextColorChange = (color) => {
        setNoteColor({ ...noteColor, text: color.hex });

        onSaveRetro({
            ...retroData,
            textColor: color.hex,
        });

        logEvent(getFirebaseAnalytics(), 'retro_notes_text_color_change');
    };

    const handleOpen = () => {
        setOpen(true);

        logEvent(getFirebaseAnalytics(), 'open_retro_notes');
    };

    const handleClose = () => {
        onSaveRetro({ ...retroData, title: noteTitle, note: noteContent, bgColor: noteColor.bg });
        setOpen(false);

        logEvent(getFirebaseAnalytics(), 'save_retro_notes');
    };

    return (
        <div className="flex w-full flex-col items-center justify-center p-4">
            <Tooltip title="Open Retro Notes" placement='right'>
                <div
                    onClick={handleOpen}
                    style={{ backgroundColor: noteColor.bg, color: noteColor.text }}
                    className="relative w-full cursor-pointer rounded-md p-2 overflow-hidden min-h-[20vh] max-h-[50vh]"
                >
                    <div className="font-bold truncate text-left">
                        {noteTitle || <span style={{ color: noteColor.text ? noteColor.text : 'text-gray-400' }}>Retrospective Notes</span>}
                    </div>
                    <div className={`markdown-content text-sm white-space-pre-wrap overflow-y-auto text-left`} style={{ color: noteColor.text ? noteColor.text : 'text-gray-600' }}>
                        <ReactMarkdown
                            remarkPlugins={[breaks]}
                            components={{
                                a: ({ ...props }) => <a {...props} target="_blank" rel="noopener noreferrer" />
                            }}
                        >
                            {noteContent}
                        </ReactMarkdown>
                    </div>

                    <div
                        className="absolute bottom-0 left-0 w-full h-4"
                        style={{
                            background: `linear-gradient(to bottom, transparent, ${noteColor.bg})`
                        }}
                    />
                </div>
            </Tooltip>

            <div className="screenshot-hide flex justify-between items-center my-2 gap-5">
                <Tooltip title="Change Background Color">
                    <div
                        ref={bgPickerAnchorRef}
                        className="w-6 h-6 rounded-full cursor-pointer border border-gray-300"
                        onClick={() => setShowBgColorPicker(true)}
                        style={{ backgroundColor: noteColor.bg }}
                    ></div>
                </Tooltip>
                <Tooltip title="Change Text Color">
                    <div
                        ref={textPickerAnchorRef}
                        className="w-6 h-6 rounded-full cursor-pointer border border-gray-300"
                        onClick={() => setShowTextColorPicker(true)}
                        style={{ backgroundColor: noteColor.text }}
                    ></div>
                </Tooltip>
            </div>

            {/* Text Color Picker Popover */}
            <Popover
                open={showTextColorPicker}
                anchorEl={textPickerAnchorRef.current}
                onClose={() => setShowTextColorPicker(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >

                <>
                    <style>
                        {`.sketch-picker input {
                            background: white !important;
                        }`}
                    </style>
                    <SketchPicker color={noteColor.text} onChangeComplete={handleTextColorChange} />
                </>

            </Popover>

            {/* Background Color Picker Popover */}
            <Popover
                open={showBgColorPicker}
                anchorEl={bgPickerAnchorRef.current}
                onClose={() => setShowBgColorPicker(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >

                <>
                    <style>
                        {`.sketch-picker input {
                            background: white !important;
                        }`}
                    </style>
                    <SketchPicker
                        color={noteColor.bg}
                        onChangeComplete={handleBgColorChange}
                        presetColors={bgPresetColors}
                    />
                </>
            </Popover>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                PaperProps={{
                    style: {
                        width: '76vw',
                        height: '76vh',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        backgroundColor: noteColor.bg,
                        color: noteColor.text,
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                    },
                }}
            >
                <DialogTitle id="form-dialog-title">Edit Note</DialogTitle>
                <DialogContent style={{ flex: 1, overflowY: 'auto' }}>
                    <input
                        type="text"
                        placeholder="Enter Note Title..."
                        value={noteTitle}
                        onChange={(e) => setNoteTitle(e.target.value)}
                        className="w-full p-2 border border-gray-300 focus:outline-none focus:border-blue-200 rounded-lg mb-2"
                        style={{ backgroundColor: noteColor.bg, color: noteColor.text }}
                    />
                    <TextareaAutosize
                        minRows={3}
                        placeholder="Reflect on the milestones achieved, challenges faced, and lessons learned..."
                        className="w-full p-2 focus:outline-none border-none rounded-lg resize-none"
                        value={noteContent}
                        onChange={(e) => setNoteContent(e.target.value)}
                        style={{ backgroundColor: noteColor.bg, color: noteColor.text }}
                    />
                </DialogContent>
                <IconButton
                    onClick={handleClose}
                    style={{
                        position: 'absolute',
                        right: '1rem',
                        top: '1rem',
                        color: noteColor.text,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Dialog>
        </div >
    );
};

export default RetrospectiveNotes;