import React, { useState } from 'react';

import { Box, Typography, CircularProgress } from '@mui/material';

const LoadingIndicator = () => {
  const [quote] = useState(quotes[1]);

  /*
  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * quotes.length);
    setQuote(quotes[randomIndex]);
  }, []);
  */

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={5}
      p={5}
      sx={{ width: '100%' }}
    >
      <Typography variant="h5" component="h2">
        Just a moment...
      </Typography>
      <CircularProgress size={48} />
      <Typography
        variant="subtitle1"
        style={{ textAlign: 'center', maxWidth: '80%', margin: '0 auto', fontStyle: 'italic' }}
      >
        {quote}
      </Typography>
    </Box>
  );
};

const quotes = [
  "A goal without a plan is just a wish. \n— Antoine de Saint-Exupéry",
  "Planning is bringing the future into the present so that you can do something about it now. \n— Alan Lakein",
  "By failing to prepare, you are preparing to fail. \n— Benjamin Franklin",
  "Plans are nothing; planning is everything. \n- Dwight D. Eisenhower",
  "Planning is a process of choosing among those many options. If we do not choose to plan, then we choose to have others plan for us. \n- Richard I. Winwood",
  "Good fortune is what happens when opportunity meets with planning. \n- Thomas Edison",
  "You can't plow a field simply by turning it over in your mind. \n— Gordon B. Hinckley",
  "An hour of planning can save you 10 hours of doing. \n— Dale Carnegie",
  "To achieve great things, two things are needed: a plan, and not quite enough time. \n— Leonard Bernstein",
  "If you don't know where you are going, you'll end up someplace else. \n— Yogi Berra"
];

export default LoadingIndicator;