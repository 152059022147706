import React from 'react';

import { IconButton } from '@mui/material';

import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';

import { Icons } from '@/lib/IconUtils';
import { IconSelectionInfo } from '@/lib/interfaces';

interface IconComponentProps {
    size: 'small' | 'medium' | 'large';
    type: 'folder' | 'plan' | 'milestone';
    onClick?: (event) => void;
    iconSelection?: IconSelectionInfo;
}

// Define the component using the props type
const IconComponent: React.FC<IconComponentProps> = ({ size, type, onClick, iconSelection }) => {
    const sizes = {
        small: { width: '25px', fontSize: '1rem' },
        medium: { width: '37.5px', fontSize: '1.5rem' },
        large: { width: '50px', fontSize: '2rem' }
    };

    const { width, fontSize } = sizes[size] || sizes.small; 

    const getDefaultIcon = () => {
        if (type === 'folder') {
            return FolderOutlinedIcon;
        } else if (type === 'milestone') {
            return MapOutlinedIcon;
        } else {
            return AssignmentOutlinedIcon;
        }
    };

    const Icon = iconSelection?.name ? Icons[iconSelection.name] || getDefaultIcon() : getDefaultIcon();

    return (
        <IconButton
            onClick={(e) => onClick && onClick(e)}
            style={{
                aspectRatio: '1 / 1',
                width: width,
                padding: '0 5px',
                color: iconSelection?.color ?? '#f6f6f8',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                backgroundColor: iconSelection?.backgroundColor ?? '#1976d2',
            }}
            className='screenshot-show'
        >
            <div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0 10px',
            }}>
                {iconSelection?.isEmoji ? (
                    <span style={{ fontSize: fontSize, display: 'flex', alignItems: 'center' }}>
                        {iconSelection?.name}
                    </span>
                ) : (
                    <Icon style={{ fontSize, color: iconSelection?.color ?? '#f6f6f8' }} />
                )}
            </div>
        </IconButton>
    );
};

export default IconComponent;
