import React, { useRef, useEffect, useState }  from 'react';
import { useDrop } from 'react-dnd';

import logger from "@/lib/logger";
import DraggableTimelineMilestone from './DraggableTimelineMilestone';
import { Tooltip } from '@mui/material';

import { DragItemTypes, IconSelectionInfo, PlanMenuInfo, PlanMilestoneData, TIMELINE_LAYER_HEIGHT, TimelineLayerItem } from '@/lib/interfaces';
import { debounce, formatDate } from '@/lib/utils';

interface TimelineLayerProps {
    layer: TimelineLayerItem[];
    layerIndex: number;
    containerWidth: number;
    moveMilestone: (sourceLayer: number, sourceMilestoneId: string, destinationLayer: number) => void;
    removeMilestoneInLayer: (layerIndex: number, milestoneId: string) => void;
    updateMilestones: (milestones: TimelineLayerItem[]) => void;
    onMilestoneClick: (planId: string, milestoneId: string) => void;
    isIntimeline?: boolean;
    iconSelections?: { [key: string]: IconSelectionInfo };
    handleIconClickForPlan: (e: React.MouseEvent, plan: PlanMenuInfo) => void;
    timelineStartDate: Date;
    timelineEndDate: Date;
    onExpandTimeline: (direction: 'left' | 'right', date: Date) => void;
}

const TimelineLayer: React.FC<TimelineLayerProps> = ({
    layer,
    layerIndex,
    containerWidth,
    moveMilestone,
    removeMilestoneInLayer,
    updateMilestones,
    onMilestoneClick,
    isIntimeline,
    iconSelections,
    handleIconClickForPlan,
    timelineStartDate,
    timelineEndDate,
    onExpandTimeline
}) => {
    const debouncedUpdateMilestones = debounce(updateMilestones, 10);

    const [{ isOver }, connectDrop] = useDrop({
        accept: DragItemTypes.TIMELINE_MILESTONE,
        drop: (item: { layerIndex: number; milestone: PlanMilestoneData }) => {
            const sourceLayer = item.layerIndex;
            const sourceMilestoneId = item.milestone.id;
            const destinationLayer = layerIndex;
    
            moveMilestone(sourceLayer, sourceMilestoneId, destinationLayer);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver({ shallow: true }),
        }),
    });

    const dropRef = useRef(null);

    useEffect(() => {
        connectDrop(dropRef);
    }, [connectDrop]);

    const updateLayerItem = (updatedLayerItem: TimelineLayerItem) => {
        // find the item based on milestone id and update it
        layer = layer.map((layerItem) => {
            if (layerItem.milestone.id === updatedLayerItem.milestone.id) {
                return updatedLayerItem;
            }
            return layerItem;
        });

        logger.debug('Updated layer:', layer);

        debouncedUpdateMilestones([updatedLayerItem]);
    };

    const onMilestoneResize = (layerItem: TimelineLayerItem) => {
        // Find conflicting items and recursively move them
        const conflictingItems = layer.filter(
            item => item.milestone.id !== layerItem.milestone.id &&
                !(layerItem.endPercent <= item.startPercent || layerItem.startPercent >= item.endPercent)
        );

        for (const conflictingItem of conflictingItems) {
            // Recursively move the conflicting item to the next layer
            moveMilestone(layerIndex, conflictingItem.milestone.id, layerIndex + 1);
        }
    }

    const removeMilestone = (milestoneId: string) => {
        layer = layer.filter(layerItem => layerItem.milestone.id !== milestoneId);

        removeMilestoneInLayer(layerIndex, milestoneId);
    };

    const [showLeftExpand, setShowLeftExpand] = useState(false);
    const [showRightExpand, setShowRightExpand] = useState(false);
    const [leftExpandDate, setLeftExpandDate] = useState<Date | null>(null);
    const [rightExpandDate, setRightExpandDate] = useState<Date | null>(null);

    useEffect(() => {
        let earliestStart: Date | null = null;
        let latestEnd: Date | null = null;

        layer.forEach(layerItem => {
            const milestoneStart = new Date(layerItem.milestone.startDate);
            const milestoneEnd = new Date(layerItem.milestone.endDate);

            if (milestoneStart < timelineStartDate) {
                if (!earliestStart || milestoneStart < earliestStart) {
                    earliestStart = milestoneStart;
                }
            }

            if (milestoneEnd > timelineEndDate) {
                if (!latestEnd || milestoneEnd > latestEnd) {
                    latestEnd = milestoneEnd;
                }
            }
        });

        setShowLeftExpand(!!earliestStart && formatDate(earliestStart) !== formatDate(timelineStartDate));
        setShowRightExpand(!!latestEnd && formatDate(latestEnd) !== formatDate(timelineEndDate));
        setLeftExpandDate(earliestStart);
        setRightExpandDate(latestEnd);
    }, [layer, timelineStartDate, timelineEndDate]);

    return (
        <div ref={dropRef}
            style={{
                border: isOver ? '2px dashed #1976d2' : '1px solid transparent',
                borderRadius: '4px',
                height: `${TIMELINE_LAYER_HEIGHT}px`,
                width: '100%',
            }}
        >
            {showLeftExpand && leftExpandDate && (
                <Tooltip
                    title={`Expand timeline to start on ${formatDate(leftExpandDate)}`}
                    placement="left"
                >
                    <div
                        style={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            bottom: 0,
                            width: '10px',
                            cursor: 'w-resize',
                            zIndex: 10,
                        }}
                        onClick={() => onExpandTimeline('left', leftExpandDate)}
                    />
                </Tooltip>
            )}

            {layer.map((layerItem) => (
                <DraggableTimelineMilestone
                    key={layerItem.milestone.id}
                    layerItem={layerItem}
                    setLayerItem={updateLayerItem}
                    containerWidth={containerWidth}
                    onMilestoneClick={onMilestoneClick}
                    onMilestoneResize={onMilestoneResize}
                    onMilestoneRemove={removeMilestone}
                    isIntimeline={isIntimeline}
                    iconSelections={iconSelections}
                    handleIconClickForPlan={handleIconClickForPlan}
                    timelineStartDate={timelineStartDate}
                    timelineEndDate={timelineEndDate}
                />
            ))}

            {showRightExpand && rightExpandDate && (
                <Tooltip
                    title={`Expand timeline to end on ${formatDate(rightExpandDate)}`}
                    placement="right"
                >
                    <div
                        style={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            bottom: 0,
                            width: '10px',
                            cursor: 'e-resize',
                            zIndex: 10,
                        }}
                        onClick={() => onExpandTimeline('right', rightExpandDate)}
                    />
                </Tooltip>
            )}
        </div>
    );
};

export default TimelineLayer;
