import React, { useState, useMemo, useEffect, useContext, useCallback } from 'react';
import { AppContext } from "@/app/providers";

import { Stepper, Step, StepLabel, TextField, Dialog, Paper, MenuItem, DialogActions, DialogTitle, DialogContent, Button, Grid, Card, CardActionArea, CardMedia, CardContent, Typography, Tooltip, CircularProgress } from '@mui/material';
import { List, ListItemButton, ListItemText, Divider } from '@mui/material';

import { StorageManager } from '@/lib/StorageManager';
import { adjustMilestones, createBlankPlan, getDurationText, getTotalDurationText } from '@/lib/utils';
import { PlanCreationInfo, PlanDefinition } from '@/lib/interfaces';

import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker, DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import LocalFloristOutlinedIcon from '@mui/icons-material/LocalFloristOutlined';

import logger from "@/lib/logger";

import { useFirebaseAuth, getFirebaseAnalytics } from '@/lib/hooks/firebase';
import { logEvent } from 'firebase/analytics';
import useSubscription from '@/lib/hooks/useSubscription';
import { toast } from 'sonner';
import PlanPromptForm from './PlanPromptForm';
import { generateRandomIcons } from '@/lib/IconUtils';

const templates = [
  /***** PERSONAL *****/

  // Health & Wellness Templates
  { id: 'mental-wellness', title: "Mental Wellness", image: "mental_wellness", description: "Year-long plan for improving mental health and resilience.", duration: '1Y', category: 'health' },
  { id: 'healthy-eating', title: "Healthy Eating", image: "healthy_eating", description: "3-month plan for healthy eating habits.", duration: '3M', category: 'health' },
  { id: '1k-to-marathon', title: "1k To Marathon", image: "marathon", description: "12 month plan to go from 1k to marathon.", duration: '1Y', category: 'health' },
  { id: 'weight-loss-plan', title: "Weight Loss Plan", image: "weight_loss", description: "6 month weight loss and fitness program.", duration: '6M', category: 'health' },
  { id: 'yoga-mindfulness', title: "Yoga & Mindfulness", image: "yoga_mindfullness", description: "Year-long yoga and mindfulness routine.", duration: '1Y', category: 'health' },
  { id: 'gratitude-practice', title: "Gratitude Practice", image: "gratitude", description: "30-day challenge to cultivate gratitude and positivity.", duration: '1M', category: 'health' },
  { id: 'fitness-challenge', title: "Fitness Challenge", image: "fitness_challenge", description: "30-day fitness challenge for all levels.", duration: '1M', category: 'health' },
  { id: 'stress-management', title: "Stress Management", image: "stress_management", description: "Strategies for managing and reducing stress over 3 months.", duration: '3M', category: 'health' },
  { id: 'therapy-journey', title: "Therapy Journey", image: "therapy", description: "Guided plan for starting and continuing therapy sessions.", duration: '1Y', category: 'health' },
  { id: 'meditation-practice', title: "Meditation Practice", image: "meditation", description: "Developing a daily meditation habit for stress reduction.", duration: '6M', category: 'health' },

  // Finance Templates
  { id: 'emergency-fund', title: "Emergency Fund", image: "emergency_fund", description: "Build an emergency fund by saving 15% of your monthly income.", duration: '2Y', category: 'finance' },
  { id: 'debt-free', title: "Debt-Free Plan", image: "debt_elimination", description: "Plan to pay off debt and become debt-free.", duration: '1Y', category: 'finance' },
  { id: 'savings-goal', title: "Savings Goal", image: "savings", description: "Setting and achieving a specific savings goal.", duration: '1Y', category: 'finance' },
  { id: 'investment-strategy', title: "Investment Strategy", image: "investment_strategy", description: "Developing a personal investment strategy.", duration: '9M', category: 'finance' },
  { id: 'budgeting-basics', title: "Budgeting Basics", image: "budgeting", description: "Learning the basics of effective budgeting.", duration: '6M', category: 'finance' },

  // Home & Property Templates
  { id: 'home-buying', title: "Home Buying Plan", image: "home_buying", description: "A step-by-step guide to buying your first home.", duration: '2Y', category: 'home' },
  { id: 'second-home', title: "Second Home Buying Plan", image: "second_home", description: "A step-by-step guide to buying your second home.", duration: '2Y', category: 'home' },
  { id: 'mortgage-refinance', title: "Mortgage Refinance", image: "mortgage_refinance", description: "Plan for refinancing your mortgage.", duration: '2M', category: 'home' },
  { id: 'home-maintenance', title: "Home Maintenance", image: "home_maintenance", description: "Annual home maintenance and upkeep plan.", duration: '1Y', category: 'home' },
  { id: 'garden-landscaping', title: "Garden & Landscaping", image: "garden_landscaping", description: "Landscaping and garden planning for your home.", duration: '6M', category: 'home' },
  { id: 'home-renovation', title: "Home Renovation", image: "home_renovation", description: "Plan for renovating and upgrading your home.", duration: '2Y', category: 'home' },
  { id: 'home-insurance', title: "Home Insurance", image: "home_insurance", description: "Choosing the right home insurance plan.", duration: '1M', category: 'home' },
  {
    id: 'build-own-home',
    title: "Build Your Own Home",
    image: "build_own_home",
    description: "Comprehensive plan for designing and building your own home from the ground up.",
    duration: '3Y',
    category: 'home'
  },
  {
    id: 'emergency-preparedness',
    title: "Emergency Preparedness Plan",
    image: "emergency_preparedness",
    description: "Detailed plan for preparing your home for emergencies, including natural disasters, power outages, and other unforeseen events.",
    duration: '6M',
    category: 'home'
  },

  // Family Planning Templates
  { id: 'kids-activities', title: "Kids' Activities", image: "kids_activities", description: "Planning engaging and educational activities for kids.", duration: '1Y', category: 'family' },
  { id: 'parenting-strategies', title: "Parenting Strategies", image: "parenting", description: "Year-long plan for effective and mindful parenting.", duration: '1Y', category: 'family' },
  { id: 'family-budget', title: "Family Budget", image: "budget", description: "Annual family budget plan.", duration: '1Y', category: 'family' },
  { id: 'education-fund', title: "Education Fund", image: "education_fund", description: "Plan for saving for children's education.", duration: '15Y', category: 'family' },
  { id: 'family-holidays', title: "Family Holidays", image: "holidays", description: "Annual plan for family holidays and vacations.", duration: '1Y', category: 'family' },
  { id: 'family-health', title: "Family Health Plan", image: "family_health", description: "Healthcare and wellness plan for the whole family.", duration: '1Y', category: 'family' },

  // Hobbies Templates
  { id: 'language-learning', title: "Language Learning", image: "language", description: "One year plan to learn a new language.", duration: '1Y', category: 'hobbies' },
  { id: 'reading-habit', title: "Reading Habit", image: "reading", description: "Building a consistent reading habit.", duration: '1Y', category: 'hobbies' },
  { id: 'book-writing', title: "Book Writing", image: "writing", description: "Step-by-step guide to write and complete a book in a year.", duration: '1Y', category: 'hobbies' },
  // writing a book, blog or newsletter, song, develop an app, Video Game Design, etc.
  { id: 'photography-basics', title: "Photography Basics", image: "photography", description: "Learning the basics of photography over 6 months.", duration: '6M', category: 'hobbies' },
  { id: 'cooking-classes', title: "Cooking Classes", image: "cooking", description: "Monthly cooking classes for culinary skills.", duration: '1Y', category: 'hobbies' },
  { id: 'painting-course', title: "Painting Course", image: "painting", description: "Exploring painting techniques and styles.", duration: '6M', category: 'hobbies' },
  { id: 'game-design', title: "Video Game Design", image: "game_design", description: "Learning game design and development over 9 months.", duration: '9M', category: 'hobbies' },
  { id: 'app-development', title: "App Development", image: "app_dev", description: "Creating and launching an app in 6 months.", duration: '6M', category: 'hobbies' },
  // Drawing and Sketching, Sculpture, Pottery, Calligraphy, Knitting, Sewing, Woodworking, Candle Making, Jewelry Making, Birdwatching, 3D Printing, Origami, Winemaking
  // Food Preservation, Robotics, Stargazing, journaling, Stamp Collecting, Model Building, Action Figure or Toy Collecting, Coin Collecting, Antique Collecting, Vinyl Record Collecting

  // Sports & Lifestyle Templates
  // Running, Yoga, Backbacking, Swimming, Triathlon, etc.
  // Martial Arts, Hiking, Backpacking, camping, soccer, basketball, football, baseball, tennis, golf, kayaking, canoeing, surfing, skateboarding, skiing, snowboarding, and all the other sports
  { id: 'martial-arts-training', title: "Martial Arts Training", image: "martial_arts", description: "Year-long training in a chosen martial art discipline.", duration: '1Y', category: 'lifestyle' },
  { id: 'hiking-adventures', title: "Hiking Adventures", image: "hiking", description: "Planning and executing monthly hiking trips.", duration: '1Y', category: 'lifestyle' },
  { id: 'soccer-season', title: "Soccer Season", image: "soccer", description: "Complete plan for a season of soccer training and matches.", duration: '6M', category: 'lifestyle' },

  // Music & Arts Templates
  { id: 'guitar-lessons', title: "Guitar Lessons", image: "guitar", description: "Plan for learning to play the guitar.", duration: '1Y', category: 'music' },
  { id: 'piano-lessons', title: "Piano Lessons", image: "piano", description: "Year-long plan for learning to play the piano.", duration: '1Y', category: 'music' },
  { id: 'singing-masterclass', title: "Singing Masterclass", image: "singing", description: "Six-month course to improve vocal skills and techniques.", duration: '6M', category: 'music' },
  { id: 'acting-workshop', title: "Acting Workshop", image: "acting", description: "Three-month intensive acting workshop.", duration: '3M', category: 'music' },
  // fill all the other musical intruments
  // singing, songwriting, music production, joining a band, etc.
  // Acting & Theater, Dance, Improv Comedy, etc.

  // Travel Templates
  { id: 'world-tour', title: "World Tour", image: "world_tour", description: "Planning a world tour adventure.", duration: '1Y', category: 'travel' },
  { id: 'cultural-exploration', title: "Cultural Exploration", image: "culture", description: "Exploring new cultures and countries.", duration: '6M', category: 'travel' },
  { id: 'eco-travel', title: "Eco Travel", image: "eco_travel", description: "Eco-friendly travel planning.", duration: '1Y', category: 'travel' },
  { id: 'luxury-getaway', title: "Luxury Getaway", image: "luxury", description: "Planning a luxury vacation.", duration: '3M', category: 'travel' },
  { id: 'budget-travel', title: "Budget Travel", image: "budget_travel", description: "Exploring the world on a budget.", duration: '6M', category: 'travel' },
  // Geocaching, Road Trips, etc.

  // Events Templates
  { id: 'wedding-planning', title: "Wedding Planning", image: "wedding", description: "Complete guide for planning your dream wedding in a year.", duration: '1Y', category: 'events' },
  { id: 'birthday-bash', title: "Birthday Bash", image: "birthday", description: "Plan a memorable birthday party.", duration: '1M', category: 'events' },
  { id: 'anniversary-celebration', title: "Anniversary Celebration", image: "anniversary", description: "Ideas and planning for a romantic anniversary celebration.", duration: '3M', category: 'events' },
  // wedding, birthdays, anniversaries (special romantic events like Valentine), graduation, etc.

  // Retirement Templates
  { id: 'retire-savings', title: "Retirement Savings", image: "savings", description: "A plan to optimize savings for retirement.", duration: '5Y', category: 'retire' },
  { id: 'retire-invest', title: "Investment for Retirement", image: "invest", description: "Long-term investment strategies for retirement.", duration: '10Y', category: 'retire' },
  { id: 'retire-budget', title: "Retirement Budget", image: "budget", description: "Annual budget planning for post-retirement.", duration: '1Y', category: 'retire' },
  { id: 'retire-healthcare', title: "Healthcare in Retirement", image: "healthcare", description: "Planning for healthcare needs in retirement.", duration: '10Y', category: 'retire' },
  { id: 'retire-travel', title: "Retirement Travel", image: "travel", description: "Planning for travel adventures in retirement.", duration: '5Y', category: 'retire' },

  // Sustainability Templates
  { id: 'eco-friendly-home', title: "Eco-Friendly Home", image: "eco_home", description: "Converting your home to be more eco-friendly.", duration: '1Y', category: 'sustain' },
  { id: 'recycling-plan', title: "Recycling Plan", image: "recycling", description: "Implementing a comprehensive recycling plan at home.", duration: '6M', category: 'sustain' },
  { id: 'green-energy', title: "Green Energy", image: "green_energy", description: "Switching to green energy sources.", duration: '1Y', category: 'sustain' },
  { id: 'sustainable-gardening', title: "Sustainable Gardening", image: "sustain_garden", description: "Creating a sustainable and organic garden.", duration: '1Y', category: 'sustain' },
  { id: 'water-conservation', title: "Water Conservation", image: "water_cons", description: "Efficient water usage and conservation methods.", duration: '6M', category: 'sustain' },
  { id: 'composting-basics', title: "Composting Basics", image: "composting", description: "Learning and starting home composting in 6 months.", duration: '6M', category: 'sustain' },
  { id: 'beekeeping-startup', title: "Beekeeping Startup", image: "beekeeping", description: "Setting up and managing a beekeeping venture.", duration: '1Y', category: 'sustain' },
  { id: 'solar-diy', title: "Solar Power DIY Project", image: "solar_diy", description: "Building and installing a home solar power system.", duration: '1Y', category: 'sustain' },

  // Estate Planning Templates
  { id: 'will-preparation', title: "Will Preparation", image: "will_prep", description: "Creating and finalizing a will.", duration: '6M', category: 'estate' },
  { id: 'asset-allocation', title: "Asset Allocation", image: "asset_alloc", description: "Planning the allocation of your assets.", duration: '1Y', category: 'estate' },
  { id: 'trust-fund', title: "Trust Fund Setup", image: "trust_fund", description: "Setting up a trust fund for family.", duration: '1Y', category: 'estate' },
  { id: 'estate-tax', title: "Estate Tax Planning", image: "estate_tax", description: "Planning for estate taxes and liabilities.", duration: '1Y', category: 'estate' },
  { id: 'legacy-planning', title: "Legacy Planning", image: "legacy", description: "Creating a legacy plan for future generations.", duration: '2Y', category: 'estate' },

  /***** PROFESSIONAL *****/

  // Career Templates
  { id: 'promotion', title: "Getting a Promotion", description: "Plan for achieving the next level in your current job.", duration: '1Y', category: 'career' },
  { id: 'job-change', title: "Changing Jobs", description: "Strategy for transitioning to a different job or career path.", duration: '6M', category: 'career' },
  { id: 'new-career', title: "Starting a New Career Path", description: "Guide for entering a completely new field.", duration: '1Y', category: 'career' },
  { id: 'career-pivot', title: "Career Pivot Plan", description: "Transitioning from one industry to another.", duration: '1Y', category: 'career' },
  { id: 'freelance-path', title: "Freelance Career Path", description: "Building a successful freelance career in your field.", duration: '2Y', category: 'career' },
  { id: 'networking-strategy', title: "Networking Strategy", description: "Developing professional connections for career advancement.", duration: '6M', category: 'career' },

  // Education Templates
  { id: 'online-course', title: "Online Course Completion", image: "online_course", description: "Plan to complete a specific online course.", duration: '6M', category: 'edu' },
  { id: 'degree-plan', title: "Degree Planning", image: "degree", description: "Long-term plan for completing a degree program.", duration: '4Y', category: 'edu' },
  { id: 'certification-track', title: "Certification Track", image: "certification", description: "Roadmap to achieving a professional certification.", duration: '1Y', category: 'edu' },
  { id: 'skill-development', title: "Skill Development", image: "skill_dev", description: "Plan for developing a new professional skill.", duration: '6M', category: 'edu' },
  { id: 'academic-research', title: "Academic Research", image: "research", description: "Planning an academic research project.", duration: '2Y', category: 'edu' },
  { id: 'professional-workshop', title: "Professional Workshop Attendance", description: "Plan to attend workshops to enhance skills.", duration: '3M', category: 'edu' },
  { id: 'study-abroad', title: "Study Abroad Planning", description: "Preparing for educational experiences in another country.", duration: '1Y', category: 'edu' },
  { id: 'grad-school-application', title: "Graduate School Application", description: "Steps for applying to graduate programs.", duration: '6M', category: 'edu' },
  { id: 'continuing-education', title: "Continuing Education", description: "Plan for lifelong learning and education enhancement.", duration: '1Y', category: 'edu' },

  // Professional Development Templates
  { id: 'career-advancement', title: "Career Advancement", image: "career_adv", description: "Strategies for advancing in your career.", duration: '3Y', category: 'profDev' },
  { id: 'public-speaking', title: "Public Speaking", image: "speaking", description: "Improving public speaking skills.", duration: '6M', category: 'profDev' },
  // { id: 'time-management', title: "Time Management", image: "time_management", description: "Mastering time management skills.", duration: '3M', category: 'profDev' },
  { id: 'personal-branding', title: "Personal Branding", image: "branding", description: "Developing and promoting your personal brand.", duration: '1Y', category: 'profDev' },
  // { id: 'leadership-training', title: "Leadership Training", image: "leadership_train", description: "Plan for undergoing leadership training.", duration: '6M', category: 'profDev' },
  { id: 'communication-skills', title: "Communication Skills", image: "communication", description: "Improving professional communication skills.", duration: '6M', category: 'profDev' },
  // { id: 'project-management', title: "Project Management", image: "project_mgmt", description: "Developing project management skills.", duration: '1Y', category: 'profDev' },
  // Sales and Negotiation, Networking, Customer Service, Conflict Resolution, Enterpreneurship, Critical Thinking, Problem Solving, Mentorship, Digital Marketing, etc.
  // Content Creation, Social Media Management, Data Analysis, Coding and Programming, Graphic Design, Web Design, etc.

  // Leadership Templates
  { id: 'team-building', title: "Team Building", image: "team_building", description: "Plan for building and managing an effective team.", duration: '6M', category: 'leadership' },
  { id: 'conflict-resolution', title: "Conflict Resolution", image: "conflict_res", description: "Strategies for resolving workplace conflicts.", duration: '3M', category: 'leadership' },
  { id: 'motivational-leadership', title: "Motivational Leadership", image: "motivational", description: "Developing motivational leadership skills.", duration: '1Y', category: 'leadership' },
  { id: 'strategic-planning', title: "Strategic Planning", image: "strategic", description: "Creating and executing strategic business plans.", duration: '1Y', category: 'leadership' },
  { id: 'decision-making', title: "Decision Making", image: "decision", description: "Enhancing decision-making skills.", duration: '6M', category: 'leadership' },
  { id: 'empathy-development', title: "Empathy Development", image: "empathy", description: "Improving emotional intelligence and empathy in leadership.", duration: '6M', category: 'leadership' },
  { id: 'leadership-coaching', title: "Leadership Coaching", image: "coaching", description: "Engaging in coaching to enhance leadership abilities.", duration: '1Y', category: 'leadership' },
  { id: 'change-management', title: "Change Management", image: "change_mgmt", description: "Leading and managing organizational change effectively.", duration: '1Y', category: 'leadership' },
  { id: 'diversity-inclusion', title: "Diversity and Inclusion", image: "diversity", description: "Fostering a diverse and inclusive workplace environment.", duration: '6M', category: 'leadership' },
  { id: 'innovative-thinking', title: "Innovative Thinking", image: "innovation", description: "Cultivating innovative thinking and creativity in leadership.", duration: '6M', category: 'leadership' },

  // Business Templates
  { id: 'start-up-plan', title: "Start-Up Plan", image: "startup", description: "Creating a plan for a new business startup.", duration: '1Y', category: 'business' },
  { id: 'marketing-strategy', title: "Marketing Strategy", image: "marketing", description: "Developing a comprehensive marketing strategy.", duration: '6M', category: 'business' },
  { id: 'growth-plan', title: "Business Growth Plan", image: "growth", description: "Planning for business expansion and growth.", duration: '3Y', category: 'business' },
  { id: 'financial-management', title: "Financial Management", image: "financial_mgmt", description: "Improving business financial management.", duration: '1Y', category: 'business' },
  { id: 'customer-engagement', title: "Customer Engagement", image: "customer_engage", description: "Strategies for enhancing customer engagement.", duration: '1Y', category: 'business' },

  // Philanthropy Templates
  { id: 'charity-event', title: "Charity Event Planning", image: "charity_event", description: "Organizing and executing a charity event.", duration: '6M', category: 'philanthropy' },
  { id: 'volunteering-schedule', title: "Volunteering Schedule", image: "volunteering", description: "Creating a regular volunteering schedule.", duration: '1Y', category: 'philanthropy' },
  { id: 'fundraising-campaign', title: "Fundraising Campaign", image: "fundraising", description: "Planning and launching a fundraising campaign.", duration: '6M', category: 'philanthropy' },
  { id: 'nonprofit-strategy', title: "Nonprofit Strategy", image: "nonprofit", description: "Developing a strategic plan for a nonprofit organization.", duration: '1Y', category: 'philanthropy' },
  { id: 'community-project', title: "Community Project", image: "community", description: "Planning and implementing a community project.", duration: '1Y', category: 'philanthropy' },
  // Donations for the year with budget that works well with taxes

];

const categoryMap = {
  personal: {
    title: "Personal",
    subcategories: {
      health: {
        id: "health",
        title: "Health & Wellness"
      },
      finance: {
        id: "finance",
        title: "Finance"
      },
      /*
      dev: {
        id: "dev",
        title: "Personal Development"
      },
      */
      home: {
        id: "home",
        title: "Home & Property"
      },
      /*
      family: {
        id: "family",
        title: "Family"
      },
      hobbies: {
        id: "hobbies",
        title: "Hobbies"
      },
      sports: {
        id: "sports",
        title: "Sports"
      },
      music: {
        id: "music",
        title: "Music & Arts"
      },
      travel: {
        id: "travel",
        title: "Travel"
      },
      retire: {
        id: "retire",
        title: "Retirement"
      },
      sustain: {
        id: "sustain",
        title: "Sustainability"
      },
      estate: {
        id: "estate",
        title: "Estate"
      },
      */
    }
  },
  professional: {
    title: "Professional",
    subcategories: {
      career: {
        id: "career",
        title: "Career"
      },
      /*
      edu: {
        id: "edu",
        title: "Education"
      },
      */
      profDev: {
        id: "profDev",
        title: "Prof. Development"
      },
      leadership: {
        id: "leadership",
        title: "Leadership"
      },
      /*
      business: {
        id: "business",
        title: "Business"
      },
      philanthropy: {
        id: "philanthropy",
        title: "Philanthropy"
      },
      innovation: {
        id: "innovation",
        title: "Innovation"
      },
      networking: {
        id: "networking",
        title: "Networking"
      },
      techSkills: {
        id: "techSkills",
        title: "Tech Skills"
      }
      */
    }
  }
};

const NewPlanDialog = ({ isOpen, onClose, onCreate, onViewPlans }) => {
  const storageManager = useMemo(() => {
    return new StorageManager();
  }, []);

  const { user } = useFirebaseAuth();
  const { hasProPlan, planUsage } = useSubscription(user?.uid);
  const maxMilestones = hasProPlan ? 72 : 6;

  const { isMobile } = useContext(AppContext);

  // Calculate plan creation limits based on subscription
  const planLimits = {
    blank: hasProPlan ? Infinity : 3,
    template: hasProPlan ? Infinity : 2,
    ai: hasProPlan ? Infinity : 1
  };

  const [step, setStep] = useState(0);

  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedStartDate, setSelectedStartDate] = useState(() => {
    const today = dayjs();
    const firstDayOfNextMonth = today.add(1, 'month').startOf('month');
    return firstDayOfNextMonth;
  });

  // New state variables for duration number and unit
  const [durationNumber, setDurationNumber] = useState(3);
  const [durationUnit, setDurationUnit] = useState('M'); // Default to 'Month'

  // Whenever durationNumber or durationUnit changes, update milestoneDuration
  useEffect(() => {
    setMilestoneDuration(`${durationNumber}${durationUnit}`);
  }, [durationNumber, durationUnit]);

  const [milestoneDuration, setMilestoneDuration] = useState('3M');
  const [numberOfMilestones, setNumberOfMilestones] = useState<number>(3);

  const [showUpgradeMessage, setShowUpgradeMessage] = useState(false);

  const handleMilestoneChange = (e) => {
    const value = Number(e.target.value);
    setNumberOfMilestones(value);

    if (!hasProPlan && value > maxMilestones) {
      setNumberOfMilestones(maxMilestones);
      setShowUpgradeMessage(true);
    }
  };

  const [planTitle, setPlanTitle] = useState('');
  const [planDescription, setPlanDescription] = useState('');

  const [planPrompt, setPlanPrompt] = useState({
    userAspiration: '',
    expertiseLevel: '',
    effortLevel: '',
    planTimeline: ''
  });

  const updatePlanPrompt = (newData) => {
    setPlanPrompt(prevState => ({ ...prevState, ...newData }));
  };

  const [userPrompt, setUserPrompt] = useState('');

  useEffect(() => {
    const prompt = getUserPrompt(planPrompt);

    setUserPrompt(prompt);
  }, [planPrompt]);

  const getUserPrompt = (pp) => {
    return `
        Aspiration: ${pp.userAspiration}.\n
        Expertise Level: ${pp.expertiseLevel || 'not an expert'}.\n
        Effort Level: ${pp.effortLevel || '1 hour a day'}.\n
        Timeline: ${pp.planTimeline || 'flexible'}.\n
    `;
  }

  const selectedTemplateDuration = useMemo(() => {
    const selectedTemplate = templates.find(template => template.id === selectedTemplateId);
    return selectedTemplate ? selectedTemplate.duration : null;
  }, [selectedTemplateId]);

  const isBlankTemplate = selectedTemplateId === 'blank';
  const isAITemplate = selectedTemplateId === 'ai';

  const handleCreatePlan = async () => {
    logEvent(getFirebaseAnalytics(), 'create_plan_attempt', {
      template_id: selectedTemplateId,
      plan_title: planTitle,
      is_ai: isAITemplate,
      is_blank: isBlankTemplate,
      is_template: !isBlankTemplate && !isAITemplate,
    });

    setIsLoading(true);

    const startDate = selectedStartDate.toDate();

    try {
      let planTemplate: PlanDefinition;

      if (isBlankTemplate) {
        // Create a new blank plan locally
        planTemplate = createBlankPlan(planTitle, new Date(startDate), milestoneDuration, numberOfMilestones);
        planTemplate.icons = generateRandomIcons(planTemplate.events);
      } else if (isAITemplate) {
        // Create a new AI plan locally
        planTemplate = createBlankPlan(planTitle || 'AI Plan', new Date(startDate), milestoneDuration, numberOfMilestones);
        planTemplate.icons = generateRandomIcons(planTemplate.events);
      } else {
        planTemplate = await storageManager.get('templates', selectedTemplateId) as PlanDefinition;
      }

      if (planTitle) {
        planTemplate.title = planTitle;
      }

      if (planDescription) {
        planTemplate.description = planDescription;
      }

      if (selectedStartDate) {
        // Adjust milestones based on the selected start date
        planTemplate.events = adjustMilestones(planTemplate.events, new Date(startDate));
      }

      planTemplate.init = {
        mode: isBlankTemplate ? 'blank' : isAITemplate ? 'ai-todo' : 'template',
        userPrompt: isAITemplate ? userPrompt : '',
        startDate: startDate.toISOString(),
        milestoneDuration,
        numberOfMilestones,
      } as PlanCreationInfo;

      await onCreate(planTemplate);

      // Reset after plan creation
      setStep(0);
      setPlanTitle('');
      setPlanDescription('');
    } catch (error) {
      logger.error("Error creating plan:", error);
      toast.error("Error creating plan. Please try again later.");
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  const getSteps = useCallback(() => {
    if (isBlankTemplate) {
        return ['Configure Plan Settings'];
    } else if (isAITemplate) {
        return ['Tell AI What You Want', 'Configure Plan Settings'];
    } else if (selectedTemplateId !== null) {
        return ['Select a Template', 'Configure Plan Settings'];
    } else {
        return [];
    }
}, [isBlankTemplate, isAITemplate, selectedTemplateId]);

  const [steps, setSteps] = useState(getSteps());

  useEffect(() => {
    setSteps(getSteps());
  }, [selectedTemplateId, getSteps]);

  // Content for each step
  const getContentForStep = () => {
    if (isAIWorking) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <CircularProgress size={68} />
          <Typography variant="h6" style={{ marginTop: 20 }}>
            Generating plan settings...
          </Typography>
          <Typography variant="subtitle1" style={{ marginTop: 10 }}>
            AI is helping out by pre-populating your plan settings, please wait.
          </Typography>
          {/* Optionally, add an AI-themed animation or image here */}
        </div>
      );
    }

    if (selectedTemplateId === null) {
      return stepOnePlanType;
    }

    switch (selectedTemplateId) {
      case null:
        return stepOnePlanType;
      case 'blank':
        return (step === 0 ? contentForPlanConfig : null);
      case 'ai':
        return (step === 0 ? contentForAIInput : contentForPlanConfig);
      default:
        return (step === 0 ? contentForTemplateSelection : contentForPlanConfig);
    }
  };

  const getPlanDurationText = () => {
    return (selectedTemplateId === 'ai' || selectedTemplateId == 'blank')
      ? getTotalDurationText(numberOfMilestones, milestoneDuration)
      : getDurationText(selectedTemplateDuration);
  }

  // Move to the next step
  const handleNext = () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
    }
  };

  // Go back to the previous step
  const handleBack = () => {
    if (step === 0) {
      setSelectedTemplateId(null);
    }

    if (step > 0) {
      setStep(step - 1);
    }
  };

  const cardStyles = {
    width: '200px',
    m: 'auto', // Centers the card
    '&:hover': {
      boxShadow: '0px 6px 15px rgba(0,0,0,0.2)', // Shadow effect on hover
      transform: 'scale(1.05)', // Slightly scale up the card on hover
      transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
      backgroundColor: '#f5f5f5', // Change background color on hover
    },
  };

  const cardActionStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px',
  };

  const iconStyles = {
    fontSize: '4rem',
    opacity: 0.5, // Adjust for transparency
  };

  const renderCardUsageText = (type, label) => {
    const isLimitReached = planUsage[type] >= planLimits[type];
    const textColorClass = isLimitReached ? 'text-red-400' : 'text-gray-500';

    const planCount = planUsage[type];
    const usageText = hasProPlan
      ? `${planCount} ${label} created so far.`
      : `${planCount} out of ${planLimits[type]} ${label} used.`;

    return (
      <Typography variant="body2" className={`mt-2 ${textColorClass}`}>
        {usageText}
      </Typography>
    );
  };

  const PlanCard = ({ type, icon, iconColor, title, description, isUpgradeNeeded }) => {
    const upgradeTitle = 'Upgrade To Pro';
    const typeDescription = type === 'ai' ? 'AI' : type === 'template' ? 'Template' : 'Blank';
    const upgradeDescription = `Upgrade to Pro for unlimited ${typeDescription} plans!`;

    const displayTitle = isUpgradeNeeded ? upgradeTitle : title;
    const displayDescription = isUpgradeNeeded ? upgradeDescription : description;

    const IconComponent = isUpgradeNeeded ? LocalFloristOutlinedIcon : icon;
    const cardColor = isUpgradeNeeded ? 'bg-gray-200' : ``;

    return (
      <Grid item>
        <Tooltip title={isUpgradeNeeded ? "Click to upgrade to Pro for unlimited plans!" : ''}>
          <Card sx={{ ...cardStyles }} className={cardColor} onClick={() => handleCardClick(type)}>
            <CardActionArea sx={cardActionStyles}>
              <IconComponent style={{ ...iconStyles, color: iconColor }} />
              <Typography variant="subtitle1" style={{ marginTop: '8px' }}>
                {displayTitle}
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 3, mt: 1, textAlign: 'center' }}>
                {displayDescription}
              </Typography>
            </CardActionArea>
          </Card>
        </Tooltip>
        {renderCardUsageText(type, `${typeDescription} plans`)}
      </Grid>
    );
  };

  const stepOnePlanType = (
    <Grid container spacing={4} justifyContent="center" alignItems="center" style={{ padding: '24px' }}>
      <PlanCard
        type='ai'
        icon={AutoAwesomeOutlinedIcon}
        iconColor={'green'}
        title='Create with AI'
        description='Let AI help you create a plan.'
        isUpgradeNeeded={planUsage.ai >= planLimits.ai}
      />
      <PlanCard
        type='template'
        iconColor={'brown'}
        icon={ListAltOutlinedIcon}
        title='Use a Template'
        description='Choose from a range of templates.'
        isUpgradeNeeded={planUsage.template >= planLimits.template}
      />
      <PlanCard
        type='blank'
        icon={AddBoxOutlinedIcon}
        iconColor={'blue'}
        title='Start from Scratch'
        description='Jump right in and plan just what you have in mind.'
        isUpgradeNeeded={planUsage.blank >= planLimits.blank}
      />
    </Grid>
  );

  const [selectedCategory, setSelectedCategory] = useState('health'); // Default to 'health'
  const [filteredTemplates, setFilteredTemplates] = useState([]);

  // Effect to filter templates based on selected category
  useEffect(() => {
    const filtered = templates.filter(template => template.category === selectedCategory);
    setFilteredTemplates(filtered);
  }, [selectedCategory]);

  const sidePaneStyles = {
    maxHeight: 'calc(75vh - 100px)',
    backgroundColor: '#f7f7f7',
    overflow: 'auto',
  };

  const templatesGridStyles = {
    marginTop: '2rem',
    maxHeight: 'calc(75vh - 100px)',
    overflow: 'auto', // Enable vertical scrolling
  };

  const categorySidePane = (
    <React.Fragment>
      <Typography variant="overline" sx={{ fontWeight: 'bold' }}>Personal</Typography>
      <List style={{ width: '90%' }} sx={{ mb: 2 }}>
        {Object.entries(categoryMap.personal.subcategories).map(([key, value]) => (
          <React.Fragment key={key}>
            <ListItemButton selected={selectedCategory === key} onClick={() => setSelectedCategory(key)}>
              <ListItemText primary={value.title} />
            </ListItemButton>
            <Divider />
          </React.Fragment>
        ))}
      </List>

      {/* Commented out for now, will be added back later */}
      {/*
      <Typography variant="overline" sx={{ fontWeight: 'bold' }}>Professional</Typography>
      <List style={{ width: '90%' }}>
        {Object.entries(categoryMap.professional.subcategories).map(([key, value]) => (
          <React.Fragment key={key}>
            <ListItemButton selected={selectedCategory === key} onClick={() => setSelectedCategory(key)}>
              <ListItemText primary={value.title} />
            </ListItemButton>
            <Divider />
          </React.Fragment>
        ))}
      </List>
      */}
    </React.Fragment>
  );

  const contentForTemplateSelection = (
    <Grid container spacing={isMobile ? 0 : 1} direction={isMobile ? 'column' : 'row'}>
      <Grid item xs={2} style={sidePaneStyles}>
        <Typography variant="h6" gutterBottom>
          Categories
        </Typography>
        {categorySidePane}
      </Grid>
      <Grid item xs={10} style={templatesGridStyles}>
        <Typography variant="h6" gutterBottom>
          Plan Templates
        </Typography>
        <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-start">
          {filteredTemplates.map((template) => (
            <Grid item xs={12} sm={4} md={3} key={template.id}>
              <Card
                raised={selectedTemplateId === template.id}
                onClick={async () => {
                  setSelectedTemplateId(template.id);

                  if (process.env.NODE_ENV !== 'development') {
                    // In non-development, skip the check for template existence
                    handleNext();
                    return;
                  }

                  // Check if the template exists in Firestore
                  const templateDefinition = await storageManager.get('templates', template.id);

                  if (templateDefinition) {
                    // Template exists, proceed normally
                    handleNext();
                  } else {
                    // Template not found, switch to AI mode
                    setSelectedTemplateId('ai'); // Set as if AI card was clicked

                    updatePlanPrompt({
                      userAspiration: `Create a ${template.category} plan titled '${template.title}' with the goal: ${template.description}.`
                    });

                    setStep(1); // Assuming second step is for AI input, adjust accordingly
                  }
                }}
                style={selectedTemplateId === template.id ? { backgroundColor: "#e0e0e0" } : {}}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image={`/templates/${template.image}.png`}
                    alt={template.title}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {template.title}
                    </Typography>
                    <Tooltip title={template.description} arrow placement="bottom">
                      <Typography variant="body2" color="textSecondary">
                        {template.description}
                      </Typography>
                    </Tooltip>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );

  const formStyles = {
    padding: '20px', // Adjust padding to your preference
    margin: 'auto', // Center the form on the page
    border: '1px solid #e0e0e0', // Border color
    borderRadius: '4px', // Rounded corners
    backgroundColor: '#fafafa', // Light gray background
    '& .MuiTextField-root': {
      margin: '10px 0', // Spacing between form elements
    },
  };

  const contentForPlanConfig = (
    <Paper sx={formStyles}>
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ height: '100%' }}>
        <Grid item>
          <Typography variant="body1" color="textSecondary" sx={{ mb: 3 }}>
            Your plan currently starts on <strong>{selectedStartDate.format('MMMM D, YYYY')}</strong>, with {(isBlankTemplate || isAITemplate) ? (
              <React.Fragment>
                <strong>{numberOfMilestones} milestones</strong> and
              </React.Fragment>
            ) : ""} a total duration of <strong>{getPlanDurationText()}</strong>.
          </Typography>
        </Grid>

        <Grid item>
          <Grid container spacing={2} alignItems="center" justifyContent="center" style={{ height: '100%' }}>
            {(isBlankTemplate || isAITemplate) && (
              <Grid item xs={12}>
                <Grid container direction="column" spacing={0}>
                  <Typography variant="caption" display="block" gutterBottom>
                    Set a title for your plan
                  </Typography>
                  <TextField
                    label="Plan Title"
                    value={planTitle}
                    onChange={(e) => setPlanTitle(e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
              </Grid>
            )}

            <Grid item xs={12}>
              <Grid container alignItems={isMobile ? "flex-start" : "center"} justifyContent="center" direction={isMobile ? "column" : "row"} spacing={isMobile ? 2 : 5}>

                {(isBlankTemplate || isAITemplate) && (
                  <Grid item>
                    <Grid container justifyContent="center" direction="column" spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Typography variant="caption" display="block" gutterBottom>
                          How many milestones (parts) do you want to have in your plan?
                          <Tooltip
                            title={
                              <React.Fragment>
                                A milestone in project management is a significant event or stage in a project.
                                It marks the completion of major phases or objectives and helps in tracking progress.
                                Examples include finishing a key project phase or reaching an important deadline.
                              </React.Fragment>
                            }
                            placement="right"
                          >
                            <InfoIcon style={{ fontSize: '16px', marginLeft: '4px' }} />
                          </Tooltip>
                        </Typography>
                        <TextField
                          label="Number of Milestones"
                          type="number"
                          value={numberOfMilestones}
                          inputProps={{ min: "1", max: "12" }}
                          onChange={handleMilestoneChange}
                          helperText={showUpgradeMessage ? "Upgrade to a Pro plan to add more than 6 milestones" : ""}
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="caption" display="block" gutterBottom>
                          What should the duration of <strong>each milestone</strong> be?
                        </Typography>
                        <Grid container direction="row" spacing={1}>
                          <Grid item xs={6}>
                            <TextField
                              label="Duration"
                              type="number"
                              value={durationNumber}
                              onChange={(e) => setDurationNumber(Number(e.target.value))}
                              margin="normal"
                              fullWidth
                              inputProps={{ min: "1", max: "100" }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              select
                              label="In (Unit)"
                              value={durationUnit}
                              onChange={(e) => setDurationUnit(e.target.value)}
                              margin="normal"
                              fullWidth
                            >
                              <MenuItem value="D">Days</MenuItem>
                              <MenuItem value="W">Weeks</MenuItem>
                              <MenuItem value="M">Months</MenuItem>
                              <MenuItem value="Y">Years</MenuItem>
                            </TextField>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid item>
                  <Typography variant="caption" display="block" gutterBottom>
                    Change the start date of your plan
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {isMobile ? (
                      <MobileDatePicker
                        label="Plan Start Date"
                        value={selectedStartDate}
                        onChange={setSelectedStartDate}
                        slotProps={{ textField: { fullWidth: true } }}
                      />) : (
                      <DateCalendar
                        value={selectedStartDate}
                        onChange={setSelectedStartDate}
                      />
                    )}
                  </LocalizationProvider>
                </Grid>


              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>

  );

  const generatePlanSettings = async (uPrompt) => {
    try {
      let systemPrompt = `
    Generate a plan's settings in JSON format based on user-provided fields: Aspiration, Expertise Level, Effort Level, and Timeline. The primary input, Aspiration, will guide the creation of the plan, including its title and description.

    The JSON response should be as follows:
    {
        "title": "Generated Plan Title",
        "description": "Generated Plan Description",
        "milestones": Number of Milestones,
        "milestoneDurationNumber": Number Part of Duration,
        "milestoneDurationUnit": "Unit Part of Duration (D, W, M, Y)"
    }

    Instructions:
    - title: For the title, don't use filling words like plan, achievement or aspiration. It should clealy represent what the user mentions in the Aspiration field.
    - description: Be crisp and concise. No need to call out the milestones and what is happening in each one. Just a high-level overview of the plan.
    - milestones: The number of milestones, up to a limit of 6.
    - milestoneDuration: The milestone duration is represented as follows: '1D' for 1 day, '2W' for 2 weeks, '3M' for 3 months, '4Y' for 4 years. The milestones and duration should be realistic and achievable based on the provided user fields. If Timeline is provided, the milestones and duration should be as close to it as possible. Ex: if it is "3 months", then milestone duration * number of milestones should be around 3 months. Ex: If timeline is "October", identify (duration * milestones) that can be completed by October.

    Remember, the format is crucial - your response must be in the very specific JSON format I provided as an example. And the title should be generated based on the user mentions in the Aspiration field.
    `;

      logEvent(getFirebaseAnalytics(), 'start_ai_title');

      logger.debug("Generating plan settings with user prompt:", uPrompt);

      const response = await fetch('https://us-central1-time-quest-18054.cloudfunctions.net/generateJson', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
          {
            userPrompt: uPrompt,
            systemPrompt,
          }),
      });

      const data = await response.json();

      logger.debug("Generated plan settings:", data);

      if (data && data.result && data.result.content) {
        let content = JSON.parse(data.result.content);

        let newTitle = content.title;
        logger.debug("Generated title:", newTitle);

        let newDescription = content.description;
        logger.debug("Generated description:", newDescription);

        let newMilestones = content.milestones;
        logger.debug("Generated Number of milestones:", newMilestones);

        let newMilestoneDurationNumber = content.milestoneDurationNumber;
        logger.debug("Generated milestone duration number:", newMilestoneDurationNumber);

        let newMilestoneDurationUnit = content.milestoneDurationUnit;
        logger.debug("Generated milestone duration unit:", newMilestoneDurationUnit);

        logEvent(getFirebaseAnalytics(), 'ai_title_success');

        return {
          title: newTitle,
          description: newDescription,
          milestones: newMilestones,
          milestoneDurationNumber: newMilestoneDurationNumber,
          milestoneDurationUnit: newMilestoneDurationUnit
        };
      } else {
        throw "Data for generating title and description is coming back empty";
      }
    } catch (error) {
      logger.error("Error generating plan title and description:", error);

      logEvent(getFirebaseAnalytics(), 'ai_title_error');

      throw "Error generating plan title and description";
    }
  }

  const [isAIWorking, setIsAIWorking] = useState(false);

  // Content for Step 2 when "Create with AI" is selected
  const contentForAIInput = (
    <PlanPromptForm
      initialPrompt={planPrompt}
      onContinue={async (pp) => {
        setIsAIWorking(true);

        updatePlanPrompt(pp);
        const uPrompt = getUserPrompt(pp);

        try {
          let result = await generatePlanSettings(uPrompt);

          setPlanTitle(result.title);
          setPlanDescription(result.description);
          setNumberOfMilestones(result.milestones);
          setDurationNumber(result.milestoneDurationNumber);
          setDurationUnit(result.milestoneDurationUnit);

          toast.success("Plan settings generated successfully!");

          handleNext();
        } catch (error) {
          logger.error('Error generating plan settings:', error);

          toast.error("Error generating plan settings. Please try again later.")
        } finally {
          setIsAIWorking(false);
        }
      }
      } />
  );

  const handleCardClick = (option) => {
    // Log event when user selects an option
    logEvent(getFirebaseAnalytics(), 'plan_creation_option_selected', {
      option: option
    });

    if (planUsage[option] >= planLimits[option]) {
      onViewPlans();
      logger.warn('Plan creation limit reached for option:', option);
      return;
    }

    setSelectedTemplateId(option);
    handleNext();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        style: {
          width: '75vw',
          height: '75vh',
          maxWidth: 'none', // This makes sure maxWidth does not override width
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }
      }}
    >
      <DialogTitle>
        {/* Back Button */}
        {selectedTemplateId !== null && (
          <IconButton onClick={handleBack}
            sx={{
              position: 'absolute', // Position absolutely to the left
              left: 10,
              top: 10
            }}>
            <ArrowBackIcon />
          </IconButton>
        )}

        <Typography variant="h6">
          {steps[step]}
        </Typography>

        <IconButton onClick={onClose}
          sx={{
            position: 'absolute', // Position absolutely to the right
            right: 10,
            top: 10
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {(!isMobile && steps.length > 1) && (
        <Stepper activeStep={step} orientation={isMobile ? 'vertical' : 'horizontal'}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}

      <DialogContent style={{ width: '100%', height: '100%' }}>
        <Grid container direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100%' }}>
          {getContentForStep()}
        </Grid>
      </DialogContent>

      <DialogActions>
        {step === steps.length - 1 && (
          <Button
            variant="outlined"
            color="primary"
            size="large" // Makes the button larger
            onClick={handleCreatePlan}
            disabled={(isBlankTemplate && !planTitle.trim()) || isLoading}
            sx={{
              width: 'auto', // Adjust the width as needed
              padding: '10px 20px', // More padding for a larger display
              margin: '20px 0', // Add more margin on the top and bottom
              fontSize: '1rem', // Adjust font size as necessary
              fontWeight: 'bold', // Bold font for better visibility
            }}
          >
            {isLoading ? <CircularProgress size={24} color="inherit" /> : isAITemplate ? "Create with AI" : "Create Plan"}
          </Button>
        )}
      </DialogActions>

    </Dialog>
  );
};

export default NewPlanDialog;
