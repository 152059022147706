import React, { useState, useEffect } from 'react';
import { Box, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material';
import { PredefinedTimeRange, TimeRange, CustomTimeRange } from '@/lib/interfaces';

interface TimeRangeSelectorProps {
    onSelect: (range: TimeRange) => void;
    selectedRange: TimeRange;
}

const predefinedRanges = Object.values(PredefinedTimeRange);

const TimeRangeSelector: React.FC<TimeRangeSelectorProps> = ({ onSelect, selectedRange }) => {
    const [currentRange, setCurrentRange] = useState<TimeRange>(selectedRange);
    const theme = useTheme();

    useEffect(() => {
        setCurrentRange(selectedRange);
    }, [selectedRange]);

    const handleChange = (event: React.MouseEvent<HTMLElement>, newRange: PredefinedTimeRange | 'Custom') => {
        if (newRange !== null) {
            if (newRange === 'Custom') {
                const customRange = { type: 'Custom', startDate: '', endDate: '' } as CustomTimeRange;
                setCurrentRange(customRange);
                onSelect(customRange);
            } else {
                setCurrentRange(newRange);
                onSelect(newRange);
            }
        }
    };

    return (
        <Box className='screenshot-hide' display="flex" justifyContent="center" alignItems="center" mt={2}>
            <ToggleButtonGroup
                value={typeof selectedRange === 'string' ? selectedRange : 'Custom'}
                exclusive
                onChange={handleChange}
                aria-label="time range"
                sx={{
                    flexWrap: 'wrap',
                    gap: '8px',
                    '& .MuiToggleButton-root': {
                        fontSize: '0.75rem',
                        padding: '6px 12px',
                        borderRadius: '16px',
                        border: 'none',
                        color: theme.palette.text.primary,
                        backgroundColor: 'lightgray',
                        '&.Mui-selected': {
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.dark,
                            },
                        },
                        '&:hover': {
                            backgroundColor: 'gray',
                        },
                        '@media (max-width: 600px)': {
                            fontSize: '0.65rem',
                            padding: '4px 8px',
                        },
                    },
                }}
            >
                {predefinedRanges.map(range => (
                    <ToggleButton key={range} value={range} aria-label={range}>
                        {range}
                    </ToggleButton>
                ))}
                <ToggleButton value="Custom" aria-label="Custom">
                    Custom
                </ToggleButton>
            </ToggleButtonGroup>
        </Box>
    );
};

export default TimeRangeSelector;