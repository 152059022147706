import React, { useState, useContext } from 'react';
import { Grid, TextField, Button } from '@mui/material';
import { AppContext } from "@/app/providers";

const PlanPromptForm = ({ initialPrompt, onContinue }) => {
  const [userAspiration, setUserAspiration] = useState(initialPrompt?.userAspiration || '');
  const minChar = 32;
  const maxChar = 1024;
  const isCharCountValid = userAspiration.length >= minChar && userAspiration.length <= maxChar;

  // Add new state variables for the expert level, effort, and timeline questions
  const [expertiseLevel, setExpertiseLevel] = useState(initialPrompt?.expertiseLevel || '');
  const [effortLevel, setEffortLevel] = useState(initialPrompt?.effortLevel || '');
  const [planTimeline, setPlanTimeline] = useState(initialPrompt?.planTimeline || '');

  const { isMobile } = useContext(AppContext);

  return (
    <Grid container className="w-full flex flex-col items-center justify-center p-3">
      <Grid item xs={12} className='pb-3'>
        <TextField
          label="What would you like to achieve?"
          multiline
          rows={isMobile ? 3 : 6}
          placeholder="E.g., A step-by-step plan for writing and publishing my first novel by next summer."
          fullWidth
          margin="normal"
          value={userAspiration}
          onChange={(event) => setUserAspiration(event.target.value)}
          error={!isCharCountValid && userAspiration.length > 0}
          helperText={
            userAspiration.length < minChar
              ? `Minimum ${minChar} characters required.`
              : userAspiration.length > maxChar
                ? `Maximum ${maxChar} characters exceeded.`
                : ' '
          }
        />

        <Grid item xs={12} className="pb-3">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="What is your level of expertise?"
                placeholder="If left empty, we will assume you have little expertise to achieve this goal."
                fullWidth
                value={expertiseLevel}
                onChange={(e) => setExpertiseLevel(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="How much effort do you plan to put in on this goal?"
                placeholder="If left empty, we will assume you will put 1 hour a day to achieve this plan."
                fullWidth
                value={effortLevel}
                onChange={(e) => setEffortLevel(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Do you have a timeline in mind?"
                placeholder="If left empty, we will determine the time based on the level of effort you're willing to put."
                fullWidth
                value={planTimeline}
                onChange={(e) => setPlanTimeline(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => onContinue({ userAspiration, expertiseLevel, effortLevel, planTimeline })}
          disabled={!isCharCountValid}
        >
          Continue
        </Button>
      </Grid>
    </Grid>
  );
};

export default PlanPromptForm;
