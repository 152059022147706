import React from 'react';
import { useDragLayer } from 'react-dnd';
import type { XYCoord } from 'react-dnd';
import type { CSSProperties } from 'react';
import { DragItemTypes, DragMilestoneItem, DragTimelineMilestoneItem } from '@/lib/interfaces';
import IconComponent from './IconComponent';
import { Typography } from '@mui/material';
import logger from '@/lib/logger';

const layerStyles: CSSProperties = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
};

function getItemStyles(initialOffset: XYCoord | null, currentOffset: XYCoord | null) {
    if (!initialOffset || !currentOffset) {
        return {
            display: 'none',
        };
    }

    const { x, y } = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;
    return {
        transform,
        WebkitTransform: transform,
    };
}


const renderTimelineMilestone = (item: DragTimelineMilestoneItem) => {
    const { objectiveText, isIntimeline: isInTimeline, iconSelections, milestone } = item;

    logger.debug('Rendering timeline milestone:', item);

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                padding: '5px',
                backgroundColor: '#f5f5f5',
                borderRadius: '5px',
                maxWidth: '200px',
                overflow: 'hidden',
                border: '1px solid #ddd',
                boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
            }}
        >
            {isInTimeline && milestone.plan ? (
                <IconComponent
                    size="small"
                    type={milestone.plan.type ?? 'plan'}
                    iconSelection={milestone.plan.icon}
                />
            ) : (
                iconSelections && iconSelections[milestone.id] && (
                    <IconComponent
                        size="small"
                        type="milestone"
                        iconSelection={iconSelections[milestone.id]}
                    />
                )
            )}

            <Typography
                variant="body2"
                component="div"
                sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                    marginLeft: '8px',
                    color: 'black',
                }}
            >
                {objectiveText}
            </Typography>
        </div>
    );
};

const renderMilestone = (item: DragMilestoneItem) => {
    const { objectiveText, iconSelections, id, index, width } = item;

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                padding: '5px',
                backgroundColor: '#f5f5f5',
                borderRadius: '5px',
                width: width || '100%',
                overflow: 'hidden',
                border: '1px solid #ddd',
                boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
            }}
        >
            {iconSelections && iconSelections[id] && (
                <IconComponent
                    size="small"
                    type="milestone"
                    iconSelection={iconSelections[id]}
                />
            )}
            <Typography
                variant="body2"
                component="div"
                style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                    marginLeft: '8px',
                    color: 'black',
                }}
            >
                {objectiveText || 'Milestone ' + (index + 1)}
            </Typography>
        </div>
    );
};

const ItemDragLayer = () => {
    const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }));

    function renderItem() {
        switch (itemType) {
            case DragItemTypes.TIMELINE_MILESTONE:
                return renderTimelineMilestone(item);
            case DragItemTypes.MILESTONE:
                return renderMilestone(item);
            default:
                return null;
        }
    }

    if (!isDragging) {
        return null;
    }

    return (
        <div style={layerStyles}>
            <div style={getItemStyles(initialOffset, currentOffset)}>
                {renderItem()}
            </div>
        </div>
    );
};

export default ItemDragLayer;
