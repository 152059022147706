import React, { useState } from 'react';
import { useRouter } from 'next/navigation';

import { Avatar, Menu, MenuItem, Typography, Divider, ListItemIcon } from '@mui/material';

import LogoutIcon from '@mui/icons-material/Logout'; // Import icons as needed
import SettingsIcon from '@mui/icons-material/Settings';
import ViewListIcon from '@mui/icons-material/ViewList';
import CampaignIcon from '@mui/icons-material/Campaign';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import MonitorOutlinedIcon from '@mui/icons-material/MonitorOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import PaletteIcon from '@mui/icons-material/Palette';

import logo from '@/public/logo.png';

import logger from "@/lib/logger";

import useSubscription from '@/lib/hooks/useSubscription';

import { useFirebaseAuth, getFirebaseAnalytics } from '@/lib/hooks/firebase';
import { getAuth, signOut } from 'firebase/auth';
import { logEvent } from 'firebase/analytics';
import { toast } from 'sonner';
import FeedbackDialog from './FeedbackDialog';
import { sendEmailMessageForManagePlan } from './EmailMessage';
import FeedbackNote from './FeedbackNote';
import { useTheme } from "next-themes";

const StyledMenuItem = (props) => (
  <MenuItem {...props} sx={{ '&:hover': { bgcolor: 'primary.light' } }}>
    {props.children}
  </MenuItem>
);

const placeholderAvatar = logo.src;

const appearances = [
  { theme: "System", icon: <MonitorOutlinedIcon /> },
  { theme: "Light", icon: <LightModeOutlinedIcon /> },
  { theme: "Dark", icon: <DarkModeOutlinedIcon /> },
];

const AuthControls = ({ onLogout, onViewPlans }) => {
  const { theme: currentTheme, setTheme } = useTheme();
  const { user } = useFirebaseAuth();
  const { hasProPlan } = useSubscription(user?.uid);
  const router = useRouter();

  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const [appearanceAnchorEl, setAppearanceAnchorEl] = useState(null);
  const isAppearanceMenuOpen = Boolean(appearanceAnchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setAppearanceAnchorEl(null);
  };

  const handleOpenAppearanceMenu = (event) => {
    setAppearanceAnchorEl(event.currentTarget);
  };

  const handleCloseAppearanceMenu = () => {
    setAppearanceAnchorEl(null);
    setAnchorEl(null);
  };

  const changeTheme = (newTheme) => {
    setTheme(newTheme);
    handleCloseAppearanceMenu();
    logEvent(getFirebaseAnalytics(), 'theme_change', { new_theme: newTheme });
  };

  const handleLogout = async () => {
    const auth = getAuth();
    try {

      // Log event for logout attempt
      logEvent(getFirebaseAnalytics(), 'logout_attempt');

      await signOut(auth);

      onLogout();

      // Log event for successful logout
      logEvent(getFirebaseAnalytics(), 'logout_success');

      // Optionally redirect to home or login page after logout
      router.push('/'); // Redirect to home after logout
    } catch (error) {
      logger.error('Logout failed:', error);
      // Handle logout error (e.g., show a notification to the user)

      toast.error('Logout failed. Please try again.');

      // Log event for logout failure
      logEvent(getFirebaseAnalytics(), 'logout_failure', {
        error_message: error.message
      });
    }
  };

  const [isFeedbackNoteOpen, setIsFeedbackNoteOpen] = useState(false);

  const sendFeedbackNote = () => {
    logEvent(getFirebaseAnalytics(), 'feedback_note_attempt');
    handleCloseMenu();

    setIsFeedbackNoteOpen(true);
  }

  const handleFeedbackNoteClose = () => {
    logEvent(getFirebaseAnalytics(), 'feedback_note_cancel');

    setIsFeedbackNoteOpen(false);
  };

  const handleFeedbackNoteSubmit = () => {
    logEvent(getFirebaseAnalytics(), 'feedback_note_submit');

    setIsFeedbackNoteOpen(false);
  };

  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);

  const sendFeedback = () => {
    logEvent(getFirebaseAnalytics(), 'feedback_attempt');
    handleCloseMenu();

    setIsFeedbackDialogOpen(true);
  }

  const handleFeedbackDialogClose = () => {
    logEvent(getFirebaseAnalytics(), 'feedback_cancel');

    setIsFeedbackDialogOpen(false);
  };

  const handleFeedbackSubmitAttempt = () => {
    logEvent(getFirebaseAnalytics(), 'feedback_submit');
  };

  const handleFeedbackSubmitSuccess = () => {
    logEvent(getFirebaseAnalytics(), 'feedback_success');
    setIsFeedbackDialogOpen(false);
  };

  const handleViewPlans = () => {
    handleCloseMenu();

    onViewPlans();
  };

  const handleManagePlan = () => {
    handleCloseMenu();

    // Get the base URL from the environment variable
    const portalBaseUrl = process.env.NEXT_PUBLIC_STRIPE_CUSTOMER_PORTAL_BASE_URL;

    if (!portalBaseUrl) {
      logger.error("Stripe Customer Portal URL is not set.");

      toast.error('Error fetching Stripe Customer Portal URL.');

      // Handle the error appropriately
      return;
    }

    // Construct the URL
    const portalUrl = user?.email
      ? `${portalBaseUrl}?prefilled_email=${encodeURIComponent(user.email)}`
      : portalBaseUrl;

    // Open the URL in a new window/tab
    window.open(portalUrl, '_blank');

    sendEmailMessageForManagePlan(user);
  };

  if (user) {
    return (
      <>
        <div className="absolute top-0 right-0 m-4">
          <Avatar
            onClick={handleOpenMenu}
            src={user.photoURL || placeholderAvatar}
            style={{ cursor: 'pointer' }}
            sx={{ width: 36, height: 36 }}
          />
          <Menu
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={handleCloseMenu}
            PaperProps={{
              sx: {
                minWidth: 250,
                padding: '8px 0',
                bgcolor: 'background.paper',
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)'
              }
            }}
          >
            <Typography variant="h6" sx={{ margin: '0px 16px' }}>
              {user.displayName}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', margin: '0 16px' }}>
              {user.email}
            </Typography>

            {process.env.NODE_ENV === 'development' && (
              <Typography variant="body2" sx={{ color: 'text.secondary', margin: '0 16px' }}>
                User ID: {user.uid}
              </Typography>
            )}

            <Divider sx={{ my: 1 }} />

            {hasProPlan ? (
              <StyledMenuItem onClick={handleManagePlan}>
                <ListItemIcon>
                  <SettingsIcon fontSize="small" />
                </ListItemIcon>
                Manage Plan
              </StyledMenuItem>
            ) : (
              <StyledMenuItem onClick={handleViewPlans}>
                <ListItemIcon>
                  <ViewListIcon fontSize="small" />
                </ListItemIcon>
                View Payment Plans
              </StyledMenuItem>
            )}

            <Divider sx={{ my: 1 }} />

            <StyledMenuItem onClick={handleOpenAppearanceMenu}>
              <ListItemIcon>
                <PaletteIcon />
              </ListItemIcon>
              Appearance
            </StyledMenuItem>

            <Menu
              anchorEl={appearanceAnchorEl}
              open={isAppearanceMenuOpen}
              onClose={handleCloseAppearanceMenu}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              {appearances.map(({ theme, icon }) => (
                <StyledMenuItem key={theme} onClick={() => changeTheme(theme.toLowerCase())}>
                  <ListItemIcon>
                    {icon}
                  </ListItemIcon>
                  {theme}
                  {currentTheme === theme.toLowerCase() && (
                    <CheckOutlinedIcon style={{ marginLeft: 'auto', paddingLeft: '10px' }} />
                  )}
                </StyledMenuItem>
              ))}
            </Menu>

            <StyledMenuItem onClick={sendFeedback}>
              <ListItemIcon>
                <CampaignIcon fontSize="small" />
              </ListItemIcon>
              Evaluate The App
            </StyledMenuItem>


            <StyledMenuItem onClick={sendFeedbackNote}>
              <ListItemIcon>
                <MailOutlineOutlinedIcon fontSize="small" />
              </ListItemIcon>
              Send Feedback Note
            </StyledMenuItem>

            {/* ... other menu items 
          <StyledMenuItem>
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            Your Settings
          </StyledMenuItem>*/}

            {/* ... other menu items */}

            <Divider sx={{ my: 1 }} />
            <StyledMenuItem onClick={handleLogout}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              Log out
            </StyledMenuItem>
          </Menu>
        </div>

        <FeedbackDialog
          isOpen={isFeedbackDialogOpen}
          onCancel={handleFeedbackDialogClose}
          onSubmitAttempt={handleFeedbackSubmitAttempt}
          onSubmitSuccess={handleFeedbackSubmitSuccess}
        />

        <FeedbackNote
          isOpen={isFeedbackNoteOpen}
          onCancel={handleFeedbackNoteClose}
          onSubmit={handleFeedbackNoteSubmit}
        />
      </>
    );
  }

  return <div></div>;
}

export default AuthControls;
