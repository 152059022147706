// FeedbackNote.tsx
import React, { useState } from 'react';
import { Button, TextField, CircularProgress, Dialog, DialogContent, DialogActions } from '@mui/material';
import { sendEmailMessage, EmailMessageType } from './EmailMessage';
import { useFirebaseAuth } from '@/lib/hooks/firebase';
import { toast } from 'sonner';

import logger from "@/lib/logger";

interface FeedbackDialogProps {
    isOpen: boolean;
    onSubmit: (feedbackNote: string) => void;
    onCancel: () => void;
}

const FeedbackNote: React.FC<FeedbackDialogProps> = ({ isOpen, onSubmit, onCancel }) => {
    const { user } = useFirebaseAuth();
    const [feedbackNote, setFeedbackNote] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSubmitting(true);

        let userData = {
            id: user?.uid,
            email: user?.email,
        };

        const emailContentHtml = `
        <html>
            <body>
                <h1>Feedback Note</h1>
                <p><strong>User ID:</strong> ${user?.uid}</p>
                <p><strong>User Email:</strong> ${user?.email}</p>
                <p><strong>User Name:</strong> ${user.displayName}</p>
                <p><strong>Feedback:</strong></p>
                <blockquote>${feedbackNote}</blockquote>
            </body>
        </html>
    `;

        try {
            await sendEmailMessage({
                subject: 'Feedback Note from: ' + (user?.displayName || 'unknown'),
                type: EmailMessageType.FeedbackNote,
                html: emailContentHtml,
                user: userData
            });

            onSubmit(feedbackNote);

            toast.success('Feedback Note sent successfully. Thank you!');
            setFeedbackNote('');
        } catch (error) {
            logger.error('Error sending feedback note:', error);
            toast.error('Error sending feedback note. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleCancel = () => {
        onCancel();
    };

    return (
        <Dialog open={isOpen} onClose={onCancel} maxWidth="sm" fullWidth>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <h2>Help us improve.</h2>

                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        margin="normal"
                        placeholder="Your feedback..."
                        name="feedback"
                        value={feedbackNote}
                        onChange={e => setFeedbackNote(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        disabled={isSubmitting || feedbackNote.trim() === ''}
                    >
                        {isSubmitting ? <CircularProgress size={24} /> : 'Send'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default FeedbackNote;
