// FeedbackDialog.tsx
import React, { useState } from 'react';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel,
    FormLabel,
    CircularProgress,
} from '@mui/material';

import { EmailMessageType, sendEmailMessage } from './EmailMessage';
import { useFirebaseAuth } from '@/lib/hooks/firebase';
import { toast } from 'sonner';

import logger from "@/lib/logger";

type FeedbackData = {
    isAppUseful: string;
    usageFrequency?: string;
    notUsefulReason?: string;
    buyProSubscription: string;
    noSubscriptionReason?: string;
    likesMost?: string;
    importantImprovements?: string;
};

interface FeedbackDialogProps {
    isOpen: boolean;
    onSubmitAttempt: (feedbackData: FeedbackData) => void;
    onSubmitSuccess: (feedbackData: FeedbackData) => void;
    onCancel: () => void;
}

const FeedbackDialog: React.FC<FeedbackDialogProps> = ({ isOpen, onSubmitAttempt, onSubmitSuccess, onCancel }) => {
    const { user } = useFirebaseAuth();

    const [feedback, setFeedback] = useState<FeedbackData>({
        isAppUseful: '',
        buyProSubscription: '',
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setFeedback(prevFeedback => ({
            ...prevFeedback,
            [name]: value,
        }));
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSubmitting(true);

        onSubmitAttempt(feedback);

        let userData = {
            id: user?.uid,
            email: user?.email,
        }

        const formattedFeedback = formatFeedbackAsHtml(feedback, userData);

        try {
            await sendEmailMessage({
                subject: (feedback.isAppUseful ? '+ve' : '-ve') + ' User App Eval from: ' + user?.displayName ?? 'unknown',
                type: EmailMessageType.AppFeedback,
                html: formattedFeedback,
                user: userData
            });

            onSubmitSuccess(feedback);

            toast.success('App Feedback sent successfully. Thank you!');
        } catch (error) {
            // Handle errors, e.g., show an error message
            logger.error('Error sending App Feedback:', error);
            toast.error('Error sending App Feedback. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleCloseDialog = () => {
        onCancel();
    }

    // Format feedback data as HTML
    const formatFeedbackAsHtml = (feedbackData: FeedbackData, userData): string => {
        let formattedFeedback = '<html><body>';
        formattedFeedback += `<p>Is the app useful to you? <strong>${feedbackData.isAppUseful}</strong></p>`;

        if (feedbackData.isAppUseful === 'yes' && feedbackData.usageFrequency) {
            formattedFeedback += `<p>Usage Frequency: <strong>${feedbackData.usageFrequency}</strong></p>`;
        } else if (feedbackData.isAppUseful === 'no' && feedbackData.notUsefulReason) {
            formattedFeedback += `<p>Why is the app not useful? <blockquote>${feedbackData.notUsefulReason}</blockquote></p>`;
        }

        formattedFeedback += `<p>Willing to buy the pro subscription? <strong>${feedbackData.buyProSubscription}</strong></p>`;

        if (feedbackData.buyProSubscription === 'no' && feedbackData.noSubscriptionReason) {
            formattedFeedback += `<p>Reason for not buying the pro subscription: <blockquote>${feedbackData.noSubscriptionReason}</blockquote></p>`;
        }

        if (feedbackData.likesMost) {
            formattedFeedback += `<p>What do you like most about the app? <blockquote>${feedbackData.likesMost}</blockquote></p>`;
        }

        if (feedbackData.importantImprovements) {
            formattedFeedback += `<p>Most important improvements: <blockquote>${feedbackData.importantImprovements}</blockquote></p>`;
        }

        // Include user information
        formattedFeedback += `<p>User ID: <strong>${userData.id}</strong></p>`;
        if (userData.email) {
            formattedFeedback += `<p>User Email: <strong>${userData.email}</strong></p>`;
        }

        formattedFeedback += '</body></html>';

        return formattedFeedback;
    };

    return (
        <Dialog open={isOpen} onClose={onCancel} aria-labelledby="feedback-dialog-title">
            <DialogTitle id="feedback-dialog-title">App Feedback</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent style={{ maxHeight: '65vh' }}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                        <FormLabel component="legend">Is the app useful to you?</FormLabel>
                        <RadioGroup
                            aria-label="is-app-useful"
                            name="isAppUseful"
                            value={feedback.isAppUseful}
                            onChange={handleInputChange}
                        >
                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>

                    {feedback.isAppUseful === 'yes' && (
                        <FormControl fullWidth margin="normal">
                            <FormLabel component="legend">At what frequency do you anticipate to use the app?</FormLabel>
                            <RadioGroup
                                aria-label="usage-frequency"
                                name="usageFrequency"
                                value={feedback.usageFrequency}
                                onChange={handleInputChange}
                            >
                                <FormControlLabel value="Daily" control={<Radio />} label="Daily" />
                                <FormControlLabel value="Weekly" control={<Radio />} label="Weekly" />
                                <FormControlLabel value="Monthly" control={<Radio />} label="Monthly" />
                                <FormControlLabel value="Occasionally" control={<Radio />} label="Occasionally" />
                            </RadioGroup>
                        </FormControl>
                    )}

                    {feedback.isAppUseful === 'no' && (
                        <TextField
                            margin="normal"
                            label="Why is the app not useful to you?"
                            fullWidth
                            multiline
                            name="notUsefulReason"
                            value={feedback.notUsefulReason}
                            onChange={handleInputChange}
                        />
                    )}

                    <FormControl component="fieldset" fullWidth margin="normal">
                        <FormLabel component="legend">Are you willing to buy the pro subscription for the app?</FormLabel>
                        <RadioGroup
                            aria-label="buy-pro-subscription"
                            name="buyProSubscription"
                            value={feedback.buyProSubscription}
                            onChange={handleInputChange}
                        >
                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>

                    {feedback.buyProSubscription === 'no' && (
                        <TextField
                            margin="normal"
                            label="Is it due to price, feature gap, or something else?"
                            fullWidth
                            multiline
                            name="noSubscriptionReason"
                            value={feedback.noSubscriptionReason}
                            onChange={handleInputChange}
                        />
                    )}

                    <TextField
                        margin="normal"
                        label="What do you like most about the app?"
                        fullWidth
                        multiline
                        minRows={2}
                        name="likesMost"
                        value={feedback.likesMost}
                        onChange={handleInputChange}
                    />

                    <TextField
                        margin="normal"
                        label="What are the most important improvements that could be made to the app?"
                        fullWidth
                        multiline
                        minRows={2}
                        name="importantImprovements"
                        value={feedback.importantImprovements}
                        onChange={handleInputChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button
                        type="submit"
                        variant="outlined"
                        color="primary"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? <CircularProgress size={24} /> : 'Submit'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default FeedbackDialog;
