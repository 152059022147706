import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useTheme } from "next-themes";

import { Menu, MenuItem, Box, Tooltip, Typography, LinearProgress, ListItemIcon } from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';

import { debounce, describeTimeRange, formatDate, getProgressStatus, getProgressStatusColor } from '@/lib/utils';
import IconComponent from './IconComponent';
import { DragItemTypes, IconSelectionInfo, PlanMenuInfo, TIMELINE_LAYER_HEIGHT, TimelineLayerItem } from '@/lib/interfaces';

import logger from '@/lib/logger';

interface DraggableTimelineMilestoneProps {
    layerItem: TimelineLayerItem;
    setLayerItem: React.Dispatch<React.SetStateAction<TimelineLayerItem>>;
    onMilestoneClick: (planId: string, milestoneId: string) => void;
    onMilestoneResize: (layerItem: TimelineLayerItem) => void;
    onMilestoneRemove: (milestoneId: string) => void;
    isIntimeline?: boolean;
    iconSelections?: { [key: string]: IconSelectionInfo };
    handleIconClickForPlan: (e: React.MouseEvent, plan: PlanMenuInfo) => void;
    containerWidth: number;
    timelineStartDate: Date;
    timelineEndDate: Date;
}

const DraggableTimelineMilestone: React.FC<DraggableTimelineMilestoneProps> = ({
    layerItem,
    setLayerItem,
    onMilestoneClick,
    onMilestoneResize,
    onMilestoneRemove,
    isIntimeline,
    iconSelections,
    handleIconClickForPlan,
    containerWidth,
    timelineStartDate,
    timelineEndDate,
}) => {
    const { theme, systemTheme } = useTheme();
    const effectiveTheme = theme === 'system' ? systemTheme : theme;

    const minMilestoneWidth = 55;
    const [milestoneWidth, setMilestoneWidth] = useState((layerItem.widthPercent / 100) * containerWidth);

    useEffect(() => {
        setMilestoneWidth((layerItem.widthPercent / 100) * containerWidth);
    }, [layerItem.widthPercent, containerWidth]);

    const actualWidth = milestoneWidth > minMilestoneWidth ? `${layerItem.widthPercent}%` : `${minMilestoneWidth}px`;

    const milestoneRef = useRef<HTMLDivElement | null>(null);
    const [resizing, setResizing] = useState<boolean | 'start' | 'end'>(false);
    const [previewStartDate, setPreviewStartDate] = useState<string | null>(null);
    const [previewEndDate, setPreviewEndDate] = useState<string | null>(null);

    const [hoveringStart, setHoveringStart] = useState<boolean>(false);
    const [hoveringEnd, setHoveringEnd] = useState<boolean>(false);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleContextMenu = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePushOut = () => {
        const oneDay = 24 * 60 * 60 * 1000; // milliseconds in one day
        const newEndDate = new Date(timelineEndDate.getTime() + oneDay + (timelineEndDate.getTime() - timelineStartDate.getTime()));

        layerItem.milestone.startDate = timelineEndDate.toISOString();
        layerItem.milestone.endDate = newEndDate.toISOString();

        onMilestoneRemove(layerItem.milestone.id);
        
        setLayerItem({ ...layerItem });

        handleClose();
    };

    const calculateNewDate = useCallback((percent: number): string => {
        const timelineDuration = timelineEndDate.getTime() - timelineStartDate.getTime();
        const calculatedTime = timelineStartDate.getTime() + (percent / 100) * timelineDuration;

        const newDate = Math.min(calculatedTime, timelineEndDate.getTime());
        return new Date(newDate).toISOString();
    }, [timelineStartDate, timelineEndDate]);

    const [{ isDragging }, drag, preview] = useDrag(() => ({
        type: DragItemTypes.TIMELINE_MILESTONE,
        item: {
            layerIndex: layerItem.layerIndex,
            objectiveText: layerItem.milestone.okr.objective,
            isIntimeline: isIntimeline,
            iconSelections,
            milestone: layerItem.milestone
        },
        canDrag: !resizing, // Prevent dragging when resizing
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }), [resizing, layerItem]);

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);

    const dragRef = useCallback((node) => {
        drag(node);
      }, [drag]);

    const handleResizeStart = (e: React.MouseEvent, side: 'start' | 'end') => {
        e.stopPropagation();

        logger.debug('Resize start:', side);
        setResizing(side);

        document.body.style.userSelect = 'none';

        setPreviewStartDate(layerItem.milestone.startDate);
        setPreviewEndDate(layerItem.milestone.endDate);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleResize = useCallback(debounce((e: MouseEvent) => {
        if (resizing && milestoneRef.current) {
            const rect = milestoneRef.current?.getBoundingClientRect();
            const deltaX = e.clientX - ((resizing === 'start') ? rect.left : rect.right);

            if (resizing === 'start') {
                const newStartPercent = Math.round((layerItem.startPercent + (deltaX / containerWidth) * 100) * 100) / 100;
                const newWidthPercent = layerItem.endPercent - newStartPercent;

                logger.debug('New start percent:', newStartPercent, 'New width percent:', newWidthPercent);

                if (newStartPercent >= 0 && newWidthPercent >= 1) {
                    const newStartDate = calculateNewDate(newStartPercent);
                    setPreviewStartDate(newStartDate);

                    layerItem.startPercent = newStartPercent;
                    layerItem.widthPercent = newWidthPercent;
                    setMilestoneWidth(newWidthPercent / 100 * containerWidth);
                } else {
                    logger.debug('New start percent is less than 0 or new width percent is less than 1', newStartPercent, newWidthPercent);
                }
            } else if (resizing === 'end') {
                const newWidthPercent = Math.round((layerItem.widthPercent + (deltaX / containerWidth) * 100) * 100) / 100;
                const newEndPercent = layerItem.startPercent + newWidthPercent;

                logger.debug('New width percent:', newWidthPercent, 'New end percent:', newEndPercent);

                if (newWidthPercent >= 1 && newEndPercent <= 100) {
                    const newEndDate = calculateNewDate(newEndPercent);
                    setPreviewEndDate(newEndDate);

                    layerItem.endPercent = newEndPercent;
                    layerItem.widthPercent = newWidthPercent;
                    setMilestoneWidth(newWidthPercent / 100 * containerWidth);
                } else {
                    logger.debug('New width percent is less than 1 or new end percent is greater than 100', newWidthPercent, newEndPercent);
                }
            }
        }
    }, 5), [resizing, milestoneRef, containerWidth, calculateNewDate, layerItem, setMilestoneWidth]);

    const handleResizeEnd = useCallback(() => {
        logger.debug('Resize end');

        document.body.style.userSelect = '';

        setResizing(false);

        const originalStartDate = layerItem.milestone.startDate;
        const originalEndDate = layerItem.milestone.endDate;

        logger.debug('Original start date:', originalStartDate, 'Original end date:', originalEndDate);
        logger.debug('Preview start date:', previewStartDate, 'Preview end date:', previewEndDate);

        if ((previewStartDate && originalStartDate !== previewStartDate) || (previewEndDate && originalEndDate !== previewEndDate)) {
            layerItem.milestone.endDate = previewEndDate || originalEndDate;
            layerItem.milestone.startDate = previewStartDate || originalStartDate;
            layerItem.milestone.description = describeTimeRange(layerItem.milestone.startDate, layerItem.milestone.endDate);

            setLayerItem({ ...layerItem });
            onMilestoneResize(layerItem);
        }

        setPreviewStartDate(null);
        setPreviewEndDate(null);
    }, [layerItem, onMilestoneResize, setLayerItem, previewStartDate, previewEndDate]);

    useEffect(() => {
        const handleMouseUp = () => handleResizeEnd();

        if (resizing) {
            window.addEventListener('mousemove', handleResize);
            window.addEventListener('mouseup', handleMouseUp);
        } else {
            window.removeEventListener('mousemove', handleResize);
            window.removeEventListener('mouseup', handleMouseUp);
        }

        return () => {
            window.removeEventListener('mousemove', handleResize);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [resizing, handleResize, handleResizeEnd]);

    const milestoneProgressInfo = {
        startDate: layerItem.milestone.startDate,
        endDate: layerItem.milestone.endDate,
        progress: layerItem.milestone.progress,
    };

    const milestoneProgressStatus = getProgressStatus(milestoneProgressInfo);
    const milestoneProgressColor = getProgressStatusColor(milestoneProgressInfo, effectiveTheme);

    return (
        <div>
            <Box
                ref={milestoneRef}
                position="absolute"
                left={`${layerItem.startPercent}%`}
                width={actualWidth}
                top={`${layerItem.layerIndex * TIMELINE_LAYER_HEIGHT + 5}px`}
                onContextMenu={handleContextMenu}
            >
                <Tooltip
                    title={(previewStartDate || hoveringStart) ? formatDate(new Date(previewStartDate || layerItem.milestone.startDate)) : ''}
                    open={previewStartDate !== null || hoveringStart}
                    placement="top"
                >
                    <div
                        style={{
                            position: 'absolute',
                            left: '0',
                            width: '8px',
                            height: '100%',
                            cursor: 'col-resize',
                            backgroundColor: 'transparent',
                            zIndex: 10,
                        }}
                        onMouseDown={(e) => handleResizeStart(e, 'start')}
                        onMouseEnter={() => setHoveringStart(true)}
                        onMouseLeave={() => setHoveringStart(false)}
                    />
                </Tooltip>

                <Tooltip
                    title={(!!previewEndDate || hoveringEnd) ? formatDate(new Date(previewEndDate || layerItem.milestone.endDate)) : ''}
                    placement="top"
                    open={previewEndDate !== null || hoveringEnd}
                >
                    <div
                        style={{
                            position: 'absolute',
                            right: '0',
                            width: '8px',
                            height: '100%',
                            cursor: 'col-resize',
                            backgroundColor: 'transparent',
                            zIndex: 10,
                        }}
                        onMouseDown={(e) => handleResizeStart(e, 'end')}
                        onMouseEnter={() => setHoveringEnd(true)}
                        onMouseLeave={() => setHoveringEnd(false)}
                    />
                </Tooltip>

                <Box
                    ref={dragRef}
                    style={{ opacity: isDragging ? 0.5 : 1 }}
                    sx={{
                        color: 'text.primary',
                        backgroundColor: '#f5f5f5',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '5px',
                        border: 'none',
                        padding: '10px',
                        margin: '5px',
                        cursor: 'pointer',
                    }}
                    onClick={(e) => {
                        logger.debug('Milestone clicked in layer:', layerItem.milestone);

                        if (!resizing && layerItem.milestone && layerItem.milestone.plan) {
                            e.stopPropagation();
                            onMilestoneClick(layerItem.milestone.plan.id, layerItem.milestone.id);
                        }
                    }}
                >
                    <div style={{ width: '100%', overflowX: 'auto', display: 'flex', alignItems: 'center' }}>
                        {isIntimeline && layerItem.milestone.plan ? (
                            <Tooltip
                                title={
                                    <>
                                        <Typography variant="h6"><b>{layerItem.milestone.plan.title}</b></Typography>
                                        <Typography variant="body2">{layerItem.milestone.plan.description}</Typography>
                                        <br />
                                        <Typography variant="body2"><b>Updated:</b> {formatDate(layerItem.milestone.plan.updatedDate)}</Typography>
                                        <Typography variant="body2" style={{ marginTop: '4px' }}><b>Created:</b> {formatDate(layerItem.milestone.plan.creationDate)}</Typography>
                                    </>
                                }
                                placement="bottom"
                            >
                                <div>
                                    <IconComponent
                                        key={`plan-icon-${layerItem.milestone.plan.icon?.name}-${layerItem.milestone.plan.icon?.color}`}
                                        size="small"
                                        type={layerItem.milestone.plan.type ?? 'plan'}
                                        iconSelection={layerItem.milestone.plan.icon}
                                        onClick={(e) => handleIconClickForPlan(e, layerItem.milestone.plan)}
                                    />
                                </div>
                            </Tooltip>
                        ) : (
                            iconSelections && iconSelections[layerItem.milestone.id] && (
                                <IconComponent
                                    size="small"
                                    type="milestone"
                                    iconSelection={iconSelections[layerItem.milestone.id]}
                                />
                            )
                        )}

                        <Tooltip title={layerItem.milestone.okr.objective} placement='top'>
                            <Typography
                                variant="body2"
                                component="div"
                                sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    fontWeight: 'bold',
                                    marginLeft: '8px',
                                }}
                            >
                                {layerItem.milestone.okr.objective}
                            </Typography>
                        </Tooltip>
                    </div>

                    <Tooltip
                        placement='bottom'
                        title={
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center'
                                }}
                            >
                                <Typography variant="body2" component="div" gutterBottom>
                                    {layerItem.milestone.description}</Typography>
                                <Typography variant="body2" component="div">
                                    {milestoneProgressStatus}
                                </Typography>
                            </Box>
                        }
                        arrow
                    >
                        <Box sx={{ width: '100%', m: 1 }}>
                            <LinearProgress variant="determinate" value={Math.round(layerItem.milestone.progress)} sx={{ '& .MuiLinearProgress-bar': { backgroundColor: milestoneProgressColor }, height: '5px', borderRadius: '5px' }} />
                        </Box>
                    </Tooltip>
                </Box>

                {isIntimeline && (
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handlePushOut}>
                            <ListItemIcon>
                                <UpdateIcon />
                            </ListItemIcon>
                            Push Out
                        </MenuItem>
                    </Menu>
                )}

            </Box>
        </div>
    );
};

export default DraggableTimelineMilestone;
