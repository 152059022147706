import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';

import { CircularProgress, TextField, IconButton, Box, Button, Tooltip, Typography, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import { KeyResult, OKRData, PlanInfo } from '@/lib/interfaces';

import { getFirebaseAnalytics, useFirebaseAuth } from '@/lib/hooks/firebase';
import { logEvent } from 'firebase/analytics';

import logger from '@/lib/logger';
import { sendEmailMessageForMilestoneComplete } from './EmailMessage';

import DraggableKeyResult from './DraggableKeyResult';

import Confetti from 'react-confetti';
import { useTheme } from "next-themes";
import { getConfettiColors, getFilteredKeyResult } from '@/lib/utils';

interface OKREditorProps {
    plan: PlanInfo;
    okrData: OKRData;
    onUpdateKeyResults: (keyResults: KeyResult[]) => void;
    onOpenEditor: (sourceType, sourceId, sourceIndex) => void;
    onMoveKRToAnotherOKR: (sourceOkrId, targetOkrId, dragIndex, hoverIndex) => void;
    filterOptions?: {
        notStarted: boolean;
        inProgress: boolean;
        completed: boolean;
        scratched: boolean;
    };
}

const OKREditor: React.FC<OKREditorProps> = ({
    plan, okrData, onUpdateKeyResults, onOpenEditor, onMoveKRToAnotherOKR,
    filterOptions = {
        notStarted: true,
        inProgress: true,
        completed: true,
        scratched: true
    }
}) => {
    const { user } = useFirebaseAuth();
    const [keyResults, setKeyResults] = useState<KeyResult[]>(okrData.keyResults || []);

    const filteredKeyResults = useMemo(() => keyResults.filter(kr => {
        return getFilteredKeyResult(kr, filterOptions);
    }), [keyResults, filterOptions]);

    const [editingKR, setEditingKR] = useState(false);
    const [shouldSaveOKR, setShouldSaveOKR] = useState(false);

    useEffect(() => {
        if (shouldSaveOKR) {
            onUpdateKeyResults(keyResults);
            setShouldSaveOKR(false);
        }
    }, [shouldSaveOKR, keyResults, onUpdateKeyResults]);

    // Function to handle changes in key results
    const handleKeyResultChange = (index: number, newValue: KeyResult) => {
        const newKeyResults = [...keyResults];
        newKeyResults[index] = newValue;

        updateKeyResults(newKeyResults);
    };

    // Update the key results in the state and call onSaveOKR
    const updateKeyResults = useCallback((newKeyResults: KeyResult[]) => {
        setKeyResults(newKeyResults);
        setShouldSaveOKR(true);
    }, []);

    const moveKeyResult = (sourceOkrId, targetOkrId, dragIndex, hoverIndex) => {
        if (sourceOkrId === targetOkrId) {
            const dragItem = keyResults[dragIndex];

            const newKeyResults = Array.from(keyResults);
            newKeyResults.splice(dragIndex, 1);
            newKeyResults.splice(hoverIndex, 0, dragItem);

            updateKeyResults(newKeyResults);
        } else {
            // Pass the key result to the parent component to handle the move
            onMoveKRToAnotherOKR(sourceOkrId, targetOkrId, dragIndex, hoverIndex);
        }
    };

    const [isMultiCreateDialogOpen, setIsMultiCreateDialogOpen] = useState(false);

    // Call updateKeyResults in all places where keyResults are changed
    const handleAddOrExitEdit = useCallback((description: string, alwaysExit = false) => {
        logger.debug('handleAddOrExitEdit - Key result description: ', description);

        const keyResultDescriptions = description.split(/\r?\n/).filter(line => line.trim() !== '');

        logger.debug('handleAddOrExitEdit - Key result descriptions: ', keyResultDescriptions);

        if (keyResultDescriptions.length > 1) {
            setIsMultiCreateDialogOpen(true);
        } else if (description.trim()) {
            const newKeyResults = [...keyResults, { description, progress: 0 }];
            updateKeyResults(newKeyResults);

            setNewKRDescription(''); // Reset the text field

            if (alwaysExit) {
                setEditingKR(false);
            }

            logEvent(getFirebaseAnalytics(), 'key_result_add', {
                description_length: description.length
            });
        } else {
            // If the description is empty, just exit edit mode
            setEditingKR(false);
        }
    }, [keyResults, updateKeyResults]);

    const createMultipleKeyResults = (createMultiple: boolean) => {
        const keyResultDescriptions = newKRDescription.split(/\r?\n/).filter(line => line.trim() !== '');

        if (createMultiple) {
            const newKeyResults = keyResultDescriptions.map(description => ({
                description,
                progress: 0
            }));

            updateKeyResults([...keyResults, ...newKeyResults]);

            logEvent(getFirebaseAnalytics(), 'key_result_add_multiple', {
                count: newKeyResults.length
            });
        } else {
            let description = keyResultDescriptions.join(' ');
            const newKeyResult = { description, progress: 0 };
            updateKeyResults([...keyResults, newKeyResult]);

            logEvent(getFirebaseAnalytics(), 'key_result_add_once', {
                description_length: description.length
            });
        }

        // Reset the dialog and temporary description
        setIsMultiCreateDialogOpen(false);
        setNewKRDescription('');
        setEditingKR(false);
    };

    const handleDeleteKeyResult = (index: number) => {
        const newKeyResults = keyResults.filter((_, krIndex) => krIndex !== index);
        updateKeyResults(newKeyResults);

        logEvent(getFirebaseAnalytics(), 'key_result_delete', {
            key_result_index: index
        });
    };

    const checkIfObjectiveCompletedOnProgressChange = (newKeyResults) => {
        const allCompleted = newKeyResults.every(kr => kr.scratched || kr.progress === 100);

        if (allCompleted) {
            sendEmailMessageForMilestoneComplete(plan, { id: okrData.id, objective: okrData.objective, keyResults }, user);

            if (!showObjectiveConfetti) {
                setShowObjectiveConfetti(true);
                setTimeout(() => setShowObjectiveConfetti(false), 3000);
            }
        }
    };

    const handleProgressChange = (index: number, value: number) => {
        const newKeyResults = [...keyResults];
        newKeyResults[index].progress = Math.max(0, Math.min(100, value));

        checkIfObjectiveCompletedOnProgressChange(newKeyResults);

        updateKeyResults(newKeyResults);

        logEvent(getFirebaseAnalytics(), 'key_result_progress_change', {
            key_result_index: index,
            progress: value
        });
    };

    // Function to handle key down events
    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Escape') {
            setEditingKR(false);
        } else if (event.key === 'Enter' && !event.shiftKey) { // Allow shift+enter to actually insert a new line
            setEditingKR(true);
            setNewKRDescription('');
        }
    };

    // State for new key result's description
    const [newKRDescription, setNewKRDescription] = useState('');

    const okrAreaRef = useRef(null);
    const [showObjectiveConfetti, setShowObjectiveConfetti] = useState(false);

    const { theme, systemTheme } = useTheme();
    const effectiveTheme = theme === 'system' ? (systemTheme) : theme;

    const [isKeyResultTextFieldFocused, setIsKeyResultTextFieldFocused] = useState(false);
    const keyResultTextFieldRef = useRef(null);
    const timeoutRef = useRef(null);
    const [blurCounter, setBlurCounter] = useState(0);

    const handleKeyResultTextFieldFocus = () => {
        setIsKeyResultTextFieldFocused(true);
    };

    const handleKeyResultTextFieldBlur = () => {
        setIsKeyResultTextFieldFocused(false);
        setBlurCounter(prev => prev + 1);
    };

    useEffect(() => {
        if (isKeyResultTextFieldFocused) {
            // Clear existing timeout to prevent duplicate calls
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        } else {
            // If the text field is not focused, start the timeout to trigger the edit handling
            timeoutRef.current = setTimeout(() => {
                handleAddOrExitEdit(newKRDescription || '', true);
            }, 10);
        }
    }, [isKeyResultTextFieldFocused, blurCounter, newKRDescription, handleAddOrExitEdit]);

    useEffect(() => {
        return () => {
            // This will now only run when the component unmounts
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    const [isGeneratingKeyResultWithAI, setIsGeneratingKeyResultWithAI] = useState(false);

    const generateKeyResultWithAI = async (e) => {
        e.stopPropagation();
        setIsKeyResultTextFieldFocused(true);
        setIsGeneratingKeyResultWithAI(true);

        // Add your AI generation logic here
        logger.debug('Generating key result with AI...');

        const prompt = `
            Generate a new key result for the objective: "${okrData.objective}".

            Here are the current key results of the objective:
            ${keyResults.map(kr => kr.description).join('\n')}

            As you know, when all key results are completed, the objective is achieved. So, what's the next key result to add to the list?

            Also, just give me the key result description. No other unencessary words of text, keep it under 15 words.
        `;

        // Call the API and handle the response as a stream
        try {
            const response = await fetch('/api/generate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ prompt })
            });

            if (!response.ok) {
                throw new Error(`Error from API: ${response.statusText}`);
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');

            let result = '';
            reader.read().then(function processText({ done, value }) {
                if (done) {
                    logger.debug('Stream complete');
                    setNewKRDescription(result); // Update the TextField with the final result
                    return;
                }

                const text = decoder.decode(value, { stream: true });
                result += text;

                // Optionally update the TextField in real-time as data arrives
                setNewKRDescription(existing => existing + text);

                return reader.read().then(processText);
            });
        } catch (error) {
            logger.error('Failed to generate text:', error);
        }

        if (keyResultTextFieldRef?.current) {
            keyResultTextFieldRef.current.focus();
        }

        setIsGeneratingKeyResultWithAI(false);
    };

    const handleNewKRDescriptionChange = (e) => {
        let newValue = e.target.value;

        if (/^[\r\n]+/.test(newValue)) {
            newValue = newValue.replace(/^[\r\n]+/, '');
        }

        setNewKRDescription(newValue);

        if (newValue.startsWith('++')) {
            // Remove '++' from the input to prepare for AI-generated content
            setNewKRDescription(newValue.slice(2).trimStart());

            // Asynchronously trigger AI generation and handle any errors
            generateKeyResultWithAI(e).catch((error) => {
                logger.error("Error generating key result with AI:", error);
            });
        }
    };

    const addNewKeyResult = () => {
        setEditingKR(true);
    };

    return (
        <>
            <Box ref={okrAreaRef} width="100%" style={{ marginLeft: '4px' }}>

                {filteredKeyResults.length === 0 ? (
                    keyResults.length === 0 ? (
                        <Typography variant="body2" color="GrayText" style={{ marginTop: '4px' }} className='screenshot-remove'>
                            No key results added yet. Click "Add a key result" to get started.
                        </Typography>
                    ) : (
                        <Typography variant="body2" color="GrayText" style={{ marginTop: '4px' }} className='screenshot-remove'>
                            No key results matching your filters.
                        </Typography>
                    )
                ) : (
                    keyResults.map((kr, index) => {
                        let filteredKeyResult = getFilteredKeyResult(kr, filterOptions);

                        if (filteredKeyResult) {
                            return <DraggableKeyResult
                                key={`kr-${okrData.id}-${index}-${kr.progress}`}
                                kr={kr}
                                index={index}
                                okrId={okrData.id}
                                objective={okrData.objective}
                                handleKeyResultChange={handleKeyResultChange}
                                handleKeyDown={handleKeyDown}
                                handleDeleteKeyResult={handleDeleteKeyResult}
                                handleProgressChange={handleProgressChange}
                                onOpenEditor={onOpenEditor}
                                moveKeyResult={moveKeyResult}
                            />;
                        }

                        return null;
                    })
                )}

                {/* Add new Key Result */}
                {editingKR ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            multiline
                            label={`Key Result ${keyResults.length + 1}`}
                            placeholder="Type '++' to generate with AI"
                            inputRef={keyResultTextFieldRef}
                            value={newKRDescription}
                            onChange={handleNewKRDescriptionChange}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    handleAddOrExitEdit((e.target as HTMLInputElement).value || '');
                                } else if (e.key === 'Escape') {
                                    setEditingKR(false);
                                }
                            }}
                            onFocus={handleKeyResultTextFieldFocus}
                            onBlur={handleKeyResultTextFieldBlur}
                            autoFocus
                            InputLabelProps={{
                                className: 'dark:text-gray-100',
                            }}
                            InputProps={{
                                className: 'bg-gray-50 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-600',
                                endAdornment: (
                                    <Tooltip title="Generate with AI" placement="top">
                                        <IconButton
                                            onClick={generateKeyResultWithAI}
                                            className="cursor-pointer"
                                            disabled={isGeneratingKeyResultWithAI}
                                        >
                                            {isGeneratingKeyResultWithAI ? (
                                                <CircularProgress size={24} className="dark:text-gray-100" />
                                            ) : (
                                                <AutoAwesomeIcon className="dark:text-gray-100" />
                                            )}
                                        </IconButton>
                                    </Tooltip>
                                ),
                            }}
                            margin="normal"
                        />
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
                        <Button variant="outlined" color="primary" onClick={addNewKeyResult} startIcon={<AddIcon />} className='screenshot-remove'>
                            Add a key result
                        </Button>
                    </Box>
                )}

                <Dialog open={isMultiCreateDialogOpen} onClose={() => setIsMultiCreateDialogOpen(false)}>
                    <DialogTitle>{"Multiple key results detected"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Do you want to create a key result for every new line?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => createMultipleKeyResults(false)}>Just one key result</Button>
                        <Button onClick={() => createMultipleKeyResults(true)} color="primary" autoFocus>
                            Create {newKRDescription.split(/\r?\n/).filter(line => line.trim() !== '').length} key results
                        </Button>
                    </DialogActions>
                </Dialog>

            </Box>

            {showObjectiveConfetti && okrAreaRef.current && (
                <Confetti
                    width={okrAreaRef.current.offsetWidth}
                    height={okrAreaRef.current.offsetHeight}
                    recycle={false}
                    numberOfPieces={500}
                    wind={0}
                    gravity={0.5}
                    run={showObjectiveConfetti}
                    colors={getConfettiColors(effectiveTheme)}
                    tweenDuration={5000}
                />
            )}
        </>
    );
};

export default OKREditor;
