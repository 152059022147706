import React, { useState, useRef, useEffect } from 'react';
import { TextField, IconButton, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { getFirebaseAnalytics } from '@/lib/hooks/firebase';
import { logEvent } from 'firebase/analytics';

const EditableDescription = ({ initialDescription, onSave }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [description, setDescription] = useState(initialDescription);
    const inputRef = useRef(null);

    useEffect(() => {
        setDescription(initialDescription);
    }, [initialDescription]);

    useEffect(() => {
        if (isEditing) {
            // Focus the input when editing starts
            inputRef.current?.focus();
        }
    }, [isEditing]);

    const handleEdit = () => {
        setIsEditing(true);

        // Log event for starting editing
        logEvent(getFirebaseAnalytics(), 'start_editing_description');
    };

    const handleSave = () => {
        if (description !== initialDescription) {
            onSave(description);

            // Log event for saving description
            logEvent(getFirebaseAnalytics(), 'save_description', {
                length: description.length
            });
        }

        setIsEditing(false);
    };

    const handleKeyDown = (event) => {
        // Save on Enter key press
        if (event.key === 'Enter') {
            handleSave();
        }
    };

    return (
        <div className='text-left'>
            {isEditing ? (
                <TextField
                    fullWidth
                    multiline
                    variant="outlined"
                    inputRef={inputRef}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    onKeyDown={handleKeyDown}
                    onBlur={handleSave}
                    InputLabelProps={{
                        className: 'dark:text-gray-100',
                    }}
                    InputProps={{
                        className: 'dark:bg-gray-700 dark:text-gray-300 dark:border-gray-600',
                        endAdornment: (
                            <IconButton
                                onClick={handleSave}
                                className="text-gray-600 hover:text-blue-700 dark:text-gray-300 dark:hover:text-blue-500 p-1 ml-2"
                            >
                                <SaveIcon />
                            </IconButton>
                        ),
                    }}
                />
            ) : (
                <div
                    onClick={handleEdit}
                    onMouseEnter={(e) => (e.currentTarget.style.borderBottom = '2px solid lightgray')}
                    onMouseLeave={(e) => (e.currentTarget.style.borderBottom = 'none')}
                    style={{ cursor: 'pointer', minHeight: '40px' }}
                >
                    <Typography variant="subtitle1">
                        {description || "Click to add a description..."}
                    </Typography>
                </div>
            )}
        </div>
    );
};

export default EditableDescription;
