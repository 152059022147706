import React, { useRef, useState, useEffect, useContext, useMemo } from 'react';
import { useTheme } from 'next-themes';

import { Tooltip, Box, Typography, LinearProgress, Menu, MenuItem, Button, Popover } from '@mui/material';

import UpdateIcon from '@mui/icons-material/Update';

import { ProgressInfo, TimelineMilestoneData, PlanMenuInfo, IconSelectionInfo, TIMELINE_LAYER_HEIGHT, TimelineLayerItem } from '@/lib/interfaces';
import { getProgressStatusColor, getProgressStatus, getTimeProgress, getTimeElapsed, getTimeRemaining, moveMilestoneOnTimeline, assignTimelinePropertiesToMilestones, debounce, formatDateButtonText } from '@/lib/utils';
import TimelineLayer from './TimelineLayer';

import logger from "@/lib/logger";
import { AppContext } from '@/app/providers';

import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

interface TimelineBarProps {
    milestones: TimelineMilestoneData[];
    planProgressInfo: ProgressInfo;
    isIntimeline?: boolean;
    onTimeRangeChange?: (start: string, end: string) => void;
    iconSelections?: { [key: string]: IconSelectionInfo };
    onSelectPlan?: (selectedPlan: PlanMenuInfo) => void;
    onMilestoneClick: (planId: string, milestoneId: string) => void;
    updateMilestones: (milestones: TimelineLayerItem[]) => void;
}

const TimelineBar: React.FC<TimelineBarProps> = ({ milestones, planProgressInfo, isIntimeline = false, onTimeRangeChange, iconSelections, onSelectPlan, onMilestoneClick, updateMilestones }) => {
    const debouncedUpdateMilestones = debounce(updateMilestones, 500);

    const { theme, systemTheme } = useTheme();
    const effectiveTheme = theme === 'system' ? systemTheme : theme;
    const { isMobile } = useContext(AppContext);

    const containerRef = useRef<HTMLDivElement>(null);
    const priorityIndicatorRef = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [priorityWidth, setPriorityWidth] = useState(0);
    const [priorityHeight, setPriorityHeight] = useState(0);
    const [priorityY, setPriorityY] = useState(0);

    const [isTodayHovered, setIsTodayHovered] = useState(false);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [timelineStart, setTimelineStart] = useState(dayjs(planProgressInfo.startDate));
    const [timelineEnd, setTimelineEnd] = useState(dayjs(planProgressInfo.endDate));

    const handleTimelineDateChange = (type: 'start' | 'end', newDate: Dayjs | null) => {
        if (type === 'start') {
            setTimelineStart(newDate);
            if (!isIntimeline) {
                updateFirstMilestoneStartDate(newDate);
            } else {
                updateTimeRangeFilter('start', newDate);
            }
        } else {
            setTimelineEnd(newDate);
            if (!isIntimeline) {
                updateLastMilestoneEndDate(newDate);
            } else {
                updateTimeRangeFilter('end', newDate);
            }
        }
        handleCloseTimelinePicker();
    };

    const updateTimeRangeFilter = (type: 'start' | 'end', newDate: Dayjs | null) => {
        if (newDate && isIntimeline && onTimeRangeChange) {
            onTimeRangeChange(
                type === 'start' ? newDate.toISOString() : timelineStart.toISOString(),
                type === 'end' ? newDate.toISOString() : timelineEnd.toISOString()
            );
        }
    };

    const updateFirstMilestoneStartDate = (newDate: Dayjs | null) => {
        if (newDate && milestones.length > 0) {
            const updatedMilestones = [...milestones];
            updatedMilestones[0] = {
                ...updatedMilestones[0],
                startDate: newDate.toISOString(),
            };
            const updatedTimelineLayerItems = assignTimelinePropertiesToMilestones(updatedMilestones, planProgressInfo.startDate, planProgressInfo.endDate);
            updateMilestones(updatedTimelineLayerItems.flat());
        }
    };

    const updateLastMilestoneEndDate = (newDate: Dayjs | null) => {
        if (newDate && milestones.length > 0) {
            const updatedMilestones = [...milestones];
            updatedMilestones[updatedMilestones.length - 1] = {
                ...updatedMilestones[updatedMilestones.length - 1],
                endDate: newDate.toISOString(),
            };
            const updatedTimelineLayerItems = assignTimelinePropertiesToMilestones(updatedMilestones, planProgressInfo.startDate, planProgressInfo.endDate);
            updateMilestones(updatedTimelineLayerItems.flat());
        }
    };

    const [timelineAnchorEl, setTimelineAnchorEl] = useState<null | HTMLElement>(null);
    const [openTimelinePicker, setOpenTimelinePicker] = useState<'start' | 'end' | null>(null);

    const handleTimelineDateClick = (event: React.MouseEvent<HTMLButtonElement>, type: 'start' | 'end') => {
        setTimelineAnchorEl(event.currentTarget);
        setOpenTimelinePicker(type);
    };

    const handleCloseTimelinePicker = () => {
        setTimelineAnchorEl(null);
        setOpenTimelinePicker(null);
    };

    const handleContextMenu = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const { startDate, endDate, progress } = planProgressInfo;
    const startDateObj = useMemo(() => new Date(startDate), [startDate]);
    const endDateObj = useMemo(() => new Date(endDate), [endDate]);

    const handleIconClickForPlan = (e, plan) => {
        logger.debug('handleIconClickForPlan', plan);

        e.stopPropagation();

        if (onSelectPlan) {
            onSelectPlan(plan);
        }
    };

    const [stackedMilestones, setStackedMilestones] = useState(() => assignTimelinePropertiesToMilestones(milestones, startDate, endDate));

    const moveMilestoneBetweenLayers = (sourceLayer, sourceMilestoneId, destinationLayer) => {
        logger.debug('Moving milestone:', sourceLayer, sourceMilestoneId, destinationLayer);

        const { newLayers, impactedMilestones } = moveMilestoneOnTimeline(stackedMilestones, sourceLayer, sourceMilestoneId, destinationLayer);

        logger.debug('New Stacked Milestones:', newLayers);
        logger.debug('Impacted Milestones:', impactedMilestones);

        setStackedMilestones(newLayers);

        debouncedUpdateMilestones(impactedMilestones);
    };

    const removeMilestoneInLayer = (layerIndex, milestoneId) => {
        const updatedStackedMilestones = stackedMilestones.map((layer, index) => {
            if (index === layerIndex) {
                return layer.filter(milestoneItem => milestoneItem.milestone.id !== milestoneId);
            }
            return layer;
        });

        setStackedMilestones(updatedStackedMilestones);
    };

    useEffect(() => {
        setStackedMilestones(assignTimelinePropertiesToMilestones(milestones, startDate, endDate));
    }, [milestones, startDate, endDate]);

    const handleExportJSON = () => {
        const dataStr = JSON.stringify(stackedMilestones, null, 2);

        const blob = new Blob([dataStr], { type: "application/json" });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = 'timelines.json';
        link.click();
        URL.revokeObjectURL(url);
    };

    const [cutLine, setCutLine] = useState<number>(() => {
        const savedCutLine = localStorage.getItem('cutLine');
        return savedCutLine ? parseInt(savedCutLine, 10) : Math.min(5, stackedMilestones.length);
    });

    const [dragging, setDragging] = useState(false);
    const [cutLinePosition, setCutLinePosition] = useState(priorityY + cutLine * (priorityHeight / stackedMilestones.length) - 1); // Updated

    const handleDragCutLineStart = () => {
        setDragging(true);
        document.body.style.userSelect = 'none';
    };

    const handleDragCutLine = (e) => {
        if (dragging && containerRef.current) {
            const newPosition = e.clientY - containerRef.current.getBoundingClientRect().top;
            setCutLinePosition(newPosition);
        }
    };

    const handleDragCutLineEnd = () => {
        if (dragging) {
            setDragging(false);
            document.body.style.userSelect = '';

            // Calculate the nearest layer to snap to
            const newCutLine = Math.max(1, Math.min(10, Math.round((cutLinePosition + 1 - priorityY) / (priorityHeight / stackedMilestones.length))));
            setCutLine(newCutLine);
            localStorage.setItem('cutLine', newCutLine.toString());
            setCutLinePosition(priorityY + newCutLine * (priorityHeight / stackedMilestones.length) - 1);
        }
    };

    const handlePushOutBelowCutLine = () => {
        const oneDay = 24 * 60 * 60 * 1000;

        const updatedStackedMilestones = stackedMilestones.map(layer =>
            layer.map(milestoneItem => {
                if (milestoneItem.layerIndex + 1 > cutLine) {
                    const newStartDate = new Date(endDateObj.getTime() + oneDay).toISOString();
                    const newEndDate = new Date(endDateObj.getTime() + oneDay + (new Date(milestoneItem.milestone.endDate).getTime() - new Date(milestoneItem.milestone.startDate).getTime())).toISOString();
                    return {
                        ...milestoneItem,
                        milestone: {
                            ...milestoneItem.milestone,
                            startDate: newStartDate,
                            endDate: newEndDate,
                        }
                    };
                }
                return milestoneItem;
            })
        );

        // Flatten the updatedStackedMilestones to get the updated milestones for the updateMilestones function
        const updatedMilestones = updatedStackedMilestones.flat();

        // Update the milestones
        debouncedUpdateMilestones(updatedMilestones);

        // Quickly and visually remove milestones from current view
        const stackWithLayerBelowCutlineRemoved = stackedMilestones.map(layer =>
            layer.filter(milestoneItem => milestoneItem.layerIndex + 1 <= cutLine)
        );

        setStackedMilestones(stackWithLayerBelowCutlineRemoved);

        handleClose();
    };

    // Calculate today's position on the timeline
    const today = new Date();
    const totalDuration = endDateObj.getTime() - startDateObj.getTime();
    const elapsedDuration = today.getTime() - startDateObj.getTime();
    const todayPosition = (elapsedDuration / totalDuration) * (containerWidth - priorityWidth);

    const [isStartDateHidden, setIsStartDateHidden] = useState(false);
    const [isEndDateHidden, setIsEndDateHidden] = useState(false);
    const [isTodayHidden, setIsTodayHidden] = useState(true);

    const startDateRef = useRef<HTMLSpanElement>(null);
    const endDateRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        const handleResize = () => {
            if (!containerRef.current || !priorityIndicatorRef.current || !startDateRef.current || !endDateRef.current) {
                return;
            }

            const newContainerWidth = containerRef.current.offsetWidth;
            const newPriorityWidth = priorityIndicatorRef.current.offsetWidth;
            const newPriorityHeight = priorityIndicatorRef.current.offsetHeight;
            const newPriorityY = priorityIndicatorRef.current.offsetTop;
            const startDateWidth = startDateRef.current.offsetWidth;
            const endDateWidth = endDateRef.current.offsetWidth;

            setContainerWidth(newContainerWidth);
            setPriorityWidth(newPriorityWidth);
            setPriorityHeight(newPriorityHeight);
            setPriorityY(newPriorityY);
            setCutLinePosition(newPriorityY + cutLine * (newPriorityHeight / stackedMilestones.length) - 1);

            const today = new Date();
            const totalDuration = endDateObj.getTime() - startDateObj.getTime();
            const elapsedDuration = today.getTime() - startDateObj.getTime();
            const todayPosition = (elapsedDuration / totalDuration) * (Math.max(0, newContainerWidth - newPriorityWidth));

            logger.debug('Today Position:', todayPosition, newContainerWidth, newPriorityWidth, startDateWidth, endDateWidth)

            const newIsTodayHidden = today < startDateObj || today > endDateObj;

            setIsTodayHidden(newIsTodayHidden);
            setIsStartDateHidden(todayPosition < startDateWidth + 10 && !newIsTodayHidden);
            setIsEndDateHidden(newContainerWidth - newPriorityWidth - todayPosition < endDateWidth + 10 && !newIsTodayHidden);
        };

        // Initial calculation
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Cleanup
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [startDateObj, endDateObj, cutLine, stackedMilestones.length]);

    const handleExpandTimeline = (direction: 'left' | 'right', date: Date) => {
        if (direction === 'left') {
            setTimelineStart(dayjs(date));
            if (isIntimeline && onTimeRangeChange) {
                onTimeRangeChange(date.toISOString(), timelineEnd.toISOString());
            } else {
                updateFirstMilestoneStartDate(dayjs(date));
            }
        } else {
            setTimelineEnd(dayjs(date));
            if (isIntimeline && onTimeRangeChange) {
                onTimeRangeChange(timelineStart.toISOString(), date.toISOString());
            } else {
                updateLastMilestoneEndDate(dayjs(date));
            }
        }
    };

    return (
        <Box
            position="relative"
            width="100%"
            marginBottom="16px"
            ref={containerRef}
            onMouseMove={dragging ? handleDragCutLine : null}
            onMouseUp={handleDragCutLineEnd}
        >
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handlePushOutBelowCutLine}>
                    <UpdateIcon sx={{ marginRight: 1 }} />
                    Push Out
                </MenuItem>
            </Menu>

            {/* Progress Bar and Time Range */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    paddingBottom: '16px',
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <Typography variant="subtitle1" color="GrayText" sx={{ mr: 2 }}>
                        <strong>Progress: </strong>
                    </Typography>
                    <Tooltip
                        title={
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center'
                                }}
                            >
                                <Typography variant="body2" component="div" sx={{ fontWeight: 'bold' }} gutterBottom>
                                    {getProgressStatus(planProgressInfo)}
                                </Typography>

                                <Typography variant="body2" component="div" gutterBottom>
                                    {getTimeElapsed(planProgressInfo.startDate, planProgressInfo.endDate)} ({Math.round(getTimeProgress(planProgressInfo.startDate, planProgressInfo.endDate))}%) elapsed
                                </Typography>

                                <Typography variant="body2" component="div" gutterBottom>
                                    {getTimeRemaining(planProgressInfo.startDate, planProgressInfo.endDate)} remaining
                                </Typography>
                            </Box>
                        }
                        arrow
                    >
                        <Box sx={{ width: '100%', mr: 1 }}>
                            <LinearProgress variant="determinate" value={Math.round(progress)} sx={{ '& .MuiLinearProgress-bar': { backgroundColor: getProgressStatusColor(planProgressInfo, effectiveTheme) }, height: '10px', borderRadius: '5px' }} />
                        </Box>
                    </Tooltip>
                    <Box sx={{ minWidth: 35 }}>
                        <Box sx={{ textAlign: 'center' }}>
                            {`${Math.round(progress)}%`}
                        </Box>
                    </Box>
                </Box>

                <Box display="flex" justifyContent="space-between" width="100%">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Button
                            onClick={(e) => handleTimelineDateClick(e, 'start')}
                        >
                            <Typography
                                variant="caption"
                                ref={startDateRef}>
                                {formatDateButtonText(timelineStart)}
                            </Typography>
                        </Button>
                        <Popover
                            open={openTimelinePicker === 'start'}
                            anchorEl={timelineAnchorEl}
                            onClose={handleCloseTimelinePicker}
                        >
                            <DateCalendar
                                value={timelineStart}
                                onChange={(newDate) => handleTimelineDateChange('start', newDate)}
                            />
                        </Popover>

                        <Button onClick={(e) => handleTimelineDateClick(e, 'end')}>
                            <Typography
                                variant="caption"
                                ref={endDateRef}>
                                {formatDateButtonText(timelineEnd)}
                            </Typography>
                        </Button>
                        <Popover
                            open={openTimelinePicker === 'end'}
                            anchorEl={timelineAnchorEl}
                            onClose={handleCloseTimelinePicker}
                        >
                            <DateCalendar
                                value={timelineEnd}
                                onChange={(newDate) => handleTimelineDateChange('end', newDate)}
                            />
                        </Popover>
                    </LocalizationProvider>
                </Box>
            </Box>

            {/* Today's Marker */}
            {!isMobile && !(isTodayHidden || isStartDateHidden || isEndDateHidden) && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '35px',
                        left: `${priorityWidth + todayPosition}px`,
                        transform: 'translateX(-50%)',
                        zIndex: 2,
                        textAlign: 'center',
                        cursor: 'pointer',
                        '&:hover .today-line': {
                            visibility: 'visible',
                        }
                    }}
                    onMouseEnter={() => setIsTodayHovered(true)}
                    onMouseLeave={() => setIsTodayHovered(false)}
                >
                    <Typography variant="caption" sx={{ padding: '2px 4px', borderRadius: '4px' }}>Today</Typography>
                    <Typography variant="caption" sx={{ display: 'block', fontWeight: 'bold' }}>^</Typography>
                    <Box
                        className="today-line"
                        sx={{
                            position: 'absolute',
                            top: '100%',
                            left: '50%',
                            width: '2px',
                            height: 'calc(100vh - 100px)',  // Ensures the line extends to the bottom
                            backgroundColor: 'primary.main',
                            transform: 'translateX(-50%)',
                            visibility: isTodayHovered ? 'visible' : 'hidden',
                        }}
                    />
                </Box>
            )}

            {/* Cut Line */}
            {!isMobile && isIntimeline && milestones.length > 0 && (
                <Tooltip
                    title="Drag this 'cutline' to prioritize. Right-click for more."
                    arrow
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: `${cutLinePosition}px`,
                            width: '100%',
                            height: '2px',
                            backgroundColor: 'secondary.main',
                            cursor: 'ns-resize',
                            zIndex: 10
                        }}
                        onMouseDown={handleDragCutLineStart}
                        onContextMenu={handleContextMenu}
                    />
                </Tooltip>
            )}

            {/* Milestones with Priority Indicators */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                }}
            >
                {/* Priority Indicators - Column */}
                {milestones && milestones.length > 0 && (
                    <Box
                        ref={priorityIndicatorRef}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            width: '50px', // Fixed width for priority indicators
                        }}
                    >
                        {stackedMilestones.map((_, layerIndex) => (
                            <Typography
                                key={`priority-${layerIndex}`}
                                variant="subtitle1"
                                component="div"
                                sx={{
                                    height: `${TIMELINE_LAYER_HEIGHT}px`, // Adjust height to match layer height
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontWeight: 'bold',
                                    zIndex: 1,
                                    borderBottom: layerIndex < stackedMilestones.length - 1 ? '1px solid #e0e0e0' : 'none', // Add border to separate layers
                                }}
                            >
                                P{layerIndex + 1}
                            </Typography>
                        ))}
                    </Box>
                )}

                {/* Milestones */}
                <Box sx={{ flexGrow: 1, position: 'relative', overflowX: 'hidden' }}>
                    {milestones && milestones.length === 0 && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <Typography variant="body2" color="GrayText">
                                No milestones to show
                            </Typography>
                        </Box>
                    )}

                    {milestones && milestones.length > 0 && (
                        <>
                            {stackedMilestones.map((layer, layerIndex) => (
                                <TimelineLayer
                                    key={`layer-${layerIndex}`}
                                    layer={layer}
                                    layerIndex={layerIndex}
                                    containerWidth={containerWidth}
                                    moveMilestone={moveMilestoneBetweenLayers}
                                    removeMilestoneInLayer={removeMilestoneInLayer}
                                    updateMilestones={debouncedUpdateMilestones}
                                    onMilestoneClick={onMilestoneClick}
                                    isIntimeline={isIntimeline}
                                    iconSelections={iconSelections}
                                    handleIconClickForPlan={handleIconClickForPlan}
                                    timelineStartDate={startDateObj}
                                    timelineEndDate={endDateObj}
                                    onExpandTimeline={handleExpandTimeline}
                                />
                            ))}
                        </>
                    )}
                </Box>
            </Box>

            {process.env.NODE_ENV === 'development' && false && (
                <Box sx={{ display: 'flex', justifyContent: 'center', m: 3 }} className='screenshot-remove'>
                    <Button variant="contained" color="primary" onClick={handleExportJSON}>
                        Export JSON
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default TimelineBar;
