import { useState, useEffect } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { firestore } from "@/lib/hooks/firebase";

const useSubscription = (userId: string) => {
  const [subscription, setSubscription] = useState({
    hasProPlan: false,
    planUsage: {
      blank: 0,
      template: 0,
      ai: 0
    }
  });

  useEffect(() => {
    if (!userId) return; // Ensure there is a userId before proceeding

    const userRef = doc(firestore, 'users', userId);

    const unsubscribe = onSnapshot(userRef, (documentSnapshot) => {
      if (documentSnapshot.exists()) {
        const userData = documentSnapshot.data();
        const hasProPlan = userData.subscriptionPlan === 'pro';
        const planUsage = {
          blank: userData.planUsage?.blank || 0,
          template: userData.planUsage?.template || 0,
          ai: userData.planUsage?.ai || 0
        };
        setSubscription({ hasProPlan, planUsage });
      } else {
        setSubscription({
          hasProPlan: false,
          planUsage: {
            blank: 0,
            template: 0,
            ai: 0
          }
        });
      }
    });

    return () => unsubscribe();
  }, [userId]);

  return subscription;
};

export default useSubscription;
