import React, { useState } from 'react';

import { Grid, Tooltip, IconButton, Select, MenuItem, FormControl, InputLabel, Typography, Card, CardContent } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import IconComponent from './IconComponent';

import { PlanInfo, PlanMenuInfo } from '@/lib/interfaces';
import { formatDate } from '@/lib/utils';
import logger from "@/lib/logger";

interface AllPlansViewProps {
    flatPlans: PlanInfo[];
    onSelectPlan: (selectedPlan: PlanMenuInfo) => void;
}

const AllPlansView: React.FC<AllPlansViewProps> = ({ flatPlans, onSelectPlan }) => {
    const [sortOption, setSortOption] = useState('updatedDate');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

    const handlePlanClick = (plan: PlanInfo) => {
        onSelectPlan(plan);
    };

    const getSortedPlans = () => {
        const sortedPlans = [...flatPlans.filter(plan => !plan.archived)].sort((a, b) => {
            const dateA = new Date(a[sortOption]);
            const dateB = new Date(b[sortOption]);
            return sortOrder === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
        });

        logger.debug('Sorted plans:', sortedPlans);

        return sortedPlans;
    };

    const handleSortChange = (event: SelectChangeEvent<string>) => {
        setSortOption(event.target.value);
    };

    const handleOrderChange = () => {
        setSortOrder(prevSortOrder => prevSortOrder === 'asc' ? 'desc' : 'asc');
    };

    const sortedPlans = getSortedPlans();

    return (
        <div style={{ width: '100%', minHeight: '100vh', overflow: 'auto', padding: '16px' }}>
            <Typography variant="h4" style={{ marginBottom: '16px' }}>All Plans</Typography>

            <div style={{ width: '100%', display: 'flex', marginBottom: '16px', gap: '5px' }}>
                <FormControl variant="outlined" size="small">
                    <InputLabel className='dark:text-gray-100'>Sort by</InputLabel>
                    <Select
                        value={sortOption}
                        onChange={handleSortChange}
                        label="Sort by"
                        className='bg-gray-50 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-600'
                    >
                        <MenuItem value="updatedDate">Last Updated</MenuItem>
                        <MenuItem value="creationDate">Creation Date</MenuItem>
                    </Select>
                </FormControl>

                <Tooltip title="Change order">
                    <IconButton className="dark:text-gray-100" onClick={handleOrderChange}>
                        {sortOrder === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                    </IconButton>
                </Tooltip>
            </div>

            <div style={{ width: '100%', overflowX: 'auto', paddingBottom: '16px' }}>
                <Grid container spacing={2} style={{ display: 'inline-flex' }}>
                    {sortedPlans.map((plan) => (
                        <Grid item key={plan.id}>
                            <Card style={{ width: '200px', height: '200px', borderRadius: '8px', overflow: 'hidden', cursor: 'pointer' }}>
                                <Tooltip
                                    title={
                                        <>
                                            <Typography variant="h6"><b>{plan.title}</b></Typography>
                                            <Typography variant="body2">{plan.description}</Typography>

                                            <br />

                                            <Typography variant="body2"><b>Updated:</b> {formatDate(plan.updatedDate)}</Typography>
                                            <Typography variant="body2" style={{ marginTop: '4px' }}><b>Created:</b> {formatDate(plan.creationDate)}</Typography>
                                        </>
                                    }
                                    placement="bottom"
                                >
                                    <CardContent onClick={() => handlePlanClick(plan)} style={{ padding: '16px', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px', height: '48px' }}>
                                            <div
                                                style={{
                                                    width: '24px',
                                                    height: '24px',
                                                    borderRadius: '50%',
                                                    backgroundColor: plan.icon?.backgroundColor || 'gray',
                                                    color: plan.icon?.color || 'white',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginRight: '20px'
                                                }}
                                            >
                                                <IconComponent
                                                    key={`plan-icon-${plan.icon?.name}-${plan.icon?.color}`}
                                                    size="medium"
                                                    type={plan.type ?? 'plan'}
                                                    iconSelection={plan.icon}
                                                />
                                            </div>

                                            <div style={{ flex: 1, overflow: 'hidden' }}>
                                                <Typography variant="body1" style={{ fontWeight: 'bold', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis', wordWrap: 'break-word' }}>
                                                    {plan.title}
                                                </Typography>
                                            </div>
                                        </div>

                                        <Typography variant="body2" color="textSecondary" style={{ flexGrow: 1, display: '-webkit-box', WebkitLineClamp: 4, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis', wordWrap: 'break-word' }}>
                                            {plan.description}
                                        </Typography>

                                        <Typography variant="body2" color="textSecondary" style={{ fontWeight: 'bold', paddingTop: '8px' }}>
                                            {sortOption === 'updatedDate' ? formatDate(plan.updatedDate) : formatDate(plan.creationDate)}
                                        </Typography>
                                    </CardContent>
                                </Tooltip>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    );
};

export default AllPlansView;
