import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
  Tabs,
  Tab,
  Box,
  Typography,
  CircularProgress
} from '@mui/material';
import { toast } from 'sonner';

import { PrioritiesSnapshotReference, PrioritiesSnapshotData, PlanSnapshot } from '@/lib/interfaces';
import logger from "@/lib/logger";
import { getFirebaseAnalytics } from '@/lib/hooks/firebase';
import { logEvent } from 'firebase/analytics';
import { StorageManager } from '@/lib/StorageManager';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteIcon from '@mui/icons-material/Delete';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomableImage from './ZoomableImage';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DownloadIcon from '@mui/icons-material/Download';

import { useFirebaseAuthContext } from '@/context/FirebaseAuthContext';

interface SnapshotGalleryProps {
  snapshots: (PrioritiesSnapshotReference | PlanSnapshot)[];
  open: boolean;
  onClose: () => void;
  onDeleteSnapshot: (snapshotId: string) => Promise<void>;
  onViewSnapshot: (snapshot: PrioritiesSnapshotData | PlanSnapshot) => void;
}

type SnapshotReference = PrioritiesSnapshotReference | PlanSnapshot;

const SnapshotGallery: React.FC<SnapshotGalleryProps> = ({
  snapshots,
  open,
  onClose,
  onDeleteSnapshot,
  onViewSnapshot
}) => {
  const { user } = useFirebaseAuthContext();

  const [activeTab, setActiveTab] = useState(0);
  const [groupedSnapshots, setGroupedSnapshots] = useState<{ [key: string]: SnapshotReference[] }>({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [snapshotToDelete, setSnapshotToDelete] = useState<string | null>(null);
  const [currentImageUrl, setCurrentImageUrl] = useState<string | null>(null);

  const storageManager = new StorageManager();

  useEffect(() => {
    groupSnapshots();
  }, [snapshots]);

  const groupSnapshots = () => {
    const grouped = groupSnapshotsByTimeframe(snapshots);
    setGroupedSnapshots(grouped);

    const timeframes = Object.keys(grouped);

    if (timeframes.length > 0) {
      // Select the latest time frame
      const latestTimeFrame = timeframes[timeframes.length - 1];
      const latestSnapshotIndex = grouped[latestTimeFrame].length - 1;

      // Set the active tab to the latest time frame and current index to the last snapshot within that time frame
      setActiveTab(timeframes.indexOf(latestTimeFrame));
      setCurrentIndex(latestSnapshotIndex);
      loadImageUrl(grouped[latestTimeFrame][latestSnapshotIndex]);
    }
  };

  const loadImageUrl = async (snapshot: SnapshotReference) => {
    logger.debug('Loading image url for snapshot: ' + snapshot.id);

    setIsLoading(true);
    try {
      let imageUrl: string;

      if ('url' in snapshot) {
        imageUrl = snapshot.url;
      } else {
        imageUrl = await storageManager.getSnapshotImageUrl(null, snapshot.id);
      }

      logger.debug('Fetched image url: ' + imageUrl);

      setCurrentImageUrl(imageUrl);
    } catch (error) {
      logger.error("Error loading image URL:", error);
      toast.error("Failed to load image");
      setCurrentImageUrl(null);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFullSnapshotData = async (snapshot: SnapshotReference) => {
    setIsLoading(true);
    try {
      let data: PrioritiesSnapshotData | PlanSnapshot;
      if ('url' in snapshot) {
        data = snapshot;
      } else {
        data = await storageManager.get('pri-snapshots', snapshot.id) as PrioritiesSnapshotData;
      }
      onViewSnapshot(data);
    } catch (error) {
      logger.error("Error fetching full snapshot data:", error);
      toast.error("Failed to load snapshot details");
    } finally {
      setIsLoading(false);
    }
  };

  const groupSnapshotsByTimeframe = (snapshots: SnapshotReference[]): { [key: string]: SnapshotReference[] } => {
    return snapshots.reduce((acc, snapshot) => {
      const timeframe = getTimeframeKey(snapshot);
      if (!acc[timeframe]) {
        acc[timeframe] = [];
      }
      acc[timeframe].push(snapshot);
      return acc;
    }, {});
  };

  const getTimeframeKey = (snapshot: SnapshotReference): string => {
    if ('timeRange' in snapshot) {
      const { timeRange } = snapshot;
      if (typeof timeRange === 'string') {
        return timeRange;
      } else if (timeRange && typeof timeRange === 'object' && 'type' in timeRange) {
        return timeRange.type;
      }
    }
    const date = new Date('date' in snapshot ? snapshot.date : snapshot.createdAt);
    return `${date.getFullYear()}-${date.getMonth() + 1}`;
  };

  const timeframes = Object.keys(groupedSnapshots);
  const currentTimeframeSnapshots = groupedSnapshots[timeframes[activeTab]] || [];

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex > 0 ? prevIndex - 1 : currentTimeframeSnapshots.length - 1;
      loadImageUrl(currentTimeframeSnapshots[newIndex]);
      return newIndex;
    });
    logEvent(getFirebaseAnalytics(), 'snapshot_gallery_previous');
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % currentTimeframeSnapshots.length;
      loadImageUrl(currentTimeframeSnapshots[newIndex]);
      return newIndex;
    });
    logEvent(getFirebaseAnalytics(), 'snapshot_gallery_next');
  };

  const handleViewDetails = () => {
    const currentSnapshot = currentTimeframeSnapshots[currentIndex];
    if (currentSnapshot) {
      fetchFullSnapshotData(currentSnapshot);
    }
  };

  const handleDeleteClick = (snapshot: SnapshotReference) => {
    setSnapshotToDelete('id' in snapshot ? snapshot.id : (snapshot as PlanSnapshot).url);
    setConfirmDeleteOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (snapshotToDelete) {
      await onDeleteSnapshot(snapshotToDelete);
      setConfirmDeleteOpen(false);
      setSnapshotToDelete(null);
      groupSnapshots();
    }
  };

  const [scale, setScale] = useState(0.4);
  const minScale = 0.1;
  const maxScale = 2;

  const handleZoomIn = useCallback(() => {
    setScale(prevScale => Math.min(prevScale + 0.1, maxScale));
  }, []);

  const handleZoomOut = useCallback(() => {
    setScale(prevScale => Math.max(prevScale - 0.1, minScale));
  }, []);

  useEffect(() => {
    if (currentTimeframeSnapshots.length > 0) {
      loadImageUrl(currentTimeframeSnapshots[currentIndex]);
    }
  }, [currentIndex, activeTab]);

  const formatDate = (snapshot: SnapshotReference) => {
    const date = new Date('date' in snapshot ? snapshot.date : snapshot.createdAt);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }).format(date);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <Box sx={{ width: '100%', p: 2 }}>
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => {
            setActiveTab(newValue);
            setCurrentIndex(0);
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          {timeframes.map((timeframe, index) => (
            <Tab key={index} label={timeframe} />
          ))}
        </Tabs>

        {currentTimeframeSnapshots.length > 0 ? (
          <Box sx={{ mt: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <IconButton onClick={goToPrevious} disabled={currentTimeframeSnapshots.length <= 1}>
                <ArrowBackIosIcon />
              </IconButton>
              <Typography variant="h6">
                Snapshot {currentIndex + 1} of {currentTimeframeSnapshots.length}
              </Typography>
              <IconButton onClick={goToNext} disabled={currentTimeframeSnapshots.length <= 1}>
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>

            <Box sx={{
              position: 'relative',
              width: '100%',
              height: '400px',
              backgroundColor: 'grey.200',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              {isLoading ? (
                <CircularProgress />
              ) : currentImageUrl ? (
                <ZoomableImage
                  src={currentImageUrl}
                  alt={`Snapshot ${currentIndex + 1}`}
                  scale={scale}
                />
              ) : (
                <Typography variant="body1" color="textSecondary">
                  Failed to load image
                </Typography>
              )}
              <Box sx={{ position: 'absolute', top: 10, right: 10, zIndex: 1 }}>
                <IconButton onClick={handleZoomIn} disabled={scale >= maxScale || !currentImageUrl} size="small">
                  <ZoomInIcon />
                </IconButton>
                <IconButton onClick={handleZoomOut} disabled={scale <= minScale || !currentImageUrl} size="small">
                  <ZoomOutIcon />
                </IconButton>

                {currentImageUrl && (
                  <>
                    <Tooltip title="Open in New Tab">
                      <IconButton
                        size="small"
                        component="a"
                        href={currentImageUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <OpenInNewIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Save Image">
                      <IconButton
                        size="small"
                        onClick={() => storageManager.downloadImage(currentImageUrl, currentTimeframeSnapshots[currentIndex].id)}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </Box>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
              <Typography variant="body2">
                {formatDate(currentTimeframeSnapshots[currentIndex])}
              </Typography>
              <Box>
                <Button onClick={handleViewDetails} disabled={isLoading} hidden={true}>
                  View Details
                </Button>
                <Tooltip title="Delete this snapshot">
                  <IconButton onClick={() => handleDeleteClick(currentTimeframeSnapshots[currentIndex])}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        ) : (
          <Typography sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}>No snapshots available for this timeframe.</Typography>
        )}

        <Dialog
          open={confirmDeleteOpen}
          onClose={() => setConfirmDeleteOpen(false)}
        >
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this snapshot?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmDeleteOpen(false)}>Cancel</Button>
            <Button onClick={handleConfirmDelete} color="error">Delete</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Dialog>
  );
};

export default SnapshotGallery;
