import React from 'react';
import {
    Dialog,
    DialogContent,
    CircularProgress,
    LinearProgress,
    Typography,
    Box
} from '@mui/material';

function ProgressDialog({ open, status, progress }) {
    return (
        <Dialog open={open} aria-labelledby="progress-dialog-title" PaperProps={{
            style: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px', // Adds padding around the dialog content
            }
        }}>
            <DialogContent>
                <Box
                    display="flex"
                    justifyContent="center"
                    height="30vh"
                    width="40vh"
                    gap={3}
                    flexDirection="column"
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        width="100%">
                        <CircularProgress size={68} />
                    </Box>

                    <Box mt={4}> {/* Adjust margin-top as needed */}
                        <Typography variant="body1" align="left" color="GrayText" style={{ fontSize: '1.25rem' }} >
                            {status}...
                        </Typography>
                    </Box>

                    {/* Overall plan progress bar and percentage shown in the same row */}
                    <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
                        <Box sx={{ width: '100%', mr: 1 }}>
                            <LinearProgress variant="determinate" value={Math.round(progress)} />
                        </Box>
                        <Box sx={{ minWidth: 35 }}>
                            <Box sx={{ textAlign: 'center' }}>{`${Math.round(progress)}%`}</Box>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default ProgressDialog;
