"use client";

import React, { useEffect, useMemo, useState } from 'react';

import { FolderInfo, PlanDefinition, PlanInfo, PlanMenuInfo, TimelineMilestoneData } from "@/lib/interfaces";
import { flattenPlans } from '@/lib/utils';
import PlanEditor from "@/ui/components/PlanEditor";
import PlanList from "@/ui/components/PlanList";
import AuthControls from '@/ui/components/AuthControls';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { StorageManager } from '@/lib/StorageManager';
import dynamic from 'next/dynamic';
import logger from "@/lib/logger";
import { getFirebaseAnalytics } from '@/lib/hooks/firebase';
import { logEvent } from 'firebase/analytics';

const FirebaseAuthWithNoSSR = dynamic(
  () => import('@/ui/components/FirebaseAuth'),
  { ssr: false }
);

import { useFirebaseAuthContext } from '@/context/FirebaseAuthContext';
import useSubscription from '@/lib/hooks/useSubscription';
import { usePlans } from '@/context/PlansContext';

import NewPlanDialog from '@/ui/components/NewPlanDialog';
import StripePlansDialog from '@/ui/components/StripePlansDialog';
import { sendEmailMessageForViewPlan } from '@/ui/components/EmailMessage';
import LoadingIndicator from './components/LoadingIndicator';
import { Button, Backdrop, Typography, Box } from '@mui/material';
import { useNetworkStatus } from '@/lib/hooks/useNetworkStatus';
import AllPlansView from '@/ui/components/AllPlansView';
import TimelineView from '@/ui/components/TimelineView';
import ItemDragLayer from '@/ui/components/ItemDragLayer';

function Page() {
  const { user, isUserLoading, userError } = useFirebaseAuthContext();
  const isOnline = useNetworkStatus();
  const { planUsage } = useSubscription(user?.uid);

  const {
    plans,
    setPlans,
    selectedPlan,
    setSelectedPlan,
    setSelectedMilestoneId,
    isFetchingPlans,
    fetchPlansError,
    createPlan,
  } = usePlans();

  const [initialMilestonesByPlan, setInitialMilestonesByPlan] = useState<{ [planId: string]: TimelineMilestoneData[] }>({});
  const [isCacheLoading, setIsCacheLoading] = useState(true);

  const resetState = () => {
    setPlans(null);
    setSelectedPlan(null);
    setInitialMilestonesByPlan({});
    setIsCacheLoading(true);
    setFlatPlans([]);

    localStorage.clear();
  };

  useEffect(() => {
    if (!isUserLoading && !user) {
      logger.debug('User is not logged in');
    }

    if (!isUserLoading && user) {
      logger.debug(`Logged in as user: ${user.uid}`);
    }
  }, [user, isUserLoading]);

  useEffect(() => {
    const fetchData = async () => {
      if (!user || selectedPlan?.id !== 'timeline') {
        return;
      }

      const userId = user.uid;

      try {
        const response = await fetch(`/api/milestonesByPlan?userId=${userId}`);

        if (!response.ok) {
          throw new Error(`Error fetching data: ${response.statusText}`);
        }

        const data = await response.json();
        setInitialMilestonesByPlan(data.milestonesByPlan);
      } catch (err) {
        logger.error('Error fetching data. Please try again.');
      } finally {
        setIsCacheLoading(false);
      }
    };

    fetchData();
  }, [user, selectedPlan]);

  const storageManager = useMemo(() => {
    return new StorageManager();
  }, []);

  const [stripeDialogOpen, setStripeDialogOpen] = useState(false);

  const handleStripeDialogClose = () => {
    setStripeDialogOpen(false);
  };

  const handleStripeDialogOpen = () => {
    setStripeDialogOpen(true);
  };

  const [isNewPlanDialogOpen, setIsNewPlanDialogOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState<FolderInfo | null>(null);

  const handleOpenNewPlanDialog = (folder: FolderInfo = null) => {
    setSelectedFolder(folder);
    setIsNewPlanDialogOpen(true);
  };

  const handleCloseNewPlanDialog = () => {
    setIsNewPlanDialogOpen(false);
  };

  const handleViewSubscriptionPlans = (source) => {
    logger.debug('handleViewSubscriptionPlans');
    logEvent(getFirebaseAnalytics(), 'view_subscription_plans');

    sendEmailMessageForViewPlan(user, source);

    handleCloseNewPlanDialog();
    handleStripeDialogOpen();
  }

  const handleCreateNewPlan = async (planDefinition: PlanDefinition) => {
    logger.debug('handleAddPlan', planDefinition);

    planDefinition.events = planDefinition.events.map((event, index) =>
      ({ ...event, collapsed: index !== 0 })
    );

    var newPlan = await createPlan(planDefinition, selectedFolder);
    handleSelectPlan(newPlan);

    await updateUserPlanUsage(user.uid, planDefinition.init.mode, planUsage);
  };

  const updateUserPlanUsage = async (userId, planType, currentPlanUsage) => {
    const planUsageKey = planType === 'blank' ? 'blank' : planType === 'template' ? 'template' : 'ai';
    const updatedPlanUsage = {
      ...currentPlanUsage,
      [planUsageKey]: (currentPlanUsage[planUsageKey] || 0) + 1
    };

    await storageManager.set('users', userId, updatedPlanUsage, 'planUsage');
  }; ``

  const [flatPlans, setFlatPlans] = useState<PlanInfo[]>([]);

  useEffect(() => {
    const flatPlans = flattenPlans(plans);
    setFlatPlans(flatPlans);
  }, [plans]);


  useEffect(() => {
    if (selectedPlan) {
      logger.debug('Selected Plan has changed in Page:', selectedPlan);
    }
  }, [selectedPlan]);

  const handleSelectPlan = (planOrFolder: PlanMenuInfo) => {
    logger.debug('Plan Selected', planOrFolder);

    setSelectedPlan(planOrFolder);

    logEvent(getFirebaseAnalytics(), 'plan_selected');
  };

  if (userError) {
    return <div>Error: {userError.message}</div>;
  }

  if (fetchPlansError) {
    return <div>Error: {fetchPlansError.message}</div>;
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center w-full">
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!isOnline}
      >
        <Box display="flex" flexDirection="column" alignItems="center" p={2}>
          <Typography variant="h5" color="common.white">
            You are offline
          </Typography>
          <Typography variant="subtitle1" color="common.white">
            Please check your internet connection.
          </Typography>
        </Box>
      </Backdrop>

      {isUserLoading || isFetchingPlans ? (
        <LoadingIndicator />
      ) : !user ? (
        <FirebaseAuthWithNoSSR />
      ) : (
        <DndProvider backend={HTML5Backend}>
          <div className={`flex w-full`}>

            <div className="top-right flex items-center space-x-4">
              <AuthControls onLogout={resetState} onViewPlans={() => handleViewSubscriptionPlans('UserMenu')} />
            </div>

            <div className="flex min-h-screen">

              <PlanList
                onSelectPlan={handleSelectPlan}
                onOpenNewPlanDialog={handleOpenNewPlanDialog}
              />
            </div>

            <div key={`${selectedPlan?.id}`} className={`min-h-screen flex-grow flex justify-center items-start p-4`}>
              {
                selectedPlan && selectedPlan.id === 'all_plans' ? (
                  <AllPlansView
                    flatPlans={flatPlans}
                    onSelectPlan={handleSelectPlan}
                  />
                ) : selectedPlan && selectedPlan.id === 'timeline' ? (
                  <TimelineView
                    initialMilestonesByPlan={initialMilestonesByPlan}
                    onSelectPlan={handleSelectPlan}
                    onSelectMilestone={
                      (plan, milestone) => {
                        logger.debug('Selected milestone:', milestone);

                        setSelectedMilestoneId(milestone.id)
                        handleSelectPlan(plan);
                      }
                    }
                    isLoading={isCacheLoading}
                  />
                ) :
                  plans && plans.length === 0 ? (
                    <div className="text-center p-4 max-w-2xl m-auto"> {/* Increased max-width */}
                      <h2 className="text-3xl font-semibold mb-4">Welcome to Plan Quest!</h2>

                      <div className="text-l mt-6 mb-6 text-left leading-relaxed"> {/* Left-aligned text with increased line spacing */}
                        This is the place where <strong>your vision takes form</strong>, through strategic and insightful planning:
                        <ul className="list-disc text-left ml-6"> {/* Bullet points for clarity */}
                          <li><span role="img" aria-label="checkmark">🎯</span> Create a plan on a <strong>timeline</strong>, with one or more objectives (OKRs).</li>
                          <li><span role="img" aria-label="checkmark">🔍</span> Add <strong>measurable outcomes</strong> (key results) to each objective.</li>
                          <li><span role="img" aria-label="sparkles">📊</span> Bring your key results to life with <strong>progress tracking</strong>.</li>
                          <li><span role="img" aria-label="sparkles">📝 </span> Per objective or key result, you can take detailed notes and <strong>track tasks</strong>.</li>
                          <li><span role="img" aria-label="camera">💭</span> Over time, <strong>reflect</strong> on your journey.</li>
                          <li><span role="img" aria-label="camera">📸</span> Snapshot your plan to <strong>see the evolution of your vision, execution and mindset</strong>.</li>
                        </ul>
                      </div>

                      <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{
                          width: "auto",
                          padding: "10px 20px",
                          margin: "20px 0",
                          fontSize: "1rem",
                          fontWeight: "bold",
                        }}
                        onClick={() => handleOpenNewPlanDialog()}>
                        Create Your First Plan
                      </Button>
                    </div>
                  )
                    :
                    (
                      selectedPlan && selectedPlan.id !== 'all_plans' && (
                        <PlanEditor
                          key={`plan-editor-${selectedPlan.id}`}
                        />
                      )
                    )
              }

            </div>

          </div>
          <ItemDragLayer />
        </DndProvider>
      )}

      <NewPlanDialog
        isOpen={isNewPlanDialogOpen}
        onClose={handleCloseNewPlanDialog}
        onCreate={handleCreateNewPlan}
        onViewPlans={() => handleViewSubscriptionPlans('NewPlanDialog')}
      />

      <StripePlansDialog
        open={stripeDialogOpen}
        onClose={handleStripeDialogClose}
        user={user}
      />

    </div>
  );
}

export default Page;
