import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Box, Typography } from '@mui/material';

interface ZoomableImageProps {
  src: string;
  alt: string;
  scale: number;
}

const ZoomableImage: React.FC<ZoomableImageProps> = ({ src, alt, scale }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [imageError, setImageError] = useState<string | null>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    setDragging(true);
    setDragStart({ x: e.clientX - position.x, y: e.clientY - position.y });
  }, [position]);

  const handleMouseMove = useCallback((e: React.MouseEvent) => {
    if (dragging && scale > 1) {
      const newX = e.clientX - dragStart.x;
      const newY = e.clientY - dragStart.y;
      setPosition({ x: newX, y: newY });
    }
  }, [dragging, scale, dragStart]);

  const handleMouseUp = useCallback(() => {
    setDragging(false);
  }, []);

  const handleWheel = useCallback((e: React.WheelEvent) => {
    e.preventDefault();
    setPosition(prevPosition => ({
      x: prevPosition.x - e.deltaX,
      y: prevPosition.y - e.deltaY
    }));
  }, []);

  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    console.error("Image failed to load:", e);
    setImageError("Failed to load image. Please try again later.");
  };

  const handleImageLoad = useCallback(() => {
    if (imgRef.current) {
      const { naturalWidth, naturalHeight } = imgRef.current;
      imgRef.current.style.width = `${naturalWidth * scale}px`;
      imgRef.current.style.height = `${naturalHeight * scale}px`;
      setPosition({ x: 0, y: 0 }); // Reset position after loading
    }
  }, [scale]);

  useEffect(() => {
    if (imgRef.current) {
      handleImageLoad();
    }
  }, [scale, src, handleImageLoad]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'auto',
        cursor: scale > 1 ? 'move' : 'default',
        userSelect: 'none',
        position: 'relative',
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onWheel={handleWheel}
    >
      {imageError ? (
        <Typography color="error">{imageError}</Typography>
      ) : (
        <img
          ref={imgRef}
          src={src}
          alt={alt}
          onError={handleImageError}
          onLoad={handleImageLoad}
          style={{
            objectFit: 'cover',
            transform: `translate(${position.x}px, ${position.y}px)`,
            transition: dragging ? 'none' : 'transform 0.1s',
            display: 'block',
            maxWidth: 'none',
            opacity: 1,
          }}
          draggable={false}
        />
      )}
    </Box>
  );
};

export default ZoomableImage;
