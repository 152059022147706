import React, { useState, useContext, useRef, useEffect, useCallback } from 'react';
import { AppContext } from "@/app/providers";
import { useTheme } from "next-themes";

import {
    IconButton, Typography, Grid, Tooltip, Menu, MenuItem, ListItemIcon, Divider,
    Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText,
    FormControlLabel, Checkbox, Box, TextField, Link, LinearProgress,
    Popover,
} from '@mui/material';

import { getFirebaseAnalytics } from '@/lib/hooks/firebase';
import { logEvent } from 'firebase/analytics';

import { DragItemTypes, IconSelectionInfo, OKRData, PlanInfo, PlanMilestoneData, ProgressInfo } from '@/lib/interfaces';
import OKREditor from './OKREditor';
import { calculateObjectiveProgress, debounce, describeTimeRange, formatDateButtonText, getProgressStatusColor, getTimeElapsed, getTimeProgress, getTimeRemaining } from '@/lib/utils';

import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import UpdateIcon from '@mui/icons-material/Update';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import FilterListIcon from '@mui/icons-material/FilterList';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';


import logger from "@/lib/logger";
import { toast } from "sonner";

import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

interface DragItem {
    id: string;
    type?: string;
    index?: number;
}

interface DraggableMilestoneProps {
    milestone: PlanMilestoneData;
    index: number;
    okr: OKRData;
    plan: PlanInfo;
    onMilestoneChange: (milestone: PlanMilestoneData) => void;
    onMilestoneAutoUpdateTimeRange: (milestone: PlanMilestoneData, index: number) => void;
    onAddMilestone: (index: number) => void;
    onDeleteMilestone: (index: number) => void;
    showDeleteIcon: boolean;
    moveMilestoneWithinPlan: (id: string, targetId: string, dragIndex: number, hoverIndex: number) => void;
    onMoveMilestoneToNewPlan: (event: React.MouseEvent, milestone: PlanMilestoneData, action: string) => void;
    showMoveIcon: boolean;
    onDuplicateMilestone: (index: number) => void;
    onClearKeyResultsConfirmation: (milestone: PlanMilestoneData) => void;
    shouldPropagateToOKREditor: number;
    handleSaveOKR: (okr: OKRData) => void;
    handleOpenEditorForOKR: (sourceType, sourceId, sourceIndex) => void;
    handleMoveKRToAnotherOKR: (sourceOkrId, targetOkrId, dragIndex, hoverIndex) => void;
    updateKeyResultsFilterForMilestone: (milestone: PlanMilestoneData, filterOptions: { [key: string]: boolean }) => void;
    iconSelections: { [key: string]: IconSelectionInfo };
}

const DraggableMilestone: React.FC<DraggableMilestoneProps> = ({
    milestone, index, okr, plan, onMilestoneChange, onMilestoneAutoUpdateTimeRange, onAddMilestone,
    onDeleteMilestone, showDeleteIcon, moveMilestoneWithinPlan, onMoveMilestoneToNewPlan, showMoveIcon,
    onDuplicateMilestone, onClearKeyResultsConfirmation,
    shouldPropagateToOKREditor, handleSaveOKR, handleOpenEditorForOKR: handleOpenEditor, handleMoveKRToAnotherOKR,
    updateKeyResultsFilterForMilestone, iconSelections
}) => {
    const { isMobile } = useContext(AppContext);

    const { theme, systemTheme } = useTheme();
    const effectiveTheme = theme === 'system' ? (systemTheme) : theme;

    const toggleCollapse = () => {
        milestone.collapsed = !milestone.collapsed;
        onMilestoneChange(milestone);
    };

    const [milestoneMenuAnchorEl, setMilestoneMenuAnchorEl] = useState(null);

    const handleMilestoneMenuOpen = (event) => {
        logger.debug("Opening milestone menu for ", milestone.id, index);

        setMilestoneMenuAnchorEl(event.currentTarget);
    };

    const handleMilestoneMenuClose = () => {
        closeMilestoneMenu();
    };

    const closeMilestoneMenu = () => {
        setMilestoneMenuAnchorEl(null);
    };

    const [start, setStart] = useState<Dayjs | null>(null);
    const [end, setEnd] = useState<Dayjs | null>(null);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [openDatePicker, setOpenDatePicker] = useState<'start' | 'end' | null>(null);
    const [isDateRangeValid, setIsDateRangeValid] = useState(true);

    useEffect(() => {
        if (milestone) {
            setStart(dayjs(milestone.startDate));
            setEnd(dayjs(milestone.endDate));
        }
    }, [milestone]);

    useEffect(() => {
        if (start && end) {
            setIsDateRangeValid(end.isSame(start, 'day') || end.isAfter(start, 'day'));
        }
    }, [start, end]);

    const handleDateButtonClick = (event: React.MouseEvent<HTMLButtonElement>, dateType: 'start' | 'end') => {
        setAnchorEl(event.currentTarget);
        setOpenDatePicker(dateType);
    };

    const handleCloseDatePicker = () => {
        setAnchorEl(null);
        setOpenDatePicker(null);
    };

    const handleDateChange = (newDate: Dayjs | null) => {
        if (openDatePicker === 'start') {
            setStart(newDate);
            updateMilestoneTimeRange(newDate?.toISOString() || '', end?.toISOString() || '');
        } else if (openDatePicker === 'end') {
            setEnd(newDate);
            updateMilestoneTimeRange(start?.toISOString() || '', newDate?.toISOString() || '');
        }
        handleCloseDatePicker();
    };

    const updateMilestoneTimeRange = (startDate, endDate) => {
        milestone.startDate = startDate;
        milestone.endDate = endDate;
        milestone.description = describeTimeRange(startDate, endDate);

        onMilestoneChange(milestone);

        logger.debug(`Updated milestone ${index + 1} with new range: ${startDate} to ${endDate}`);
    };

    const getDateArrowTooltipContent = () => {
        if (!isDateRangeValid) {
            return (
                <Box
                    sx={{
                        backgroundColor: '#ffebee',
                        padding: '8px',
                        borderRadius: '4px',
                    }}
                >
                    <Typography variant="body2" color="error">
                        <ErrorOutlineIcon fontSize="small" style={{ verticalAlign: 'middle', marginRight: '4px' }} />
                        Invalid date range: End date must be after start date.
                    </Typography>
                    <br />
                    <Typography variant="body2" color="error">
                        Please adjust the dates to create a valid range.
                    </Typography>
                </Box>
            );
        }

        return (
            <Box
                sx={{
                    padding: '8px',
                    borderRadius: '4px',
                }}
            >
                <Typography variant="body2"><b>Time Remaining:</b> {getTimeRemaining(milestone.startDate, milestone.endDate)}</Typography>
                <Typography variant="body2"><b>Time Elapsed:</b> {getTimeElapsed(milestone.startDate, milestone.endDate)} ({Math.round(getTimeProgress(milestone.startDate, milestone.endDate))}%)</Typography>
            </Box>
        );
    };

    const handleMilestoneAddBelow = () => {
        closeMilestoneMenu();

        onAddMilestone(index);

        toast.message('Objective added below');
    };

    const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);

    const handleMilestoneDelete = () => {
        closeMilestoneMenu();
        setDeleteConfirmDialog(true);
    };

    const deleteMilestone = () => {
        onDeleteMilestone(index);
        setDeleteConfirmDialog(false);

        toast.message('Objective deleted');
    };

    const handleMoveMilestoneToNewPlan = (event, action) => {
        closeMilestoneMenu();

        onMoveMilestoneToNewPlan(event, milestone, action);
    };

    const handleDuplicateMilestone = () => {
        closeMilestoneMenu();

        onDuplicateMilestone(index);

        toast.message('Objective duplicated');
    };

    const [clearKeyResultsDialogOpen, setClearKeyResultsDialogOpen] = useState(false);

    const handleClearKeyResultsConfirmation = () => {
        closeMilestoneMenu();

        setClearKeyResultsDialogOpen(true);
    };

    const clearKeyResultsForMilestone = () => {
        onClearKeyResultsConfirmation(milestone);

        setClearKeyResultsDialogOpen(false);
        toast.message('Key results cleared');
    }

    const [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState(null);
    const [filterOptions, setFilterOptions] = useState({
        notStarted: okr.filterSettings?.notStarted ?? true,
        inProgress: okr.filterSettings?.inProgress ?? true,
        completed: okr.filterSettings?.completed ?? true,
        scratched: okr.filterSettings?.scratched ?? true
    });

    const handleFilterMenuToggle = (event) => {
        setFilterMenuAnchorEl(event.currentTarget);
    };

    const closeFilterMenu = () => {
        setFilterMenuAnchorEl(null);
    };

    const handleFilterOptionChange = (option) => {
        setFilterOptions(prev => {
            const updatedFilters = {
                ...prev,
                [option]: !prev[option]
            };

            updateKeyResultsFilterForMilestone(milestone, updatedFilters);

            return updatedFilters;
        });
    };

    const formatLabel = (key) => {
        // Insert a space before each uppercase letter (except the first) and capitalize the first letter
        return key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
    };

    const [objective, setObjective] = useState<string>(okr.objective || '');
    // Use useEffect to update objective whenever okr.objective changes
    useEffect(() => {
        if (okr?.objective) {
            setObjective(okr.objective);
        }

        if (milestone) {
            setStart(dayjs(milestone.startDate));
            setEnd(dayjs(milestone.endDate));
        }
    }, [okr, milestone, shouldPropagateToOKREditor]);

    const [objectiveProgress, setObjectiveProgress] = useState(0);
    
    const saveOKRRef = useRef(debounce((newData: OKRData) => {
        handleSaveOKR(newData);
    }, 1000));

    useEffect(() => {
        if (okr?.keyResults) {
            const progress = calculateObjectiveProgress(okr.keyResults);

            setObjectiveProgress(progress);
        }
    }, [okr?.keyResults]);

    // Update the objective in the state and call onSaveOKR
    const handleObjectiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newDescription = event.target.value;
        setObjective(newDescription);

        okr.objective = newDescription;
        saveOKRRef.current(okr);

        logEvent(getFirebaseAnalytics(), 'objective_change', {
            objective_length: newDescription.length
        });
    };

    const handleUpdateKeyResults = (newKeyResults) => {
        okr.keyResults = newKeyResults;
        saveOKRRef.current(okr);
    }

    const ref = useRef(null);

    const [{ isDragging }, drag, preview] = useDrag({
        type: DragItemTypes.MILESTONE,
        item: { id: milestone.id, index, objectiveText: objective, iconSelections, width: ref.current?.clientWidth },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item: DragItem | null, monitor) => {
            const dropResult = monitor.getDropResult();

            if (item && dropResult) {
                const { id, index: dragIndex } = item;

                const { targetId, hoverIndex } = dropResult as { targetId: string; hoverIndex: number };

                if (id !== targetId || dragIndex !== hoverIndex) {
                    moveMilestoneWithinPlan(id, targetId, dragIndex, hoverIndex);
                }
            }
        },
    });

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);

    const [{ isOver }, drop] = useDrop({
        accept: DragItemTypes.MILESTONE,
        hover(item: DragItem) {
            if (!ref.current) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;
            const targetMilestoneId = milestone.id;

            logger.debug('Drag Index:', dragIndex, 'Hover Index:', hoverIndex, 'Target Milestone ID:', targetMilestoneId, 'Source Milestone ID:', item.id);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
        drop: () => ({
            targetId: milestone.id,
            hoverIndex: index,
        }),
    });

    drag(drop(ref));

    const dragHandleRef = useCallback((node) => {
        drag(node);
    }, [drag]);

    return (
        <div
            style={{
                border: isOver ? '2px dashed #1976d2' : '1px solid',
                opacity: isDragging ? 0.5 : 1,
            }}
            className={`flex flex-col items-center justify-center rounded w-full h-full`}
        >
            <div
                ref={ref}
                className={`flex flex-col justify-center items-center rounded w-full p-1`}>

                <div className="relative w-full flex items-start">
                    <div className="flex flex-col items-center pr-2">
                        <Tooltip title={milestone.collapsed === true ? "Expand" : "Collapse"} placement='top'>
                            <IconButton
                                className="screenshot-show text-stone-500"
                                onClick={() => toggleCollapse()}
                            >
                                {milestone.collapsed === true ? (
                                    <ExpandMoreOutlinedIcon />
                                ) : (
                                    <ExpandLessOutlinedIcon />
                                )}
                            </IconButton>
                        </Tooltip>

                        <div className="flex items-center">
                            <div
                                ref={dragHandleRef}
                                style={{
                                    cursor: 'grab',
                                    color: 'gray',
                                    transition: 'color 0.3s',
                                    display: 'flex',
                                }}
                                className='screenshot-hide'
                                onMouseOver={(e) => e.currentTarget.style.color = '#1976d2'}
                                onMouseOut={(e) => e.currentTarget.style.color = 'gray'}
                            >
                                <Tooltip title="Drag to reorder" placement='bottom'>
                                    <DragIndicatorIcon fontSize="small" />
                                </Tooltip>
                            </div>
                        </div>
                    </div>

                    <Box flexGrow={1} px={2} className="flex flex-col items-center">
                        <TextField
                            fullWidth
                            variant="outlined"
                            key={`${okr.id}-objective`}
                            label={
                                <Box display="flex" alignItems="center">
                                    {objective && objective.trim() !== '' ? (
                                        <>
                                            Objective
                                            <Tooltip
                                                title={
                                                    <React.Fragment>
                                                        Define a clear and achievable objective. E.g., &apos;Increase website traffic by 20%&apos;.
                                                        For more info, see
                                                        <Link
                                                            href="https://scaledagileframework.com/okrs/"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            style={{ marginLeft: '4px', color: 'blue' }}
                                                        >
                                                            this guide
                                                        </Link>.
                                                    </React.Fragment>
                                                }
                                            >
                                                <InfoOutlinedIcon fontSize="small" style={{ marginLeft: 4, marginBottom: 1 }} />
                                            </Tooltip>
                                        </>
                                    ) : (
                                        "What objective are you trying to achieve?"
                                    )}
                                </Box>
                            }
                            value={objective}
                            size="small"
                            onChange={handleObjectiveChange}
                            InputLabelProps={{
                                className: 'dark:text-gray-100',
                                style: { fontWeight: 'bold' }
                            }}
                            InputProps={{
                                className: 'bg-gray-50 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-600',
                                style: { fontWeight: 'bold' }
                            }}
                            margin="normal"
                            multiline
                        />

                        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', mb: 1 }}>
                            <Box sx={{ width: '100%', mr: 1, mt: 1 }}>
                                <LinearProgress
                                    variant="determinate"
                                    value={Math.round(objectiveProgress)}
                                    sx={{
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: getProgressStatusColor({
                                                startDate: milestone.startDate,
                                                endDate: milestone.endDate,
                                                progress: okr ? calculateObjectiveProgress(okr.keyResults) : 0,
                                            } as ProgressInfo, effectiveTheme)
                                        }, height: '5px', borderRadius: '5px'
                                    }}
                                />
                                <Box sx={{ alignContent: 'center', paddingTop: '8px' }}>
                                    <Grid container alignItems="center" justifyContent="center" spacing={1}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Grid item>
                                                <Button
                                                    variant="text"
                                                    className='screenshot-show'
                                                    onClick={(e) => handleDateButtonClick(e, 'start')}
                                                    style={{
                                                        justifyContent: 'flex-end',
                                                        padding: '0px',
                                                        margin: '0px',
                                                    }}
                                                >
                                                    {formatDateButtonText(start)}
                                                </Button>
                                                <Popover
                                                    open={openDatePicker === 'start'}
                                                    anchorEl={anchorEl}
                                                    onClose={handleCloseDatePicker}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                >
                                                    <DateCalendar
                                                        value={start}
                                                        onChange={handleDateChange}
                                                    />
                                                </Popover>
                                            </Grid>
                                            <Grid item>
                                                <Tooltip
                                                    title={getDateArrowTooltipContent()}
                                                    placement="bottom"
                                                    componentsProps={{
                                                        tooltip: {
                                                            sx: {
                                                                bgcolor: `${isDateRangeValid ? '' : 'transparent'}`,
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <ArrowForwardIcon
                                                        fontSize="small"
                                                        className={`${isDateRangeValid ? 'text-stone-500' : 'text-red-500'}`}
                                                    />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="text"
                                                    className='screenshot-show'
                                                    onClick={(e) => handleDateButtonClick(e, 'end')}
                                                    style={{
                                                        justifyContent: 'flex-start',
                                                        padding: '0px',
                                                        margin: '0px',
                                                    }}
                                                >
                                                    {formatDateButtonText(end)}
                                                </Button>
                                                <Popover
                                                    open={openDatePicker === 'end'}
                                                    anchorEl={anchorEl}
                                                    onClose={handleCloseDatePicker}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                >
                                                    <DateCalendar
                                                        value={end}
                                                        onChange={handleDateChange}
                                                    />
                                                </Popover>
                                            </Grid>
                                        </LocalizationProvider>
                                    </Grid>
                                </Box>
                            </Box>
                            <Box sx={{ minWidth: 35, textAlign: 'right' }}>
                                <Box>{`${Math.round(objectiveProgress)}%`}</Box>
                            </Box>
                        </Box>
                    </Box>

                    <div className="flex flex-col items-center pr-2">
                        <Tooltip title="Objective Options" placement='top'>
                            <IconButton
                                onClick={(e) => handleMilestoneMenuOpen(e)}
                                className="text-stone-500"
                            >
                                <MoreHorizOutlinedIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Filter Key Results" placement='bottom'>
                            <IconButton
                                className="text-stone-500"
                                onClick={handleFilterMenuToggle}
                            >
                                <FilterListIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Adjust Time Range Automatically">
                            <IconButton
                                className="text-stone-500"
                                onClick={() => onMilestoneAutoUpdateTimeRange(milestone, index)}
                            >
                                <UpdateIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </div>

            {!(milestone.collapsed === true) && (
                <div className="relative w-full items-center" style={{ minWidth: isMobile ? 'initial' : '400px', marginBottom: '0px' }}>
                    <div className='pl-2 pr-3 w-full flex justify-center items-center'>
                        <OKREditor
                            key={'okr-' + milestone.id + '-' + (shouldPropagateToOKREditor || 0)}
                            plan={plan}
                            okrData={okr}
                            onUpdateKeyResults={handleUpdateKeyResults}
                            onOpenEditor={handleOpenEditor}
                            onMoveKRToAnotherOKR={handleMoveKRToAnotherOKR}
                            filterOptions={filterOptions}
                        />
                    </div>
                </div>
            )}

            <Menu
                key={`milestone-menu-${index}`}
                anchorEl={milestoneMenuAnchorEl}
                open={Boolean(milestoneMenuAnchorEl)}
                onClose={handleMilestoneMenuClose}
            >
                <MenuItem onClick={() => handleMilestoneAddBelow()}>
                    <ListItemIcon>
                        <AddCircleOutlineIcon fontSize="small" />
                    </ListItemIcon>
                    Add Objective Below
                </MenuItem>

                <MenuItem onClick={handleDuplicateMilestone}>
                    <ListItemIcon>
                        <FileCopyIcon fontSize="small" />
                    </ListItemIcon>
                    Duplicate Objective
                </MenuItem>

                {showMoveIcon && (
                    <MenuItem onClick={(event) => handleMoveMilestoneToNewPlan(event, 'move')}>
                        <ListItemIcon>
                            <SubdirectoryArrowRightIcon fontSize="small" />
                        </ListItemIcon>
                        Move to New Plan
                    </MenuItem>
                )}

                <Divider />

                {showDeleteIcon && (
                    <MenuItem onClick={() => handleMilestoneDelete()}>
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        Delete this Objective
                    </MenuItem>
                )}

                <MenuItem onClick={() => handleClearKeyResultsConfirmation()}>
                    <ListItemIcon>
                        <ClearAllIcon fontSize="small" />
                    </ListItemIcon>
                    Delete All Key Results
                </MenuItem>
            </Menu>

            <Menu
                anchorEl={filterMenuAnchorEl}
                open={Boolean(filterMenuAnchorEl)}
                onClose={closeFilterMenu}
            >
                {Object.keys(filterOptions).map((key) => (
                    <MenuItem key={key} style={{ padding: '0px 16px' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filterOptions[key]}
                                    onChange={() => handleFilterOptionChange(key)}
                                />
                            }
                            label={formatLabel(key)}
                        />
                    </MenuItem>
                ))}
            </Menu>

            <Dialog open={deleteConfirmDialog} onClose={() => setDeleteConfirmDialog(false)}>
                <DialogTitle>{"Are you sure you want to delete this milestone?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={() => setDeleteConfirmDialog(false)}>Cancel</Button>
                    <Button onClick={deleteMilestone} autoFocus>Delete</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={clearKeyResultsDialogOpen}
                onClose={() => setClearKeyResultsDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Clear Key Results?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This will remove all key results for this milestone. This action cannot be undone. Are you sure you want to proceed?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setClearKeyResultsDialogOpen(false)}>Cancel</Button>
                    <Button onClick={clearKeyResultsForMilestone} autoFocus>
                        Clear
                    </Button>
                </DialogActions>
            </Dialog>
        </div>

    );
};

export default DraggableMilestone;
