"use client";

import React, { useState, useEffect } from 'react';

import { getFirebaseAnalytics } from '@/lib/hooks/firebase';
import { logEvent } from 'firebase/analytics';

const EditableTitle = ({ initialTitle, onSave }) => {
  const [isEditMode, setEditMode] = useState(false);
  const [title, setTitle] = useState(initialTitle);

  useEffect(() => {
    setTitle(initialTitle);
  }, [initialTitle]);

  const handleClick = () => {
    setEditMode(true);

    // Log event for entering edit mode
    logEvent(getFirebaseAnalytics(), 'enter_edit_mode_title');
  };

  const handleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleBlur = () => {
    if (isEditMode) {
      setEditMode(false);
      onSave(title);

      // Log event for saving title
      logEvent(getFirebaseAnalytics(), 'save_title', {
        title_length: title.length
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setEditMode(false);
      onSave(title);

      // Log event for saving title with enter key
      logEvent(getFirebaseAnalytics(), 'save_title_enter_key', {
        title_length: title.length
      });
    }
  };

  return (
    <div className="w-full">
      {isEditMode ? (
        <input
          type="text"
          value={title}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          className="text-3xl font-bold bg-transparent border-b-2 border-blue-500 focus:outline-none w-full"
          autoFocus
        />
      ) : (
        <h1
          onClick={handleClick}
          onMouseEnter={(e) => (e.currentTarget.style.borderBottom = '2px solid lightgray')}
          onMouseLeave={(e) => (e.currentTarget.style.borderBottom = 'none')}
          className="text-3xl font-bold cursor-pointer"
        >
          {title}
        </h1>
      )}
    </div>
  );
};

export default EditableTitle;
