// CreateFolderDialog.jsx
import React, { useState } from 'react';
import { Typography, Popover, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Tooltip } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { FolderInfo, IconSelectionInfo } from '@/lib/interfaces';
import { IconsGrid } from '@/lib/IconUtils';
import { toast } from 'sonner';


import IconComponent from './IconComponent';

function CreateFolderDialog({ isOpen, onClose, onCreate }) {
    const [folderName, setFolderName] = useState('');
    const [folderIcon, setFolderIcon] = useState<IconSelectionInfo>(null);
    const [isIconPickerOpen, setIsIconPickerOpen] = useState(false);
    const [anchorIconEl, setAnchorIconEl] = useState(null);

    const handleFolderNameChange = (event) => {
        const input = event.target.value;

        if (input.length <= 45) {
            setFolderName(input);
        }
    };

    const handleCreate = () => {
        if (!folderName.trim()) {
            toast.error('Folder name is required');
            return;
        }

        // Construct folder data
        const folderData: FolderInfo = {
            id: uuidv4(),
            type: 'folder',
            title: folderName,
            icon: folderIcon,
            creationDate: new Date().toISOString(),
            items: [],
        };

        // Pass folder data back to parent component
        onCreate(folderData);

        // Close the dialog
        onClose();
    };

    const handleSelectIcon = (icon: IconSelectionInfo) => {
        setFolderIcon(icon);

        handleIconMenuClose();
    };

    const handleIconMenuOpen = (event) => {
        setAnchorIconEl(event.currentTarget);
        setIsIconPickerOpen(true);
    };

    const handleIconMenuClose = () => {
        setIsIconPickerOpen(false);
        setAnchorIconEl(null);
    };

    const isCreateButtonDisabled = folderName.trim().length === 0;

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            maxWidth="xs"
            fullWidth
            TransitionProps={{
                onEntered: () => {
                    const input = document.getElementById('folderNameInput');
                    if (input) {
                        input.focus();
                    }
                }
            }}
        >
            <DialogTitle>Create a folder</DialogTitle>
            <DialogContent>
                <TextField
                    label="Name"
                    id="folderNameInput"
                    value={folderName}
                    onChange={(e) => handleFolderNameChange(e)}
                    inputProps={{ maxLength: 45 }} // Set the maximum length for the input
                    fullWidth
                    margin="dense"
                    helperText={`${folderName.length}/45 characters`} // Show the character count
                />

                <div
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '16px' }}
                >
                    <Typography variant="body2">Choose Folder Icon</Typography>
                    <Tooltip title="Select an icon for the folder" placement='right'>
                        <IconComponent
                            size="large"
                            type='folder'
                            onClick={handleIconMenuOpen}
                            iconSelection={folderIcon}
                        />
                    </Tooltip>
                </div>

            </DialogContent>
            <DialogActions style={{ justifyContent: 'center', paddingBottom: '20px' }}>
                <Button
                    onClick={handleCreate}
                    disabled={isCreateButtonDisabled}
                    variant="outlined"
                >
                    Create folder
                </Button>
            </DialogActions>

            {/* Icon Picker Modal */}
            <Popover
                open={isIconPickerOpen}
                anchorEl={anchorIconEl}
                onClose={handleIconMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <IconsGrid onSelect={handleSelectIcon} />
            </Popover>
        </Dialog>
    );
}

export default CreateFolderDialog;
