"use client";

import React, { useState, useEffect, useMemo, useCallback, useContext, useRef } from 'react';
import { AppContext } from "@/app/providers";

import { Timeline, TimelineItem, timelineItemClasses, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent, TimelineOppositeContent } from '@mui/lab';
import { CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Popover, Typography, Tooltip, Drawer, Box, Backdrop, Menu, MenuItem, ListItemIcon, DialogContentText } from '@mui/material';
import { IconsGrid } from '../../lib/IconUtils';
import { v4 as uuidv4 } from 'uuid';
import { toPng } from 'html-to-image';
import { toast } from "sonner";

import { PlanInfo, PlanMilestoneData, PlanSnapshot, OKRData, PlanDefinition, PlanCreationInfo, ProgressInfo, IconSelectionInfo, PlanSideViewType, TimelineMilestoneData, TimelineLayerItem, PrioritiesSnapshotReference, PredefinedTimeRange, TimeRange } from '@/lib/interfaces';
import { addDuration, calculateNewMilestoneDates, calculateObjectiveProgress, calculatePlanDuration, calculateProgressInfo, debounce, describeTimeRange, formatDate, getOKRObjectiveText } from '@/lib/utils';
import SnapshotGallery from './SnapshotGallery';

import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import PsychologyAltOutlinedIcon from '@mui/icons-material/PsychologyAltOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import InterestsOutlinedIcon from '@mui/icons-material/InterestsOutlined';
import PublishIcon from '@mui/icons-material/Publish';
import DeleteIcon from '@mui/icons-material/Delete';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import WysiwygOutlinedIcon from '@mui/icons-material/WysiwygOutlined';
import ShowChartIcon from '@mui/icons-material/ShowChart';

import Editor from "@/ui/components/editor";
import RetrospectiveNotes from './RetrospectiveNotes';
import ProgressDialog from './ProgressDialog';

import EditableTitle from './EditableTitle';
import EditableDescription from './EditableDescription';
import { StorageManager } from '@/lib/StorageManager';

import logger from "@/lib/logger";

import { getFirebaseAnalytics, useFirebaseAuth } from '@/lib/hooks/firebase';
import { logEvent } from 'firebase/analytics';
import useSubscription from '@/lib/hooks/useSubscription';
import { sendEmailMessageForNewPlan, sendEmailMessageForPlanComplete, sendEmailMessageForTemplateSubmission } from './EmailMessage';
import PlanSelector from './PlanSelector';

import Confetti from 'react-confetti';
import { useTheme } from "next-themes";
import { getConfettiColors } from '@/lib/utils';

import { usePlans } from '@/context/PlansContext';

import DraggableMilestone from './DraggableMilestone';
import IconComponent from './IconComponent';
import TimelineBar from './TimelineBar';

interface PlanEditorProps {
}

const PlanEditor: React.FC<PlanEditorProps> = ({ }) => {
    const { theme, systemTheme } = useTheme();
    const effectiveTheme = theme === 'system' ? (systemTheme) : theme;

    const { user } = useFirebaseAuth();
    const { hasProPlan } = useSubscription(user?.uid);

    const { isMobile, isSmall } = useContext(AppContext);

    const {
        plans,
        selectedPlan,
        selectedMilestoneId,
        setSelectedMilestoneId,
        updatePlan,
        deletePlan,
        planDetailChangeCounter,
    } = usePlans();

    const planInfo = selectedPlan as PlanInfo;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updatePlanChanges = useCallback(
        debounce((changes, source, flagAsHeaderChange = false, flagAsDetailChange = false) => {
            logger.debug("Updating plan changes:", changes, source, flagAsHeaderChange, flagAsDetailChange);

            const updatedPlan = {
                ...selectedPlan,
                ...changes,
                updatedDate: new Date().toISOString()
            };

            updatePlan(updatedPlan, flagAsHeaderChange, flagAsDetailChange);
        }, 300),
        [selectedPlan, updatePlan, logger]
    );

    // Milestones / events
    const [milestones, setMilestones] = useState<PlanMilestoneData[]>([]);
    const [milestonesHaveChanged, setMilestonesHaveChanged] = useState(false);

    const maxMilestones = hasProPlan ? 72 : 6;
    const canAddMoreMilestones = milestones.length < maxMilestones;

    const [planProgressInfo, setPlanProgressInfo] = useState<ProgressInfo>(() => {
        if (planInfo && planInfo?.progressInfo) {
            return planInfo.progressInfo as ProgressInfo;
        } else {
            const startDate = planInfo?.init?.startDate || new Date().toISOString();
            const endDate = addDuration(
                startDate,
                planInfo?.init?.milestoneDuration,
                planInfo?.init?.numberOfMilestones
            );

            return {
                startDate,
                endDate,
                progress: 0,
            } as ProgressInfo;
        }
    });

    const storageManager = useMemo(() => {
        return new StorageManager();
    }, []);

    useEffect(() => {
        logger.debug('PlanEditor Component mounted');

        return () => {
            logger.debug('PlanEditor Component about to be unmounted');
        };
    }, []);

    const [aiMode, setAiMode] = useState(planInfo?.init?.mode || 'ai-completed');

    useEffect(() => {
        logger.debug('planInfo.init.mode changed:', planInfo?.init?.mode);

        if (planInfo?.init?.mode) {
            if (aiMode !== 'ai-completed') {
                logger.debug('Updating aiMode to:', planInfo.init.mode);
                setAiMode(planInfo.init.mode);
            } else {
                logger.warn('aiMode is already set to ai-completed');
            }
        }
    }, [planInfo?.init?.mode]);

    const isAIDialogOpen = /^ai-.*-(todo|done)$/.test(aiMode);
    const [aiStatus, setAiStatus] = useState('');
    const [aiProgress, setAiProgress] = useState(0);

    const [shouldPropagateChangeInMilestones, setShouldPropagateChangeInMilestones] = useState<number[]>([]);

    const [isPlanLoading, setIsPlanLoading] = useState(false);

    const initialize = useCallback(async () => {
        setIsPlanLoading(true);

        logger.debug("Retrieving plan milestones:", selectedPlan?.id);
        const initialEvents = await storageManager.get('timelines', selectedPlan?.id, 'events') || [];

        logger.debug("Ensuring the right date format for milestones");
        const fixedMilestones = initialEvents.map(milestone => {
            const convertIfTimestamp = (date) => date && typeof date.toDate === 'function' ? date.toDate() : date;

            return {
                ...milestone,
                startDate: convertIfTimestamp(milestone.startDate),
                endDate: convertIfTimestamp(milestone.endDate),
                collapsed: milestone.collapsed ?? false
            };
        });

        logger.debug("Retrieving plan OKRs:", selectedPlan?.id);
        const initialOKRs = await storageManager.get('timelines', selectedPlan?.id, 'okrs') || [];

        logger.debug("Retrieving plan retros:", selectedPlan?.id);
        const initialRetros = await storageManager.get('timelines', selectedPlan?.id, 'retros') || [];

        logger.debug("Retrieving plan icons:", selectedPlan?.id);
        const initialIcons = await storageManager.get('timelines', selectedPlan?.id, 'icons') || {};

        logger.debug("Ensuring the right format for icons");
        const fixedIcons = Object.entries(initialIcons).reduce((acc, [key, value]) => {
            if (typeof value === 'object' && value !== null && 'name' in value && 'isEmoji' in value) {
                acc[key] = value;
            } else if (typeof value === 'string') {
                acc[key] = { name: value, isEmoji: false } as IconSelectionInfo;
            }
            return acc;
        }, {});

        logger.debug("Retrieving plan snapshots:", selectedPlan?.id);
        const initialSnapshots = await storageManager.get('timelines', selectedPlan?.id, 'snapshots') || [];

        setMilestones(fixedMilestones);
        setOkrs(initialOKRs);
        setRetros(initialRetros);
        setIconSelections(fixedIcons);
        setSnapshots(initialSnapshots);

        setIsPlanLoading(false);
    }, [selectedPlan?.id, storageManager]);

    const [hasInitialized, setHasInitialized] = useState(false);

    useEffect(() => {
        if (selectedPlan?.id && !isPlanLoading && !hasInitialized) {
            const debouncedInitialize = debounce(async () => {
                await initialize();
                setHasInitialized(true);
            }, 300);
            debouncedInitialize();
        }
    }, [selectedPlan?.id, isPlanLoading, initialize, hasInitialized]);


    const milestoneRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

    const setMilestoneRef = useCallback((el: HTMLDivElement | null, id: string) => {
        if (el) {
            milestoneRefs.current[id] = el;
        }
    }, []);

    const scrollToMilestone = (planId, milestoneId) => {
        if (milestoneRefs.current[milestoneId]) {
            milestoneRefs.current[milestoneId].scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const autoCollapseOtherMilestones = (milestoneId) => {
        setMilestones(prevMilestones => prevMilestones.map(milestone =>
            ({ ...milestone, collapsed: milestone.id !== milestoneId })
        ));

        setMilestonesHaveChanged(true);
    }

    useEffect(() => {
        logger.debug("Checking if we should scroll to milestone id", selectedPlan?.id, selectedMilestoneId);

        if (!isPlanLoading && milestones && selectedPlan?.id && selectedMilestoneId && milestoneRefs.current[selectedMilestoneId]) {
            logger.debug("Scrolling to initial milestone:", selectedMilestoneId);

            // Introduce a slight delay to ensure refs are registered
            const timer = setTimeout(() => {
                autoCollapseOtherMilestones(selectedMilestoneId);

                scrollToMilestone(selectedPlan?.id, selectedMilestoneId);

                setSelectedMilestoneId(null);
            }, 10); // Adjust the delay time as necessary

            return () => clearTimeout(timer);
        }
    }, [milestones, selectedPlan?.id, selectedMilestoneId, isPlanLoading, setSelectedMilestoneId, milestoneRefs]);

    useEffect(() => {
        const saveMilestonesChange = async () => {
            try {
                await storageManager.set('timelines', selectedPlan?.id, milestones, 'events');
                setMilestonesHaveChanged(false);

                if (!isAIDialogOpen) {
                    // Update plan progress info after new milestone, changed milestone range, delete milestone, etc...
                    recalculatePlanProgressInfo();
                }
            } catch (error) {
                logger.error("Failed to update milestones:", error);
                toast.error('Failed to save changes in milestones');
            }
        };

        if (milestonesHaveChanged) {
            const debouncedSaveMilestonesChange = debounce(saveMilestonesChange, 300);
            debouncedSaveMilestonesChange();
        }
    }, [milestonesHaveChanged, storageManager, selectedPlan?.id, updatePlanChanges, isAIDialogOpen, milestones]);


    const handleMilestoneChange = (updatedMilestone: PlanMilestoneData) => {
        logger.debug("Handling milestone change:", updatedMilestone);

        setMilestones(prevMilestones => {
            const newMilestones = prevMilestones.map(milestone =>
                milestone.id === updatedMilestone.id ? updatedMilestone : milestone
            );

            return newMilestones;
        });

        setMilestonesHaveChanged(true);
    };

    const updateMilestones = (updatedMilestones: TimelineLayerItem[]) => {
        logger.debug("Updating milestones:", updatedMilestones);
    
        setMilestones(prevMilestones => {
            const updatedMilestoneIds = updatedMilestones.map(({ milestone }) => milestone.id);
    
            return prevMilestones.map(milestone => {
                if (updatedMilestoneIds.includes(milestone.id)) {
                    // Find the updated milestone
                    const updatedMilestone = updatedMilestones.find(({ milestone: m }) => m.id === milestone.id)?.milestone;
    
                    if (updatedMilestone) {
                        // Destructure to remove the plan, progress, and okr properties
                        const { plan, progress, okr, ...planMilestone } = updatedMilestone;
                        return planMilestone;
                    }
                }
    
                return milestone;
            });
        });
    
        setMilestonesHaveChanged(true);
    };    

    const handleMilestoneSelection = (milestoneId) => {
        setSelectedMilestoneId(milestoneId);
    };

    const clearKeyResultsForMilestone = (m) => {
        // Find the OKR associated with the milestone
        const okrIndex = okrs.findIndex(okr => okr.id === m.id);
        const milestoneIndex = milestones.findIndex(milestone => milestone.id === m.id);

        logger.debug("Clearing key results for milestone", m.id, okrIndex);

        if (okrIndex >= 0) {
            // Clear the key results of the found OKR
            let updatedOkrs = [...okrs];
            updatedOkrs[okrIndex] = { ...updatedOkrs[okrIndex], keyResults: [] };

            setOkrs(updatedOkrs);

            propagateChangeInMilestones(milestoneIndex);

            setOkrsHaveChanged(true);
        }
    };

    const updateKeyResultsFilterForMilestone = (m, filterSettings) => {
        // Find the OKR associated with the milestone
        const okrIndex = okrs.findIndex(okr => okr.id === m.id);
        const milestoneIndex = milestones.findIndex(milestone => milestone.id === m.id);

        logger.debug("Updating key results filter for milestone", m.id, okrIndex);

        if (okrIndex >= 0) {
            // Update the filter settings of the found OKR
            let updatedOkrs = [...okrs];
            updatedOkrs[okrIndex] = { ...updatedOkrs[okrIndex], filterSettings };

            setOkrs(updatedOkrs);

            propagateChangeInMilestones(milestoneIndex);

            setOkrsHaveChanged(true);
        }
    }

    const propagateChangeInMilestones = useCallback((milestoneIndex: number, milestoneIndex2?: number) => {
        logger.debug("Propagating to OKR Editor:", milestoneIndex);

        // Ensure the array is long enough
        const updatedArray = [...shouldPropagateChangeInMilestones];
        if (milestoneIndex >= updatedArray.length) {
            for (let i = updatedArray.length; i <= milestoneIndex; i++) {
                updatedArray[i] = updatedArray[i] || 0; // Initialize with 0 if undefined
            }
        }

        // Now safely update the value at milestoneIndex
        updatedArray[milestoneIndex] = (updatedArray[milestoneIndex] || 0) + 1;

        // If the second milestone index is provided, repeat the process
        if (milestoneIndex2 !== undefined) {
            logger.debug("Also propagating to OKR Editor for second milestone:", milestoneIndex2);
            for (let i = updatedArray.length; i <= milestoneIndex2; i++) {
                updatedArray[i] = updatedArray[i] || 0; // Initialize with 0 if undefined
            }
            updatedArray[milestoneIndex2] = (updatedArray[milestoneIndex2] || 0) + 1;
        }

        setShouldPropagateChangeInMilestones(updatedArray);
    }, [shouldPropagateChangeInMilestones, setShouldPropagateChangeInMilestones]);

    const handleDuplicateMilestone = (index) => {
        if (index === -1) return;

        const milestoneToDuplicate = milestones[index];
        const duplicatedMilestone = {
            ...milestoneToDuplicate,
            id: uuidv4(), // Ensure a new ID for the duplicated milestone
        };

        const newMilestones = [
            ...milestones.slice(0, index + 1),
            duplicatedMilestone,
            ...milestones.slice(index + 1)
        ];

        setMilestones(newMilestones);
        setMilestonesHaveChanged(true);

        // Duplicate OKRs if they exist for the milestone
        const okrsToDuplicate = okrs.filter(okr => okr.id === milestoneToDuplicate.id);
        if (okrsToDuplicate.length > 0) {
            const duplicatedOkrs = okrsToDuplicate.map(okr => ({
                ...okr,
                id: duplicatedMilestone.id, // Assign new milestone ID to duplicated OKRs
            }));

            setOkrs([...okrs, ...duplicatedOkrs]);
            setOkrsHaveChanged(true); // Indicate that OKRs have changed
        }

        // Duplicate icons if they exist for the milestone
        if (iconSelections[milestoneToDuplicate.id]) {
            const duplicatedIcons = { [duplicatedMilestone.id]: iconSelections[milestoneToDuplicate.id] };
            setIconSelections({
                ...iconSelections,
                ...duplicatedIcons,
            });
            setIconsHaveChanged(true); // Indicate that icons have changed
        }
    };

    const handleMilestoneAutoUpdateTimeRange = (milestone, index) => {
        const previousMilestone = index > 0 ? milestones[index - 1] : null;
        const nextMilestone = index < milestones.length - 1 ? milestones[index + 1] : null;

        const { startDate: newStartDate, endDate: newEndDate, changed } = calculateNewMilestoneDates(milestone, previousMilestone, nextMilestone);

        if (!changed) {
            toast.message('Time range looks good.');
            return;
        }

        milestone.startDate = newStartDate.toISOString();
        milestone.endDate = newEndDate.toISOString();
        milestone.description = describeTimeRange(newStartDate, newEndDate);

        handleMilestoneChange(milestone);
        propagateChangeInMilestones(index);

        const message = `New range: ${formatDate(milestone.startDate)} to ${formatDate(milestone.endDate)}`;
        toast.success(message);
        logger.debug(message);
    };

    const moveMilestoneWithinPlan = (draggedId, targetId, dragIndex, hoverIndex) => {
        logger.debug("Moving milestone within plan:", draggedId, targetId, dragIndex, hoverIndex);

        // Find the dragged and target milestone objects
        const draggedMilestone = milestones.find(milestone => milestone.id === draggedId);
        const targetMilestone = milestones.find(milestone => milestone.id === targetId);

        if (!draggedMilestone || !targetMilestone) {
            logger.error('Could not find milestones for dragging operation');
            return;
        }

        logger.debug(`Moving milestone from index ${dragIndex} to ${hoverIndex}`);

        // Create a new array without the dragged milestone
        const newMilestones = milestones.filter(milestone => milestone.id !== draggedId);

        // Insert the dragged milestone at the position of the target milestone
        newMilestones.splice(hoverIndex, 0, draggedMilestone);

        // Update the state with the new milestones array
        setMilestones(newMilestones);
        setMilestonesHaveChanged(true);

        // Optionally, log the event for analytics
        logEvent(getFirebaseAnalytics(), 'move_milestone', {
            draggedMilestoneId: draggedId,
            targetMilestoneId: targetId,
            fromIndex: dragIndex,
            toIndex: hoverIndex
        });

        // Provide feedback to the user
        toast.success(`Moved milestone from position ${dragIndex + 1} to ${hoverIndex + 1}`);
    };

    const [planSelectorOpen, setPlanSelectorOpen] = useState(false);
    const [anchorMilestoneActionsEl, setAnchorMilestoneActionsEl] = useState(null);
    const [milestoneAction, setMilestoneAction] = useState(null);
    const [movingMilestone, setMovingMilestone] = useState(null);

    const handleClosePlanSelector = () => {
        setPlanSelectorOpen(false);
        setAnchorMilestoneActionsEl(null);
    };

    const handleMoveMilestoneToNewPlan = (event, milestone, action) => {
        setMilestoneAction(action);
        setMovingMilestone(milestone);
        setAnchorMilestoneActionsEl(event.currentTarget);
        setPlanSelectorOpen(true);
    };

    const onSelectPlanForMove = async (plan: PlanInfo) => {
        setPlanSelectorOpen(false);

        await moveMilestoneToNewPlan(movingMilestone.id, plan.id, milestoneAction === 'move' ? true : false);

        // Reset the action and close the menu after the operation
        setMilestoneAction(null);
    };

    const moveMilestoneToNewPlan = async (milestoneId, newPlanId, shouldMove) => {
        const index = milestones.findIndex(m => m.id === milestoneId);
        if (index === -1) return;

        try {
            await moveMilestoneToNewPlanHelper(milestoneId, newPlanId, shouldMove);
            toast.message('Milestone moved to new plan');
        } catch (error) {
            logger.error('Failed to move milestone to new plan:', error);
            toast.error('Failed to move milestone to new plan');
        } finally {
            if (movingMilestone) {
                setMovingMilestone(null);
            }
        }
    };

    const moveMilestoneToNewPlanHelper = async (milestoneId, newPlanId, shouldMove = false) => {
        const milestoneIndex = milestones.findIndex(m => m.id === milestoneId);
        if (milestoneIndex === -1) return; // Milestone not found

        const milestone = milestones[milestoneIndex];
        const okr = okrs.find(o => o.id === milestoneId);
        const icon = iconSelections[milestoneId] as IconSelectionInfo;
        const retro = retros.find(r => r.id === milestoneId);

        await addMilestoneToPlan(newPlanId, milestone, okr, icon, retro);

        // Remove from current plan after successful addition to the new plan
        if (shouldMove) {
            setMilestones(currentMilestones => currentMilestones.filter(m => m.id !== milestoneId));

            setOkrs(currentOkrs => currentOkrs.filter(o => o.id !== milestoneId));

            setIconSelections(currentIcons => {
                const updatedIcons = { ...currentIcons };
                delete updatedIcons[milestoneId];
                return updatedIcons;
            });

            setRetros(currentRetros => currentRetros.filter(r => r.id !== milestoneId));

            setMilestonesHaveChanged(true);
            setOkrsHaveChanged(true);
            setIconsHaveChanged(true);
            setRetrosHaveChanged(true);
        }
    };

    const addMilestoneToPlan = async (planId, milestone, okr, icon, retro) => {
        await storageManager.addMilestoneToPlan(planId, milestone, okr, icon, retro);
    };

    const [okrs, setOkrs] = useState<OKRData[]>([]);
    const [okrsHaveChanged, setOkrsHaveChanged] = useState(false);

    useEffect(() => {
        logger.debug("OKRs updated:", okrs);
    }, [okrs]);

    useEffect(() => {
        const saveOkrs = async () => {
            try {
                setOkrsHaveChanged(false);

                if (!okrs || okrs.length === 0) {
                    logger.warn("No OKRs to save");
                    return;
                }

                await storageManager.set('timelines', selectedPlan?.id, okrs, 'okrs');

                // Minimize updates when in AI mode
                if (!isAIDialogOpen) {
                    recalculatePlanProgressInfo();
                }
            } catch (error) {
                logger.error("Failed to save OKRs:", error);
                toast.error('Failed to save OKRs');
            }
        };

        if (okrsHaveChanged) {
            saveOkrs();
        }
    }, [okrs, okrsHaveChanged, storageManager, selectedPlan?.id, updatePlanChanges, isAIDialogOpen]);

    const handleSaveOKR = useCallback((updatedOkr: OKRData) => {
        logger.debug("Saving OKR:", updatedOkr);

        setOkrs(prevOkrs => {
            if (prevOkrs.length === 0) {
                logger.warn("Attempting to save an OKR but the current OKR list is empty");
            }

            const existingOkrIndex = prevOkrs.findIndex(okr => okr.id === updatedOkr.id);

            if (existingOkrIndex >= 0) {
                // OKR exists, so we update it
                logger.debug("Updating existing OKR:", updatedOkr);
                const updatedOkrs = [...prevOkrs];
                updatedOkrs[existingOkrIndex] = updatedOkr;
                return updatedOkrs;
            } else {
                // OKR does not exist, so we add it
                logger.debug("Adding new OKR:", updatedOkr);
                return [...prevOkrs, updatedOkr];
            }
        });

        setOkrsHaveChanged(true);
    }, []);

    const [timelineMilestones, setTimelineMilestones] = useState<TimelineMilestoneData[]>([]);

    useEffect(() => {
        if (selectedPlan && okrs && milestones) {
            const newTimelineMilestones: TimelineMilestoneData[] = milestones.map((milestone: PlanMilestoneData, milestoneIndex) => {
                let okr = okrs.find((okr) => okr.id === milestone.id);

                if (!okr) {
                    okr = {
                        id: milestone.id,
                        objective: '',
                        keyResults: []
                    };
                }

                okr.objective = getOKRObjectiveText(okr, milestoneIndex);

                const milestoneProgress = okr ? calculateObjectiveProgress(okr.keyResults) : 0;

                return {
                    ...milestone,
                    plan: selectedPlan as PlanInfo,
                    okr,
                    progress: milestoneProgress,
                } as TimelineMilestoneData;
            });

            logger.debug("Setting timeline milestones:", newTimelineMilestones);

            setTimelineMilestones(newTimelineMilestones);
        }
    }, [selectedPlan, okrs, milestones]);

    const getEditorTitle = (sourceType, sourceId, sourceIndex) => {
        logger.debug("Getting editor title for", sourceType, sourceId, sourceIndex);

        if (sourceType === 'kr') {
            // Get the title of the key result
            const okr = okrs.find(okr => okr.id === sourceId);
            return okr?.keyResults[sourceIndex]?.description || "Key Result Details";
        } else {
            // Get the text of the objective
            const objective = okrs.find(okr => okr.id === sourceId)?.objective;
            return objective || "Objective Details";
        }
    };

    const [showPlanConfetti, setShowPlanConfetti] = useState(false);

    const recalculatePlanProgressInfo = useCallback(() => {
        logger.debug("Recalculating plan progress info");

        const progressInfo = calculateProgressInfo(milestones, okrs);

        const roundedPrevProgress = Math.round(planProgressInfo.progress);
        const roundedAverageProgress = Math.round(progressInfo.progress);

        if (roundedPrevProgress !== roundedAverageProgress && roundedPrevProgress !== 0 && roundedAverageProgress === 100) {
            sendEmailMessageForPlanComplete(planInfo, user);

            if (!showPlanConfetti) {
                setShowPlanConfetti(true);
                setTimeout(() => setShowPlanConfetti(false), 5000);
            }
        }

        setPlanProgressInfo(prevInfo => {
            if (prevInfo.startDate !== progressInfo.startDate ||
                prevInfo.endDate !== progressInfo.endDate ||
                Math.round(prevInfo.progress) !== Math.round(progressInfo.progress)) {

                updatePlanChanges({
                    progressInfo
                }, 'planProgressInfo');

                return progressInfo;
            }

            return prevInfo;
        });
    }, [milestones, okrs, updatePlanChanges, user, showPlanConfetti, planInfo, planProgressInfo]);

    const [sideViewAnchorEl, setSideViewAnchorEl] = useState(null);
    const [sideViewType, setSideViewType] = useState<PlanSideViewType>(planInfo?.sideViewType || PlanSideViewType.Vision);

    const handleSideViewClick = (event) => {
        setSideViewAnchorEl(event.currentTarget);
    };

    const handleSideViewClose = (option: PlanSideViewType) => {
        setSideViewAnchorEl(null);
        setSideViewType(option);

        if (option !== planInfo?.sideViewType) {
            planInfo.sideViewType = option;
            updatePlanChanges({ updatedDate: new Date().toISOString() }, 'sideView');
        }
    };

    const menuItems = Object.keys(PlanSideViewType)
        .filter(key => isNaN(Number(key)))  // Filter out numeric values
        .filter((key) => PlanSideViewType[key as keyof typeof PlanSideViewType] !== sideViewType);


    // State for retrospective notes
    const isRetroOpen = () => {
        return sideViewType === PlanSideViewType.Mindset;
    };

    const [retros, setRetros] = useState([]);
    const [retrosHaveChanged, setRetrosHaveChanged] = useState(false);

    // Save retros to storage
    useEffect(() => {
        const saveRetros = async () => {
            try {
                await storageManager.set('timelines', selectedPlan?.id, retros, 'retros');
                setRetrosHaveChanged(false);

                // Minimize updates when in AI mode
                if (!isAIDialogOpen) {
                    updatePlanChanges({ updatedDate: new Date().toISOString() }, 'retros');
                }
            } catch (error) {
                logger.error("Failed to save retros:", error);
                toast.error('Failed to save retrospective notes');
            }
        };

        if (retrosHaveChanged) {
            saveRetros();
        }
    }, [retros, retrosHaveChanged, storageManager, selectedPlan?.id, updatePlanChanges, isAIDialogOpen]);

    // Function to update a single retrospective note
    const handleSaveRetro = (updatedRetro) => {
        const existingRetroIndex = retros.findIndex(retro => retro.id === updatedRetro.id);
        let updatedRetros;
        if (existingRetroIndex >= 0) {
            // Retro exists, so we update it
            updatedRetros = [...retros];
            updatedRetros[existingRetroIndex] = updatedRetro;
        } else {
            // Retro does not exist, so we add it
            updatedRetros = [...retros, updatedRetro];
        }

        setRetros(updatedRetros);
        setRetrosHaveChanged(true);
    };

    // dot and its icons
    const [selectedDot, setSelectedDot] = useState<string | null>(null);
    const [iconSelections, setIconSelections] = useState<{ [key: string]: IconSelectionInfo }>({});
    const [anchorIconEl, setIconAnchorEl] = React.useState(null);
    const [isIconMenuOpen, setIsIconMenuOpen] = useState(false);

    const [iconsHaveChanged, setIconsHaveChanged] = useState(false);

    useEffect(() => {
        const handleIconsChange = async () => {
            try {
                await storageManager.set('timelines', selectedPlan?.id, iconSelections, 'icons');
                setIconsHaveChanged(false);

                // Minimize updates when in AI mode
                if (!isAIDialogOpen) {
                    updatePlanChanges({ updatedDate: new Date().toISOString() }, 'icons', false, true);
                }
            } catch (error) {
                logger.error("Failed to update icons:", error);
                toast.error('Failed to update milestone icon')
            }
        };

        if (iconsHaveChanged) {
            handleIconsChange();
        }
    }, [iconSelections, iconsHaveChanged, storageManager, selectedPlan?.id, updatePlanChanges, isAIDialogOpen]);

    const handleIconClickForMilestone = (event, milestoneId) => {
        setIconAnchorEl(event.currentTarget);
        setSelectedDot(milestoneId);
        setIsIconMenuOpen(true);

        logEvent(getFirebaseAnalytics(), 'timeline_icon_click');
    };

    const handleIconClickForPlan = (event) => {
        setIconAnchorEl(event.currentTarget);
        setSelectedDot(null);
        setIsIconMenuOpen(true);

        logEvent(getFirebaseAnalytics(), 'plan_icon_click');
    };

    const handleIconMenuClose = () => {
        setIconAnchorEl(null);
        setIsIconMenuOpen(false);
    };

    const selectIcon = async (icon: IconSelectionInfo) => {
        logger.debug("Selected icon:", icon.name);

        if (selectedDot != null) {
            const newSelections = { ...iconSelections, [selectedDot]: icon };
            setIconSelections(newSelections);
            setIconsHaveChanged(true);

            logEvent(getFirebaseAnalytics(), 'milestone_icon_selected', {
                icon_name: icon.name
            });
        } else {
            updatePlanChanges({ icon: icon }, 'icon', true, false);

            logEvent(getFirebaseAnalytics(), 'plan_icon_selected', {
                icon_name: icon.name
            });
        }

        setIsIconMenuOpen(false);
    };

    // Render icon based on selection or default
    const renderPlanDotIcon = (milestoneId) => {
        return (
            <IconComponent
                size="medium"
                type='milestone'
                iconSelection={iconSelections[milestoneId]}
            />
        )
    };

    // State for managing the drawer's visibility
    const [isEditorDrawerOpen, setEditorDrawerOpen] = useState(false);
    const [isEditorContentLoading, setIsEditorContentLoading] = useState(null);
    const [selectedEditorSourceId, setSelectedEditorSourceId] = useState(null);
    const [selectedEditorSourceIndex, setSelectedEditorSourceIndex] = useState(null);
    const [selectedEditorSourceType, setSelectedEditorSourceType] = useState(null);
    const [selectedEditorTitle, setSelectedEditorTitle] = useState('');
    const [selectedEditorTimeRange, setSelectedEditorTimeRange] = useState('');
    const [selectedEditorContent, setSelectedEditorContent] = useState('');
    const [isDrawerFullWidth, setIsDrawerFullWidth] = useState(false);

    const handleOpenEditor = async (sourceType, sourceId, sourceIndex) => {
        logger.debug("Opening editor for", sourceType, sourceId, sourceIndex);

        logger.debug("Loading editor content...");
        setIsEditorContentLoading(true);

        const title = getEditorTitle(sourceType, sourceId, sourceIndex);

        let timeRange = "";
        const milestone = milestones.find(event => event.id === sourceId);
        if (milestone) {
            timeRange = describeTimeRange(milestone.startDate, milestone.endDate);
        }

        const content = await getEditorContent(
            sourceType,
            sourceId,
            sourceIndex);

        setSelectedEditorTitle(title);
        setSelectedEditorTimeRange(timeRange);
        setSelectedEditorContent(content);

        logger.debug("Editor content loaded:", content);

        setSelectedEditorSourceId(sourceId);
        setSelectedEditorSourceType(sourceType);
        setSelectedEditorSourceIndex(sourceIndex);

        setIsEditorContentLoading(false);

        logEvent(getFirebaseAnalytics(), 'open_editor', {
            source_type: sourceType
        });
    };

    useEffect(() => {
        if (isEditorContentLoading === false) {
            setEditorDrawerOpen(true);
            setIsEditorContentLoading(null);
        }
    }, [isEditorContentLoading]);

    const handleCloseEditor = () => {

        setSelectedEditorSourceId(null);
        setSelectedEditorSourceType(null);
        setSelectedEditorSourceIndex(null);

        setSelectedEditorTitle('');
        setSelectedEditorContent('');

        setIsEditorContentLoading(null);

        setEditorDrawerOpen(false);
    }

    const onEditorContentUpdated = async (updatedEditorContent, sourceType, sourceId, sourceIndex) => {
        logger.debug("Updated editor content", updatedEditorContent, sourceType, sourceId, sourceIndex);

        if (isPlanLoading) {
            logger.warn("Attempted to update a note while the plan is still loading.");
            return;
        }

        let okrsChanged = false;

        if (!updatedEditorContent) {
            logger.warn("Attempted to update a note, but no editor data was provided.");
            return;
        }

        let updatedOKRs = [...okrs];
        let targetOkrIndex = updatedOKRs.findIndex(okr => okr.id === sourceId);

        if (targetOkrIndex === -1) {
            // Create a new OKR if it doesn't exist
            const newOkr = {
                id: sourceId,
                objective: "",
                keyResults: [],
                contentId: uuidv4()
            };

            updatedOKRs.push(newOkr);
            targetOkrIndex = updatedOKRs.length - 1;

            // Save the new OKR
            await handleSaveOKR(newOkr);

            okrsChanged = true;
        }

        // At this point, updatedOKRs includes the targetOkr
        let targetOkr = updatedOKRs[targetOkrIndex];

        logger.debug('updatedOKRs', updatedOKRs);
        logger.debug('Looking for OKR with sourceId', sourceId);
        logger.debug("OKR with content to update:", targetOkr);

        if (sourceType === 'okr') {
            if (!targetOkr.contentId) {
                targetOkr.contentId = uuidv4();

                okrsChanged = true;
            }

            logger.debug("OKR Content Id:", targetOkr.contentId);
            logger.debug("Saving OKR content:", updatedEditorContent);

            await storageManager.set('notes', targetOkr.contentId, updatedEditorContent);

            targetOkr = { ...targetOkr, contentId: targetOkr.contentId };
            updatedOKRs[targetOkrIndex] = targetOkr;
        } else if (sourceType === 'kr') {

            const newKeyResults = targetOkr.keyResults.map((kr, idx) => {
                if (idx === sourceIndex) {
                    if (!kr.contentId) {
                        okrsChanged = true;
                    }

                    const newContentId = kr.contentId || uuidv4();

                    logger.debug("Key Result Content Id: ", newContentId);
                    logger.debug("Saving Key Result content:", updatedEditorContent);

                    storageManager.set('notes', newContentId, updatedEditorContent);
                    return { ...kr, contentId: newContentId };
                }
                return kr;
            });

            targetOkr = { ...targetOkr, keyResults: newKeyResults };
            updatedOKRs[targetOkrIndex] = targetOkr;
        }

        if (okrsChanged) {
            setOkrs(updatedOKRs);

            // I am changing the OKR in PlanEditor, so I need to let the OKREditor component know
            let targetMilestoneIndex = milestones.findIndex(m => m.id === sourceId);
            propagateChangeInMilestones(targetMilestoneIndex);

            setOkrsHaveChanged(true);
        }
    };

    const getEditorContent = async (sourceType, sourceId, sourceIndex) => {
        logger.debug("getEditorContent", sourceType, sourceId, sourceIndex);

        let contentId, note;

        switch (sourceType) {
            case 'okr':
                contentId = okrs.find(okr => okr.id === sourceId)?.contentId;
                logger.debug("OKR contentId:", contentId);
                break;
            case 'kr':
                contentId = okrs.find(okr => okr.id === sourceId)?.keyResults[sourceIndex]?.contentId;
                logger.debug("KR contentId:", contentId);
                break;
            default:
                logger.debug("Unknown contentId");
                break;
        }

        if (contentId) {
            logger.debug("Fetching editor content for", sourceType, sourceId, sourceIndex, contentId);

            note = await storageManager.get('notes', contentId);

            logger.debug("Notes content:", note);
        }

        let result = note;

        logger.debug("Returning editor content:", result);

        return result;
    };

    const handleMoveKRToAnotherOKR = async (sourceOkrId, targetOkrId, dragIndex, hoverIndex) => {
        logger.debug("Moving KR from", sourceOkrId, "to", targetOkrId, "from", dragIndex, "to", hoverIndex);

        const sourceOkrIndex = okrs.findIndex(okr => okr.id === sourceOkrId);
        const targetOkrIndex = okrs.findIndex(okr => okr.id === targetOkrId);

        if (sourceOkrIndex === -1 || targetOkrIndex === -1) {
            logger.error("Source or target OKR not found.");
            return;
        }

        const sourceOkr = okrs[sourceOkrIndex];
        const targetOkr = okrs[targetOkrIndex];

        if (!sourceOkr || !targetOkr) {
            logger.error("Source or target OKR is invalid.");
            return;
        }

        const sourceKR = sourceOkr.keyResults[dragIndex];

        if (!sourceKR) {
            logger.error("Source KR is invalid.");
            return;
        }

        // Remove KR from source OKR
        const updatedSourceKeyResults = [...sourceOkr.keyResults];
        updatedSourceKeyResults.splice(dragIndex, 1);

        // Add KR to target OKR
        const updatedTargetKeyResults = [...targetOkr.keyResults];
        if (hoverIndex >= updatedTargetKeyResults.length) {
            updatedTargetKeyResults.push(sourceKR);
        } else {
            updatedTargetKeyResults.splice(hoverIndex, 0, sourceKR);
        }

        // Update the OKRs array with the updated key results
        const updatedOKRs = [...okrs];
        updatedOKRs[sourceOkrIndex] = { ...sourceOkr, keyResults: updatedSourceKeyResults };
        updatedOKRs[targetOkrIndex] = { ...targetOkr, keyResults: updatedTargetKeyResults };

        setOkrs(updatedOKRs);

        let sourceMilestoneIndex = milestones.findIndex(m => m.id === sourceOkrId);
        let targetMilestoneIndex = milestones.findIndex(m => m.id === targetOkrId);

        propagateChangeInMilestones(targetMilestoneIndex, sourceMilestoneIndex);

        setOkrsHaveChanged(true);
    };

    const addMilestone = (index) => {
        logger.debug("Adding milestone at index", index);

        if (index < 0 || index >= milestones.length) {
            logger.error("Invalid index for adding a milestone.");
            toast.error('Failed to add milestone');
            return;
        }

        if (!canAddMoreMilestones) {
            if (hasProPlan) {
                toast.error(`${maxMilestones} is maximum number of milestones you can add!`);
            } else {
                toast.error(`Upgrade to a Pro plan to add more than ${maxMilestones} milestones`);
            }
            return;
        }

        const currentMilestone = milestones[index];
        const currentDate = new Date(currentMilestone.endDate);

        if (isNaN(currentDate.getTime())) {
            logger.error("Invalid endDate in the current milestone.");
            toast.error('Failed to add milestone due to invalid end date');
            return;
        }

        const newMilestoneStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1);
        if (isNaN(newMilestoneStartDate.getTime())) {
            logger.error("Failed to calculate new milestone start date.");
            toast.error('Failed to add milestone due to invalid start date');
            return;
        }

        // Set the end date to the last day of the same month as the start date
        const newMilestoneEndDate = new Date(newMilestoneStartDate.getFullYear(), newMilestoneStartDate.getMonth() + 1, 0);
        if (isNaN(newMilestoneEndDate.getTime())) {
            logger.error("Failed to calculate new milestone end date.");
            toast.error('Failed to add milestone due to invalid end date');
            return;
        }

        // Creating a new milestone object
        const newMilestone = {
            id: uuidv4(),
            startDate: newMilestoneStartDate.toISOString(),
            endDate: newMilestoneEndDate.toISOString(),
            description: describeTimeRange(newMilestoneStartDate, newMilestoneEndDate)
        } as PlanMilestoneData;

        // Inserting the new milestone into the events array
        const updatedEvents = [...milestones];
        updatedEvents.splice(index + 1, 0, newMilestone);
        setMilestones(updatedEvents);
        setMilestonesHaveChanged(true);

        logEvent(getFirebaseAnalytics(), 'add_milestone', {
            milestone_index: index
        });
    };

    const handleDeleteMilestone = (index) => {
        setMilestones(updatedMilestones => updatedMilestones.filter((_, idx) => idx !== index));
        setMilestonesHaveChanged(true);

        logEvent(getFirebaseAnalytics(), 'delete_milestone', {
            milestone_index: index
        });
    };

    // Function to handle title save
    const handleTitleSave = (newTitle) => {
        updatePlanChanges({ title: newTitle }, 'title', true, false);
    };

    // Function to handle description save
    const handleDescriptionSave = (newDescription) => {
        updatePlanChanges({ description: newDescription }, 'description');
    };

    const [snapshots, setSnapshots] = useState<PlanSnapshot[]>([]);
    const [snapshotsHaveChanged, setSnapshotsHaveChanged] = useState(false);

    useEffect(() => {
        const handleSnapshotsChange = async () => {
            try {
                await storageManager.set('timelines', selectedPlan?.id, snapshots, 'snapshots');
                setSnapshotsHaveChanged(false);

                //  updatePlan({ updatedDate: new Date().toISOString() }, 'snapshots');
            } catch (error) {
                logger.error("Failed to update snapshots:", error);
                toast.error('Failed to update snapshots');
            }
        };

        if (snapshotsHaveChanged) {
            handleSnapshotsChange();
        }
    }, [snapshots, snapshotsHaveChanged, storageManager, selectedPlan?.id, updatePlan]);

    const [isTakingSnapshot, setIsTakingSnapshot] = useState(false);

    const maxSnapshots = hasProPlan ? 72 : 3;
    const canTakeSnapshot = snapshots.length < maxSnapshots;

    const snapshotButtonTooltip = !canTakeSnapshot
        ? "Upgrade to a Pro plan to take more than 3 snapshots"
        : "Record the history of your plan";

    const handleTakeSnapshot = async () => {
        if (!canTakeSnapshot) {
            toast.error(snapshotButtonTooltip);
            return;
        }

        toast.message('Taking snapshot...');

        setIsTakingSnapshot(true);

        logEvent(getFirebaseAnalytics(), 'take_snapshot');

        try {
            const planElement = document.querySelector('#tq-plan') as HTMLElement;

            if (!planElement) {
                throw new Error("Plan element not found.");
            }

            // Find elements to remove and store their information
            const elementsToRemove = planElement.querySelectorAll('.screenshot-remove');
            const removedElements = Array.from(elementsToRemove).map(el => ({
                element: el,
                parent: el.parentNode,
                nextSibling: el.nextSibling
            }));

            // Remove elements from DOM
            removedElements.forEach(({ element }) => element.remove());

            // Custom filter function
            const filter = (node) => {
                if (!(node instanceof HTMLElement)) {
                    return true;
                }

                if (node.classList.contains('screenshot-show')) {
                    return true;
                }

                // Exclude buttons and elements with the 'screenshot-hide' class
                if (node.tagName === 'BUTTON' || node.classList.contains('screenshot-hide')) {
                    return false;
                }
                return true;
            };

            // Generate Image from Html
            const dataUrl = await toPng(planElement, {
                cacheBust: true,
                filter: filter
            });

            // Add removed elements back to the DOM
            removedElements.forEach(({ element, parent, nextSibling }) => {
                if (nextSibling) {
                    parent.insertBefore(element, nextSibling);
                } else {
                    parent.appendChild(element);
                }
            });

            const snapshotId = uuidv4();
            const snapshotUrl = await storageManager.handleSnapshotUpload(dataUrl, selectedPlan?.id, snapshotId);

            const newSnapshot = {
                id: snapshotId,
                url: snapshotUrl,
                date: new Date().toISOString()
            };

            const newSnapshots = [...snapshots, newSnapshot];
            setSnapshots(newSnapshots);
            setSnapshotsHaveChanged(true);

            openGallery();

            setIsTakingSnapshot(false);
            toast.success('Snapshot taken successfully!');
        } catch (error) {
            logger.error("Error handling snapshot:", error);

            setIsTakingSnapshot(false);
            toast.error(error?.message || error || 'Failed to take snapshot.');
        }
    };

    const [isGalleryOpen, setIsGalleryOpen] = useState(false);

    const openGallery = () => {
        setIsGalleryOpen(true);
    };

    const closeGallery = () => {
        setIsGalleryOpen(false);
    };

    const getPlanDefinition = useCallback(() => {
        logger.debug("Getting plan definition...");

        return {
            title: planInfo?.title,
            description: planInfo?.description,
            events: milestones,
            okrs: okrs,
            retros: retros,
            icons: iconSelections,
            init: planInfo?.init,
            durationInDays: calculatePlanDuration(milestones),
        } as PlanDefinition;
    }, [planInfo, milestones, okrs, retros, iconSelections]);

    const submitPlanAsTemplate = useCallback(async () => {
        logger.debug("Submitting plan as template...");

        const planTemplate = getPlanDefinition();

        toast.message('Submitting plan as template...');

        // Save
        try {
            await storageManager.set('templates', uuidv4(), planTemplate);
            toast.success('Plan submitted as template!');

            sendEmailMessageForTemplateSubmission(user, planTemplate);
        } catch (error) {
            logger.error("Error Submitting template to Firestore:", error);
            toast.error('Failed to submit plan as template');
        }
    }, [storageManager, getPlanDefinition, user]);


    const savePlanAsTemplate = async () => {
        logger.debug("Saving plan as template...");

        const planTemplate = getPlanDefinition();

        toast.message('Saving plan as template...');

        // Save
        try {
            // await storageManager.set('templates', uuidv4(), planTemplate);
            toast.success('Plan saved as template!');

            sendEmailMessageForTemplateSubmission(user, planTemplate);
        } catch (error) {
            logger.error("Error saving template to Firestore:", error);
            toast.error('Failed to save plan as template');
        }
    }

    const handleExportPlanInfoJSON = () => {
        const jsonData = JSON.stringify(planInfo, null, 2);
        handleExportJSON(`plan_info_${planInfo.title}`, jsonData);
    }

    const handleExportPlanDefJSON = () => {
        const planDefinition = getPlanDefinition();
        const jsonData = JSON.stringify(planDefinition, null, 2);
        handleExportJSON(`plan_definition_${planDefinition.title}`, jsonData);
    }

    const handleExportJSON = (name, jsonData) => {
        const blob = new Blob([jsonData], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${name}_export.json`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);

        logEvent(getFirebaseAnalytics(), 'export_plan_json');
        toast.success('Plan exported as JSON');
    };

    // Function to check if the response matches the OKRData type
    function isValidOKRData(data): data is OKRData {
        return Object.prototype.hasOwnProperty.call(data, 'objective') &&
            Array.isArray(data.keyResults) &&
            data.keyResults.every(kr =>
                Object.prototype.hasOwnProperty.call(kr, 'description')
            );
    }

    const fetchOkrForMilestone = useCallback(async (planInfo, milestoneIndex, milestone, objectivesList) => {
        let planCreationInfo = planInfo?.init as PlanCreationInfo;

        let objectivesStr = objectivesList && objectivesList.length > 0
            ? objectivesList.map((objective, index) => `Milestone ${index + 1}: ${objective}`).join('\n')
            : 'None';

        let objectivesPrompt = `
            In case it helps, for context, here are the objectives of the previous milestones in the plan:
            ${objectivesStr}
        `;

        let systemPrompt = `
            Generate an objective and its Key Results for milestone ${milestoneIndex + 1} (${milestone.description}) in a plan consisting of ${planCreationInfo.numberOfMilestones} milestones. 
            
            Plan title: ${planInfo?.title}
            Plan Description: ${planInfo?.description}

            ${objectivesList && objectivesList.length > 0 ? objectivesPrompt : ''}

            Ensure that the Key Results are measurable and achievable within the milestone's duration. 
            
            Whenever providing dates in your responses, please format them in a long, user-friendly style, such as 'December 23, 2023'.
            Duration should also be formatted in a human readable format.

            This milestone starts on ${new Date(milestone.startDate).toDateString()} and ends on ${new Date(milestone.endDate).toDateString()}. Today is ${new Date().toDateString()}.
            
            Structure the response as a single JSON object following the format below.
            
            {
                "objective": "Milestone Objective",
                "keyResults": [
                    {"description": "First Key Result", "progress": 0}
                ]
            }
 
            The response should include exactly one objective, at least one key result, and at most three key results.

            Remember, the format is crucial - your response must be in the very specific JSON format I provided as an example.
            `;

        let attempts = 0;
        const maxAttempts = 5;

        while (attempts < maxAttempts) {
            try {
                let systemMessage = systemPrompt;

                if (attempts > 0) {
                    logger.warn(`Attempt ${attempts} of ${maxAttempts}`);

                    logEvent(getFirebaseAnalytics(), 'retry_ai_okr', {
                        attempt: attempts
                    });

                    systemMessage = `
                        You have just failed attempt ${attempts} to generate an objective and its key results. 

                        the format should be what as follows:

                        {
                            "objective": "Milestone Objective",
                            "keyResults": [
                                {"description": "First Key Result", "progress": 0}
                            ]
                        }

                        ${systemPrompt}
                    `;
                } else {
                    logEvent(getFirebaseAnalytics(), 'start_ai_okr');
                }

                const response = await fetch('https://us-central1-time-quest-18054.cloudfunctions.net/generateJson', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(
                        {
                            userPrompt: planCreationInfo.userPrompt,
                            systemPrompt: systemMessage,
                            startDate: planCreationInfo.startDate,
                            milestoneDuration: planCreationInfo.milestoneDuration,
                            numberOfMilestones: planCreationInfo.numberOfMilestones
                        }),
                });

                // Check if the request was successful
                if (!response.ok) {
                    logger.error(`HTTP error! status: ${response.status}`);
                    logEvent(getFirebaseAnalytics(), 'ai_okr_error');
                    attempts++;
                    break;
                }

                const data = await response.json();
                logger.debug("OKR Generated JSON:", data);

                if (data && data.result && data.result.content) {
                    let okr = JSON.parse(data.result.content);

                    if (isValidOKRData(okr)) {
                        logEvent(getFirebaseAnalytics(), 'ai_okr_success');
                        return okr as OKRData;
                    } else {
                        logEvent(getFirebaseAnalytics(), 'ai_okr_invalid');
                        logger.warn(`Received data does not match OKRData format. Attempt ${attempts + 1} of ${maxAttempts}`);
                        attempts++;
                    }
                } else {
                    logEvent(getFirebaseAnalytics(), 'ai_okr_error');
                    logger.error("Data for generating OKR is coming back empty");
                    attempts++;
                }
            } catch (error) {
                logger.error("Error fetching OKR for milestone:", error);

                // Handle or throw the error as appropriate for your application
                throw error;
            }
        }

        throw new Error("Failed to fetch valid OKRData after maximum attempts");
    }, []);

    const generatePlanOKRs = async () => {
        let planCreationInfo = planInfo?.init as PlanCreationInfo;

        if (!planCreationInfo) {
            return;
        }

        try {
            const progressIncrement = Math.round((100 - 45) / planCreationInfo.numberOfMilestones);
            let updatedOkrs = [...okrs];

            logEvent(getFirebaseAnalytics(), 'start_ai_okrs');

            for (let i = 0; i < planCreationInfo.numberOfMilestones; i++) {
                setAiStatus('Generating Objective and Key Results for Milestone ' + (i + 1));

                const objectivesList = updatedOkrs.map(okr => okr.objective);
                const okr = await fetchOkrForMilestone(planInfo, i, milestones[i], objectivesList);

                okr.id = milestones[i].id;

                logger.debug(`Generated OKR for milestone: ${i + 1}`, okr);

                updatedOkrs = [...updatedOkrs, okr];
                setOkrs(updatedOkrs);

                propagateChangeInMilestones(i);
                setAiProgress(prevProgress => prevProgress + progressIncrement);
            }

            logEvent(getFirebaseAnalytics(), 'ai_okrs_success');
        } catch (error) {
            logger.error("Error generating plan OKRs:", error);
            logEvent(getFirebaseAnalytics(), 'ai_okrs_fail');
            throw "Error generating plan OKRs";
        }
    };

    useEffect(() => {
        logger.debug("AI Mode:", aiMode);
    }, [aiMode]);

    useEffect(() => {
        const runAIStep1 = async () => {
            logger.debug("AI step: ai-todo ==> ai-step1-todo ==> ai-step1-done");

            if (!milestones || milestones.length === 0 || aiMode !== 'ai-todo') {
                return;
            }

            setAiMode('ai-step1-todo');

            toast.message('Generating AI Plan...');

            // Step 1
            setAiStatus('Generating title and description of the plan');
            setAiProgress(10);

            // await generatePlanTitleAndDescription(plan);

            setAiMode('ai-step1-done');

            // toast.success('AI generated plan title and description successfully!');
        };

        try {
            if (!hasInitialized) {
                return;
            }

            if (selectedPlan && aiMode === 'ai-todo') {
                runAIStep1();
            }
        } catch (error) {
            logger.error("Failed in AI Mode:", error);
            setAiMode('ai-step1-failed');
            toast.error('Failed to generate plan title and description with AI.');
        }
    }, [aiMode, milestones, selectedPlan, hasInitialized]);

    useEffect(() => {
        const runAIStep2 = async () => {
            logger.debug("AI step: ai-step1-done ==> ai-step2-todo ==> ai-step2-done");

            setAiMode('ai-step2-todo');

            // Step 2
            setAiProgress(25);
            setAiStatus('Generating objectives and key results for each milestone');
            await generatePlanOKRs();

            setOkrsHaveChanged(true);

            setAiMode('ai-step2-done');

            toast.success('AI OKRs generated successfully!');
        };

        try {
            if (selectedPlan && aiMode === 'ai-step1-done') {
                runAIStep2();
            }
        } catch (error) {
            logger.error("Failed in AI Mode:", error);
            setAiMode('ai-step2-failed');
            toast.error('Failed to generate OKRs with AI.');
        }
    }, [aiMode, milestones, okrs, selectedPlan, generatePlanOKRs]);

    useEffect(() => {
        const runAIStep3 = async () => {
            logger.debug("AI step: ai-step2-done ==> ai-step3-todo ==> ai-step3-done");

            setAiMode('ai-step3-todo');

            // Step 3
            setAiProgress(80);
            // setAiStatus('Generating retrospective questions');

            setAiMode('ai-step3-done');

            // toast.success('AI generated plan retrospective successfully!');
        };

        try {
            if (aiMode === 'ai-step2-done') {
                runAIStep3();
            }
        } catch (error) {
            logger.error("Failed in AI Mode:", error);
            setAiMode('ai-step3-failed');
            toast.error('Failed to generate retrospective with AI.');
        }
    }, [aiMode, milestones, selectedPlan]);

    useEffect(() => {
        const runAIStep4 = async () => {
            logger.debug("AI step: ai-step3-done ==> ai-step4-todo ==> ai-step4-done");

            setAiMode('ai-step4-todo');

            // Step 4
            setAiProgress(90);
            // setAiStatus('Generating milestone visual markers');

            setAiMode('ai-step4-done');

            // toast.success('AI generated plan visual markers successfully!');
        };

        try {
            if (aiMode === 'ai-step3-done') {
                runAIStep4();
            }
        } catch (error) {
            logger.error("Failed in AI Mode:", error);
            setAiMode('ai-step4-failed');
            toast.error('Failed to generate plan visual markers with AI.');
        }
    }, [aiMode]);

    useEffect(() => {
        if (aiMode === 'ai-step4-done') {
            logger.debug("AI step: ai-step4-done ==> ai-completed");

            setAiMode('ai-completed');

            logEvent(getFirebaseAnalytics(), 'ai_completed');

            updatePlanChanges({ init: { ...planInfo?.init, mode: 'ai-completed' } }, 'ai-last-step', false, true);

            // Save the generated AI plan as a template
            const planDef = getPlanDefinition();
            sendEmailMessageForNewPlan(planInfo, planDef, user);

            toast.success('AI generated plan successfully!');
        }
    }, [aiMode, getPlanDefinition, planInfo, updatePlanChanges, user]);

    const [planOptionsAnchorEl, setPlanOptionsAnchorEl] = useState(null);

    const handlePlanOptionsClick = (event) => {
        setPlanOptionsAnchorEl(event.currentTarget);
    };

    const handlePlanOptionsClose = () => {
        setPlanOptionsAnchorEl(null);
    };

    const [windowSize, setWindowSize] = useState({ width: undefined, height: undefined });

    useEffect(() => {
        // This code runs only on the client side
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        // Set initial size
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [showDescription, setShowDescription] = useState(planInfo?.shouldShowDescription !== false);

    const toggleDescription = () => {
        const newShowDescription = !showDescription;
        setShowDescription(newShowDescription);

        // Update shouldShowDescription in planInfo using an update function
        updatePlanChanges({ shouldShowDescription: newShowDescription }, 'shouldShowDescription');
    };

    const [showProgress, setShowProgress] = useState(planInfo?.shouldShowProgress !== false);

    const [shouldShowPrompt, setShouldShowPrompt] = useState(false);

    const showPrompt = () => {
        setShouldShowPrompt(true);
    };

    const closePrompt = () => {
        setShouldShowPrompt(false);
    };

    const [isDeletePlanDialogOpen, setIsDeletePlanDialogOpen] = React.useState(false);
    const handleOpenDeleteDialog = () => {
        setIsDeletePlanDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setIsDeletePlanDialogOpen(false);
    };

    const handleConfirmDelete = () => {
        deletePlan(selectedPlan);

        logEvent(getFirebaseAnalytics(), 'delete_plan');

        setIsDeletePlanDialogOpen(false);
    };

    const getMenuItemDetails = (type: PlanSideViewType) => {
        switch (type) {
            case PlanSideViewType.Vision:
                return {
                    icon: <InterestsOutlinedIcon fontSize="small" />,
                    label: 'Stay Focused on Vision'
                };
            case PlanSideViewType.Mindset:
                return {
                    icon: <PsychologyAltOutlinedIcon fontSize="small" />,
                    label: 'Reflect on Mindset'
                };
            // Add more cases as needed
            default:
                return {
                    icon: null,
                    label: ''
                };
        }
    };

    const handleDeleteSnapshot = async (snapshotId: string) => {
        const updatedSnapshots = snapshots.filter(s => s.id !== snapshotId);
        setSnapshots(updatedSnapshots);

        setSnapshotsHaveChanged(true);

        logEvent(getFirebaseAnalytics(), 'delete_snapshot');

        // Optional: close the gallery if no snapshots are left
        if (updatedSnapshots.length === 0) {
            setIsGalleryOpen(false);
        }
    };

    const mapSnapshotsToPrioritiesSnapshotReferences = (snapshots: PlanSnapshot[]): PrioritiesSnapshotReference[] => {
        return snapshots.map((snapshot, index) => ({
            id: snapshot.id || String(index),
            createdAt: snapshot.date,
            timeRange: PredefinedTimeRange.AllTime as TimeRange
        }));
    };

    const getCurrentObjective = useCallback((milestoneId) => {
        const matchingOKR = okrs.find(okr => okr.id === milestoneId);
        return matchingOKR ?? { id: milestoneId, objective: "", keyResults: [] };
    }, [okrs]);

    logger.debug("Plan Detail Change Counter in PlanEditor:", planDetailChangeCounter);

    return (
        <div className={`container mx-auto p-${isMobile ? 1 : 4}`}>
            {showPlanConfetti && windowSize.width && windowSize.height && (
                <Confetti
                    width={windowSize.width}
                    height={windowSize.height}
                    recycle={true}
                    numberOfPieces={1000}
                    gravity={0.5}
                    run={showPlanConfetti}
                    colors={getConfettiColors(effectiveTheme)}
                    style={{ position: 'absolute', top: 0, left: 0 }}
                />
            )}

            <div id="tq-plan" className={`flex-grow novel-bg-white p-4`}>


                <div className="mb-8">
                    { /* Plan Header: Icon, Title, Actions */}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            width: '100%',
                            padding: '0',
                            marginBottom: '2rem'
                        }}
                    >

                        <Tooltip title="Change Icon" placement='top'>
                            <div className="p-0 mr-4 bg-primary items-center justify-center cursor-pointer">
                                <IconComponent
                                    key={`plan-icon-${selectedPlan?.icon?.name}-${selectedPlan?.icon?.color}`}
                                    size="large"
                                    type={planInfo?.type ?? 'plan'}
                                    iconSelection={selectedPlan?.icon}
                                    onClick={(e) => handleIconClickForPlan(e)}
                                />
                            </div>
                        </Tooltip>

                        <EditableTitle
                            key={`plan-title-${selectedPlan?.title}`}
                            initialTitle={selectedPlan?.title}
                            onSave={handleTitleSave}
                        />

                        <Button
                            className='ml-2'
                            size="large"
                            onClick={handlePlanOptionsClick}>
                            <MoreHorizOutlinedIcon />
                        </Button>

                        <Menu
                            anchorEl={planOptionsAnchorEl}
                            open={Boolean(planOptionsAnchorEl)}
                            onClose={handlePlanOptionsClose}
                        >
                            {planInfo?.init?.userPrompt && (
                                <MenuItem onClick={() => { handlePlanOptionsClose(); showPrompt(); }}>
                                    <ListItemIcon>
                                        <TipsAndUpdatesOutlinedIcon />
                                    </ListItemIcon>
                                    Show Prompt
                                </MenuItem>
                            )}

                            <MenuItem onClick={() => { handlePlanOptionsClose(); toggleDescription(); }}>
                                <ListItemIcon>
                                    <WysiwygOutlinedIcon />
                                </ListItemIcon>
                                {showDescription ? 'Hide Description' : 'Show Description'}
                            </MenuItem>

                            {!isMobile && (
                                <MenuItem onClick={() => { handlePlanOptionsClose(); setShowProgress(!showProgress); }}>
                                    <ListItemIcon>
                                        <ShowChartIcon />
                                    </ListItemIcon>
                                    {showProgress ? 'Hide Progress' : 'Show Progress'}
                                </MenuItem>
                            )}

                            <MenuItem key="delete-plan" onClick={() => { handlePlanOptionsClose(); handleOpenDeleteDialog() }}>
                                <ListItemIcon>
                                    <DeleteIcon />
                                </ListItemIcon>
                                Delete plan
                            </MenuItem>

                            {process.env.NODE_ENV === 'development' && [
                                <MenuItem key="submit-as-template" onClick={() => { handlePlanOptionsClose(); submitPlanAsTemplate(); }}>
                                    <ListItemIcon>
                                        <PublishIcon />
                                    </ListItemIcon>
                                    Submit as Template
                                </MenuItem>,

                                <MenuItem key="save-as-template" onClick={() => { handlePlanOptionsClose(); savePlanAsTemplate(); }}>
                                    <ListItemIcon>
                                        <SaveOutlinedIcon />
                                    </ListItemIcon>
                                    Save as Template
                                </MenuItem>,

                                <MenuItem onClick={() => { handlePlanOptionsClose(); handleExportPlanInfoJSON(); }}>
                                    <ListItemIcon>
                                        <SaveOutlinedIcon />
                                    </ListItemIcon>
                                    Export Plan Info as JSON
                                </MenuItem>,

                                <MenuItem onClick={() => { handlePlanOptionsClose(); handleExportPlanDefJSON(); }}>
                                    <ListItemIcon>
                                        <SaveOutlinedIcon />
                                    </ListItemIcon>
                                    Export Plan Definition as JSON
                                </MenuItem>,

                                <MenuItem key="plan-id" disabled>
                                    <Typography variant="body2" sx={{ color: 'text.secondary', margin: '0 16px' }}>
                                        Plan ID: {selectedPlan?.id}
                                    </Typography>
                                </MenuItem>
                            ]}
                        </Menu>
                    </div>

                    { /* Description */}
                    {showDescription && (
                        <EditableDescription
                            initialDescription={planInfo?.description}
                            onSave={handleDescriptionSave}
                        />
                    )}

                    { /* Blue header */}
                    <div className="screenshot-hide flex justify-between items-center bg-primary-700 bg-opacity-20 rounded p-1 mt-5 mb-3">
                        {/* Left-aligned buttons */}
                        <div className="flex items-center justify-center">
                            <Tooltip
                                title={snapshots.length > 0 ?
                                    "Open snapshots gallery to look back at the history of your plan" :
                                    "Taking snapshots on a plan helps you tell a story over time, showing how the vision is evolving and how the execution and mindset are progressing throughout the journey."
                                }
                            >
                                <span> {/* Tooltip needs a non-disabled element to wrap around */}

                                    <button
                                        onClick={() => setIsGalleryOpen(true)}
                                        disabled={snapshots.length === 0}
                                        className={
                                            `inline-flex items-center justify-center bg-opacity-70
                        ${snapshots.length === 0 ? 'bg-gray-400' : 'bg-primary-700 hover:bg-primary-500'} 
                        text-white border-none rounded px-3 py-2 
                        cursor-${snapshots.length === 0 ? 'default' : 'pointer'} 
                        outline-none m-1`
                                        }
                                    >
                                        <PhotoLibraryIcon style={{ marginRight: '4px' }} />
                                        Snapshots
                                    </button>
                                </span>
                            </Tooltip>

                            <Tooltip title={'Take a snapshot'}>
                                <IconButton
                                    onClick={handleTakeSnapshot}
                                    disabled={isTakingSnapshot}
                                    color="primary"
                                    sx={{
                                        margin: '2px',
                                    }}
                                >
                                    {!isTakingSnapshot ? <ScreenshotMonitorIcon /> : <CircularProgress size={24} />}
                                </IconButton>
                            </Tooltip>
                        </div>

                        {/* Right-aligned button */}
                        <Tooltip
                            title="Select a side view"
                            placement='top'>
                            <div className="flex items-center justify-center">
                                <button
                                    className={
                                        `inline-flex items-center justify-center 
                        bg-primary-700
                        text-white border-none rounded
                        outline-none p-2`
                                    }
                                    onClick={handleSideViewClick}
                                >
                                    {sideViewType === PlanSideViewType.Vision
                                        ? <InterestsOutlinedIcon style={{ marginRight: '4px' }} /> : sideViewType === PlanSideViewType.Mindset
                                            ? <PsychologyAltOutlinedIcon style={{ marginRight: '4px' }} /> : null
                                    }

                                    {PlanSideViewType[sideViewType]}

                                    <ExpandMoreOutlinedIcon style={{ marginLeft: '4px' }} />
                                </button>

                            </div>
                        </Tooltip>

                        {/* Menu for selecting the side view */}
                        {menuItems.length > 0 && (
                            <Menu
                                anchorEl={sideViewAnchorEl}
                                open={Boolean(sideViewAnchorEl)}
                                onClose={() => handleSideViewClose(sideViewType)}
                            >
                                {menuItems.map((key) => {
                                    const type = PlanSideViewType[key as keyof typeof PlanSideViewType];

                                    const { icon, label } = getMenuItemDetails(type);

                                    return (
                                        <MenuItem key={type} onClick={() => handleSideViewClose(type)}>
                                            <ListItemIcon>
                                                {icon}
                                            </ListItemIcon>
                                            {label}
                                        </MenuItem>
                                    );
                                })}
                            </Menu>
                        )}

                    </div>

                    { /* Progress and Timeline */}
                    {
                        !isMobile && showProgress && planProgressInfo && (
                            <div id={`plan-${selectedPlan?.id}-progress`}>

                                <TimelineBar
                                    key={`timeline-bar-${selectedPlan?.id}-${timelineMilestones.length}-${planProgressInfo.startDate}-${planProgressInfo.endDate}`}
                                    milestones={timelineMilestones}
                                    planProgressInfo={planProgressInfo}
                                    isIntimeline={false}
                                    iconSelections={iconSelections}
                                    onMilestoneClick={(planId, milestoneId) => handleMilestoneSelection(milestoneId)}
                                    updateMilestones={updateMilestones}
                                />
                            </div>
                        )
                    }

                    <SnapshotGallery
                        snapshots={snapshots}
                        open={isGalleryOpen}
                        onClose={() => setIsGalleryOpen(false)}
                        onDeleteSnapshot={handleDeleteSnapshot}
                        onViewSnapshot={(snapshot) => { }}
                    />
                </div>

                { /* Hidden old Structure of: Vision, Execution, Retrospection */}
                <div className="hidden grid-container my-8 mx-4">
                    <div className="grid grid-cols-10 gap-4 text-center">
                        {/* First cell: Outcomes */}
                        <div className="col-span-5 bg-white p-4 rounded border border-gray-200">
                            <div className="text-xl font-bold">
                                <Tooltip title="Am I reaching my personal goals? Do my actions align with my values and contribute to my growth?">
                                    <span className="screenshot-hide icon-personal mr-2">👤</span>
                                </Tooltip>
                                Outcomes
                                <Tooltip title="Do our solutions meet the needs of our customers and the business?">
                                    <span className="screenshot-hide icon-company ml-2">🏢</span>
                                </Tooltip>
                            </div>
                        </div>

                        {/* Second cell: Competency */}
                        <div className="col-span-3 bg-white p-4 rounded border border-gray-200">
                            <div className="text-xl font-bold">
                                <Tooltip title="How skilled am I in the practices that improve my personal effectiveness and adaptability?">
                                    <span className="screenshot-hide icon-personal mr-2">👤</span>
                                </Tooltip>
                                Competency
                                <Tooltip title="How proficient is the organization in the practices that enable business agility?">
                                    <span className="screenshot-hide icon-company ml-2">🏢</span>
                                </Tooltip>
                            </div>
                        </div>

                        {/* Third cell: Flow */}
                        <div className="col-span-2 bg-white p-4 rounded border border-gray-200">
                            <div className="text-xl font-bold">
                                <Tooltip title="How efficiently am I managing my time and energy to achieve my personal goals?">
                                    <span className="screenshot-hide icon-personal mr-2">👤</span>
                                </Tooltip>
                                Flow
                                <Tooltip title="How efficient is the organization at delivering value to the customer?">
                                    <span className="screenshot-hide icon-company ml-2">🏢</span>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </div>

                <Timeline className="timeline-container"
                    sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                            flex: isRetroOpen() && !isSmall ? 1 : 0,
                            padding: 0,
                        },
                        paddingLeft: isMobile ? '0px' : '16px',
                        paddingRight: isMobile ? '0px' : '16px',
                    }}>

                    {milestones.map((milestone, index) => (
                        <TimelineItem
                            key={'timeline-' + milestone.id}
                            className="timeline-item"
                            ref={(el) => setMilestoneRef(el as HTMLDivElement, milestone.id)}
                        >
                            <TimelineContent
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    textAlign: 'left',
                                }}
                                sx={{
                                    paddingLeft: isMobile ? '0px' : '16px',
                                    paddingRight: isMobile ? '0px' : '16px',
                                }}
                            >
                                <div className={`flex flex-col items-center justify-center w-full h-full p-2`}>
                                    {isSmall && isRetroOpen() && (
                                        <div id="retro" className="grid w-full p-2 rounded flex items-center justify-center">
                                            <RetrospectiveNotes
                                                retroData={retros.find(r => r.id === milestone.id) || { id: milestone.id, title: '', note: '', bgColor: '', textColor: '' }}
                                                onSaveRetro={handleSaveRetro}
                                            />
                                        </div>
                                    )}

                                    <DraggableMilestone
                                        key={`milestone-${milestone.id}`}
                                        milestone={milestone}
                                        index={index}
                                        okr={getCurrentObjective(milestone.id)}
                                        plan={planInfo}
                                        iconSelections={iconSelections}
                                        onMilestoneChange={handleMilestoneChange}
                                        onMilestoneAutoUpdateTimeRange={handleMilestoneAutoUpdateTimeRange}
                                        onAddMilestone={addMilestone}
                                        onDeleteMilestone={handleDeleteMilestone}
                                        showDeleteIcon={milestones.length > 1}
                                        moveMilestoneWithinPlan={moveMilestoneWithinPlan}
                                        onMoveMilestoneToNewPlan={handleMoveMilestoneToNewPlan}
                                        showMoveIcon={plans?.length > 1}
                                        onDuplicateMilestone={handleDuplicateMilestone}
                                        onClearKeyResultsConfirmation={clearKeyResultsForMilestone}
                                        shouldPropagateToOKREditor={shouldPropagateChangeInMilestones[index]}
                                        handleSaveOKR={handleSaveOKR}
                                        handleOpenEditorForOKR={handleOpenEditor}
                                        handleMoveKRToAnotherOKR={handleMoveKRToAnotherOKR}
                                        updateKeyResultsFilterForMilestone={updateKeyResultsFilterForMilestone}
                                    />
                                </div>
                            </TimelineContent>

                            {!isMobile && (
                                <TimelineSeparator >
                                    <Tooltip title="Change Icon">
                                        <TimelineDot
                                            className='p-0 mt-9 mb-9 items-center justify-center cursor-pointer'
                                            onClick={(e) => handleIconClickForMilestone(e, milestone.id)}
                                        >
                                            {renderPlanDotIcon(milestone.id)}
                                        </TimelineDot>
                                    </Tooltip>
                                    <TimelineConnector />
                                </TimelineSeparator>
                            )}

                            {isRetroOpen() && !isSmall && (
                                <TimelineOppositeContent className="flex items-center">
                                    <div id="retro" className="grid w-full p-2 rounded mt-10 flex items-center justify-center">
                                        <RetrospectiveNotes
                                            retroData={retros.find(r => r.id === milestone.id) || { id: milestone.id, title: '', note: '', bgColor: '', textColor: '' }}
                                            onSaveRetro={handleSaveRetro}
                                        />
                                    </div>
                                </TimelineOppositeContent>
                            )}

                        </TimelineItem>
                    ))}
                </Timeline>
            </div>

            <div className="text-center mt-24 mb-8">
                <span className="text-stone-500 text-sm block">
                    {selectedPlan?.creationDate && (
                        <><strong>Created:</strong> {formatDate(selectedPlan?.creationDate)}.</>
                    )}
                    {planInfo?.updatedDate && (
                        <> <strong>Last Updated:</strong> {formatDate(planInfo?.updatedDate)}.</>
                    )}
                </span>
            </div>

            {/* Swipeable Drawer from the bottom */}
            <Drawer
                anchor="right"
                open={isEditorDrawerOpen}
                onClose={handleCloseEditor}
                role="dialog"
                PaperProps={{
                    sx: {
                        height: '100vh',    // Set height to cover full viewport height
                        width: isMobile || isDrawerFullWidth ? '100vw' : '45vw'     // Set width to cover full viewport width
                    },
                }}
            >
                {/* Drawer content */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    {/* Right Arrow - Visible when the drawer is full width */}
                    {/* Left Arrow - Visible when the drawer is not full width */}
                    {!isMobile && (
                        <Tooltip title={isDrawerFullWidth ? "Collapse Editor" : "Expand Editor"}>
                            <IconButton
                                onClick={() => { setIsDrawerFullWidth(!isDrawerFullWidth) }}
                                sx={{
                                    position: 'absolute', // Position absolutely to the left
                                    left: 16,
                                }}
                            >
                                {isDrawerFullWidth ? <KeyboardArrowRightIcon fontSize="large" /> : <KeyboardArrowLeftIcon fontSize="large" />}
                            </IconButton>
                        </Tooltip>
                    )}

                    {/* Objective Title and Down Arrow */}
                    <Box
                        sx={{
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            bgcolor: 'background.paper',
                            display: 'flex',
                            justifyContent: 'center',
                            py: 1,
                            flexGrow: 1,
                            maxWidth: '80%', // The box will not exceed 80% of its parent's width
                            margin: 'auto' // This centers the box if it's smaller than the max width
                        }}
                    >
                        {/* Display the objective text (truncate if too long) */}
                        <Tooltip title={selectedEditorTitle}>
                            <Typography variant="h5" sx={{ maxWidth: '95%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'center', marginRight: '1rem' }}>
                                {selectedEditorTitle}
                            </Typography>
                        </Tooltip>
                    </Box>

                    {/* Close button (right arrow) */}
                    <Tooltip title="Close Editor">
                        <IconButton
                            onClick={handleCloseEditor}
                            sx={{
                                position: 'absolute', // Position absolutely to the right
                                right: 16,
                            }}
                        >
                            <CloseIcon
                                fontSize="large"
                            />
                        </IconButton>
                    </Tooltip>
                </Box>

                <div className="px-2 pb-2 h-full overflow-auto dark:text-gray-100">
                    <Editor
                        key={'editor-' + selectedEditorSourceType + '-' + selectedEditorSourceId + '-' + (selectedEditorSourceIndex || 'none')}
                        title={selectedEditorTitle}
                        isReorderEnabled={false}
                        timeRange={selectedEditorTimeRange}
                        sourceId={selectedEditorSourceId}
                        sourceType={selectedEditorSourceType}
                        sourceIndex={selectedEditorSourceIndex}
                        noteContent={selectedEditorContent}
                        onDebouncedUpdate={onEditorContentUpdated}
                        disableLocalStorage={true}
                    />
                </div>
            </Drawer>

            <Popover
                open={isIconMenuOpen}
                anchorEl={anchorIconEl}
                onClose={handleIconMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <IconsGrid
                    onSelect={selectIcon}
                    initialIcon={iconSelections[selectedDot]}
                />
            </Popover>

            <Popover
                open={planSelectorOpen}
                anchorEl={anchorMilestoneActionsEl}
                onClose={handleClosePlanSelector}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box p={2}>
                    <Typography variant="h6" gutterBottom>
                        Select a Plan to move milestone to:
                    </Typography>
                    <PlanSelector
                        onSelectPlan={onSelectPlanForMove}
                    />
                </Box>
            </Popover>

            <ProgressDialog
                open={isAIDialogOpen}
                status={aiStatus}
                progress={aiProgress}
            />

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isEditorContentLoading || isPlanLoading}
            >
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={5} minHeight="100vh" width="100%">
                    <Typography variant="h5" component="h2">
                        Just a moment...
                    </Typography>

                    <CircularProgress size={48} />

                    <Typography variant="subtitle1" style={{ textAlign: 'center', maxWidth: '80%', margin: '0 auto', fontStyle: 'italic' }}>
                        Loading {isEditorContentLoading ? "notes for your goal" : "your plan"}...
                    </Typography>
                </Box>
            </Backdrop>

            <Dialog
                open={shouldShowPrompt}
                onClose={closePrompt}
                aria-labelledby="prompt-dialog-title"
            >
                <DialogTitle id="prompt-dialog-title">Prompt Details</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div>
                            {planInfo?.init?.userPrompt
                                ?.split(/(Aspiration|Expertise Level|Effort Level|Timeline):/)
                                .filter(text => text.trim() !== "")
                                .map((text, index) => {
                                    if (text === "Aspiration" || text === "Expertise Level" || text === "Effort Level" || text === "Timeline") {
                                        return (
                                            <div key={`${index}-item`}>
                                                <strong>{text}</strong>
                                            </div>
                                        );
                                    }
                                    return <div key={`${index}-item`}>{text}<br /><br /></div>;
                                })
                            }
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closePrompt}>Close</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isDeletePlanDialogOpen}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this plan? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div >

    );
};

export default PlanEditor;